import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { InvoiceDA } from '../../services/invoice.service';
import { Company } from '../../models/company.model';
import { Error } from '../../models/error.model';
import { Invoice } from '../../models/invoice.model';
import { modalHide } from '../../tools/modal.tool';
import { getError } from '../../languages/translations/response';

export interface AppModalInvoiceUpdateValidateProps {
  companyForUser: Company | undefined | null
  invoice: Invoice | undefined | null,
  setInvoice: Dispatch<SetStateAction<Invoice | undefined | null>>
};

let errorResponse: Error, invoiceResponse: Invoice;

const AppModalInvoiceUpdateValidate: React.FunctionComponent<AppModalInvoiceUpdateValidateProps> = ({companyForUser, invoice, setInvoice}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')

  const submitInvoiceValidate = async () => {
    setLoadIndicator('on')

    if (companyForUser && invoice) {
      await InvoiceDA.putInvoiceValidated(companyForUser.id, invoice.id).then( (response) => {
        if (response.status === 200) {
          invoiceResponse = response.data
          setInvoice(invoiceResponse)

          Swal.fire({
            title: lang.labels.successfullyUpdatedStatus,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalInvoiceUpdateValidate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    }
  }

  const executeHideModalInvoiceUpdateValidate = () => {
    modalHide('modal-invoice-update-validate')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {

  }

  return (
    <div id="modal-invoice-update-validate" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.updateReminder}</h2>
          </div>
          <div className="modal-body">
            { invoice
              ?
              <div className="form">

              </div>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalInvoiceUpdateValidate}>{lang.labels.noCancel}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitInvoiceValidate}>
              <span className="indicator-label">{lang.labels.yesChangeStatus}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalInvoiceUpdateValidate;
