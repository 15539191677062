import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Company } from '../models/company.model';
import { ProviderOrder } from '../models/provider.order.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { CompanyProviderOrders } from '../models/company.provider.orders.model';
import { appToken } from '../scripts/app.token.script';

export class ProviderOrderDA {
  static apiResponse: ApiResponse

  public static async getProviderOrders(id_company: string, interval: string, start_date: string, end_date: string, contact_id: string, treasury_id: string, status: string, sort_field: string, sort_mode: string, group_by: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerOrdersByCompany: CompanyProviderOrders | null = null
      let providerOrderGroupsByCompany: CompanyProviderOrders | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/provider.orders.list.data.json').then(response => { providerOrdersByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/provider.orders.group.data.json').then(response => { providerOrderGroupsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerOrdersByCompany!.id_company === id_company || providerOrderGroupsByCompany!.id_company === id_company) {
        if (group_by.length === 0) {
          providerOrdersByCompany!.filter.interval = interval
          providerOrdersByCompany!.filter.start_date = start_date
          providerOrdersByCompany!.filter.end_date = end_date
          providerOrdersByCompany!.filter.contact_id = contact_id
          providerOrdersByCompany!.filter.treasury_id = treasury_id
          providerOrdersByCompany!.filter.status = status
          providerOrdersByCompany!.filter.sort_field = sort_field
          providerOrdersByCompany!.filter.sort_mode = sort_mode
          providerOrdersByCompany!.filter.group_by = group_by
        } else {
          providerOrderGroupsByCompany!.filter.interval = interval
          providerOrderGroupsByCompany!.filter.start_date = start_date
          providerOrderGroupsByCompany!.filter.end_date = end_date
          providerOrderGroupsByCompany!.filter.contact_id = contact_id
          providerOrderGroupsByCompany!.filter.treasury_id = treasury_id
          providerOrderGroupsByCompany!.filter.status = status
          providerOrderGroupsByCompany!.filter.sort_field = sort_field
          providerOrderGroupsByCompany!.filter.sort_mode = sort_mode
          providerOrderGroupsByCompany!.filter.group_by = group_by
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (group_by.length === 0) ? providerOrdersByCompany : providerOrderGroupsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/orders?interval=${interval}&start_date=${start_date}&end_date=${end_date}&id_contact=${contact_id}&id_bank_account=${treasury_id}&status=${status}&sort_field=${sort_field}&sort_mode=${sort_mode}&group_by=${group_by}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getProviderOrder(id_company: string, id_provider_order: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerOrder: ProviderOrder | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/provider.order.data.json').then(response => { providerOrder = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerOrder!.id === id_provider_order) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: providerOrder
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/orders/${id_provider_order}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postProviderOrder(id_company: string, id_provider: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string }[] }[], vouchers: { name: string, file: any }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/orders`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append("concerning", concerning)
      data.append("customer", id_provider)
      data.append("created_date", date)
      data.append("bank_account", id_treasury_account)
      data.append("reference", reference)
      data.append('currency', JSON.stringify(currency))
      data.append('company', JSON.stringify(company_data))

      requirements.forEach((item: any) => {
        data.append('requirements', JSON.stringify(item))
      })

      signatures.forEach((item: any) => {
        data.append('signatures', item)
      })

      vouchers.forEach((item: any) => {
        data.append("vouchers", item.file, item.name)
      })

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putProviderOrder(id_company: string, id_provider_order: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string }[] }[], vouchers: { name: string, file: any }[], signatures: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerOrder: ProviderOrder | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/provider.order.data.json').then(response => { providerOrder = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerOrder!.id === id_provider_order) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/orders/${id_provider_order}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append("concerning", concerning)
      data.append('created_date', date)
      data.append("bank_account", id_treasury_account)
      data.append('currency', JSON.stringify(currency))

      requirements.forEach((item: any) => {
        data.append('requirements', JSON.stringify(item))
      })

      signatures.forEach((item: any) => {
        data.append('signatures', item)
      })

      vouchers.forEach((item: any) => {
        if (item.file instanceof File) {
          data.append("vouchers", item.file, item.name)
        } else {
          data.append("array_url", item.file)
        }
        data.append("array_names", item.name)
      })

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putProviderOrderStatus(id_company: string, id_provider_order: string, new_status: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerOrder: ProviderOrder | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/provider.order.data.json').then(response => { providerOrder = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerOrder!.id === id_provider_order) {
        providerOrder!.status = new_status

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: providerOrder
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/orders/${id_provider_order}/status`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data = {
        status: new_status
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }
    return this.apiResponse
  }
}
