import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextTreasury } from '../Treasury';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { ApiGeolocation } from '../../../../services/api.geolocation';
import { TreasuryAccount } from '../../../../models/treasury.account.model';
import { AccountingAccount } from '../../../../models/accounting.account.model';
import { legendInvalid, legendValid, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../../../tools/legend.data.entry.tool';
import { uploadTooltip } from '../../../../tools/tooltip.tool';
import { evaluateLegendValidateEmpty, evaluateLegendValidateEmptyIcon, evaluateLegendValidateRequired, evaluateLegendValidateRequiredIcon } from '../../../../scripts/validate.legend.script';
import { accountingAccountSelected } from '../../../../scripts/selected.item.list.script';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { treasuryAccountTypeValue, treasuryTypeList } from '../../../../libraries/treasury.account.type.library';
import { countryList, countryCode, countryName } from '../../../../libraries/countries.library';
import { modalShow } from '../../../../tools/modal.tool';

import AppLegend from '../../../../components/element/Legend';
import AppModalAccountingAccountCreate from '../../../../components/modal/ModalAccountingAccountCreate';

import iconCard from '../../../../assets/images/icons/card-secret-icon.svg';
import iconBank from '../../../../assets/images/icons/bank-ico.svg';
import iconToken from '../../../../assets/images/icons/token-icon.svg';

export interface AppTreasuryCreateFormProps {
  component: string,
  treasuryAccount: TreasuryAccount | undefined | null,
  typeAccount: {value: string, valid: boolean} | null,
  accountingAccount: {value: string, valid: boolean} | null,
  nameAccount: {value: string, valid: boolean},
  iban: {value: string, valid: boolean},
  bban: {value: string, valid: boolean},
  bic: {value: string, valid: boolean},
  membership: {value: string, valid: boolean},
  qrIban: {value: string, valid: boolean},
  reference: {value: string, valid: boolean},
  nameHolder: {value: string, valid: boolean},
  street: {value: string, valid: boolean},
  streetNumber: {value: string, valid: boolean},
  additional: {value: string, valid: boolean},
  administrativeArea: {value: string, valid: boolean},
  city: {value: string, valid: boolean},
  zipCode: {value: string, valid: boolean},
  country: {value: string, valid: boolean},
  options: {is_default: boolean, is_reference: boolean, is_holder: boolean},
  setTypeAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null,
  setAccountingAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>> | null,
  setNameAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setIban: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setBban: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setBic: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setMembership: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setQrIban: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setReference: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setNameHolder: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setStreet: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setStreetNumber: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setAdditional: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setAdministrativeArea: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCity: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setZipCode: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCountry: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setOptions: Dispatch<SetStateAction<{is_default: boolean, is_reference: boolean, is_holder: boolean}>>
};

const AppTreasuryCreateForm: React.FunctionComponent<AppTreasuryCreateFormProps> = ({component, treasuryAccount, typeAccount, accountingAccount, nameAccount, iban, bban, bic, membership, qrIban, reference, nameHolder, street, streetNumber, additional, administrativeArea, city, zipCode, country, options, setTypeAccount, setAccountingAccount, setNameAccount, setIban, setBban, setBic, setMembership, setQrIban, setReference, setNameHolder, setStreet, setStreetNumber, setAdditional, setAdministrativeArea, setCity, setZipCode, setCountry, setOptions}) => {
  const {companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, accountingCashAccountsForCompany, accountingPostalAccountsForCompany, accountingBankAccountsForCompany, setAccountingAccountsForCompany, setAccountingCashAccountsForCompany, setAccountingPostalAccountsForCompany, setAccountingBankAccountsForCompany} = UseOutletContextTreasury()
  const {lang} = StateLanguage()

  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const handleChangeType = (item: string) => {
    if (setTypeAccount && setAccountingAccount) {
      setTypeAccount({value: item, valid: true})
      legendValid('container-validate-type-account-required')

      if (treasuryAccountsForCompany && accountingCashAccountsForCompany && treasuryAccountsForCompany.filter((temp_item) => (temp_item.type === 'cash')).length === 0 && item === 'cash') {
        setAccountingAccount({value: accountingCashAccountsForCompany.find((temp_item) => (temp_item.code === '1000'))!!.id, valid: true})
        legendValid('container-validate-accounting-account-required')
      } else {
        setAccountingAccount({value: '', valid: false})
        legendValidInvalidRestart('container-validate-accounting-account-required')
      }
    }
  }

  const handleChangeAccoutingAccount = (item: AccountingAccount) => {
    if (item.available) {
      if (accountingAccount && setAccountingAccount) {
        setAccountingAccount({value: item.id, valid: true})
        legendValid('container-validate-accounting-account-required')
      }
    } else {
      Swal.fire({
        text: lang.labels.accountingAccountAlreadySelected,
        icon: 'error',
        buttonsStyling: !1,
        confirmButtonText: lang.labels.okGotIt,
        customClass: {confirmButton: 'btn btn-primary'}
      } as SweetAlertOptions)
    }
  }

  const handleChangeNameAccount = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNameAccount({...nameAccount, value: event.target.value})
  }

  const handleChangeIban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setIban({...iban, value: event.target.value})
  }

  const handleChangeBban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setBban({...bban, value: event.target.value})
  }

  const handleChangeBic = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setBic({...bic, value: event.target.value})
  }

  const handleChangeMembership = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setMembership({...membership, value: event.target.value})
  }

  const handleChangeQrIban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setQrIban({...qrIban, value: event.target.value})
  }

  const handleChangeReference = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setReference({...reference, value: event.target.value})
  }

  const handleChangeNameHolder = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNameHolder({...nameHolder, value: event.target.value})
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreetNumber({...streetNumber, value: event.target.value})
  }

  const handleChangeAdditional = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdditional({...additional, value: event.target.value})
  }

  const handleChangeAdministrativeArea = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdministrativeArea({...administrativeArea, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeCountry = (item: string) => {
    setCountry({...country, value: item, valid: true})
    legendValid('container-validate-country-required')
  }

  const handleChangeOptionDefault = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setOptions({...options, is_default: event.target.checked})
  }

  const handleChangeOptionReference = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setOptions({...options, is_reference: event.target.checked})
  }

  const handleChangeOptionHolder = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setOptions({...options, is_holder: event.target.checked})

    if (event.target.checked) {
      startAccountHolder()
    } else {
      resetAccountHolder()
    }

    restartForm()
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of countryList(lang.code)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = countryList(lang.code)
    }

    setSearchCountry({text: text, list: list})
  }

  const validateNameAccount = () => {
    evaluateLegendValidateRequired(expressions.name, nameAccount, setNameAccount, 'container-validate-name-account-valid', 'container-validate-name-account-required')
  }

  const validateIban = () => {
    evaluateLegendValidateEmpty(expressions.iban, iban, setIban, 'container-validate-iban-valid')
  }

  const validateBban = () => {
    evaluateLegendValidateEmpty(expressions.bban, bban, setBban, 'container-validate-bban-valid')
  }

  const validateBic = () => {
    evaluateLegendValidateEmpty(expressions.bic, bic, setBic, 'container-validate-bic-valid')
  }

  const validateMembership = () => {
    evaluateLegendValidateEmpty(expressions.membership, membership, setMembership, 'container-validate-membership-valid')
  }

  const validateQrIban = () => {
    evaluateLegendValidateEmpty(expressions.qrIban, qrIban, setQrIban, 'container-validate-qr-iban-valid')
  }

  const validateReference = () => {
    if (reference.value.length === 0) {
      setReference({...reference, valid: true})
      setOptions({...options, is_reference: false})
      legendValidInvalidRestart('container-validate-reference-valid')
    } else {
      if (expressions.reference.test(reference.value)) {
        setReference({...reference, valid: true})
        setOptions({...options, is_reference: true})
        legendValid('container-validate-reference-valid')
      } else {
        setReference({...reference, valid: false})
        setOptions({...options, is_reference: false})
        legendInvalid('container-validate-reference-valid')
      }
    }
  }

  const validateNameHolder = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, nameHolder, setNameHolder, 'input-name-holder', 'container-validate-name-holder-valid', 'container-validate-name-holder-required')
  }

  const validateStreet = () => {
    evaluateLegendValidateRequiredIcon(expressions.street, street, setStreet, 'input-street', 'container-validate-street-valid', 'container-validate-street-required')
  }

  const validateStreetNumber = () => {
    if (streetNumber.value === 'NN' || streetNumber.value === 'nn') {
      setStreetNumber({...streetNumber, valid: true})
      legendValidRequiredIcon('input-street-number', 'container-validate-street-number-valid', 'container-validate-street-number-required')
    } else {
      evaluateLegendValidateRequiredIcon(expressions.streetnumber, streetNumber, setStreetNumber, 'input-street-number', 'container-validate-street-number-valid', 'container-validate-street-number-required')
    }
  }

  const validateAdditional = () => {
    evaluateLegendValidateEmptyIcon(expressions.text, additional, setAdditional, 'input-additional', 'container-validate-additional-valid')
  }

  const validateAdministrativeArea = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, administrativeArea, setAdministrativeArea, 'input-administrative-area', 'container-validate-administrative-area-valid', 'container-validate-administrative-area-required')
  }

  const validateCity = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, city, setCity, 'input-city', 'container-validate-city-valid', 'container-validate-city-required')
  }

  const validateZipCode = () => {
    evaluateLegendValidateRequiredIcon(expressions.zipcode, zipCode, setZipCode, 'input-zip-code', 'container-validate-zip-code-valid', 'container-validate-zip-code-required')
  }

  const executeShowModalAccountingAccountCreate = () => {
    modalShow('modal-accounting-account-create')
  }

  function accountingAccounts(type: string): AccountingAccount[] {
    if (accountingCashAccountsForCompany && accountingPostalAccountsForCompany && accountingBankAccountsForCompany) {
      switch (type) {
        case 'cash':
          return accountingCashAccountsForCompany
        case 'postal':
          return accountingPostalAccountsForCompany
        case 'bank':
          return accountingBankAccountsForCompany
        default:
          return []
      }
    } else {
      return []
    }
  }

  function uploadDropdown() {
    setSearchCountry({text: '', list: countryList(lang.code)})
  }

  function startAccountHolder() {
    if (companyForUser) {
      setNameHolder({value: companyForUser.name, valid: true})
      setStreet({value: companyForUser.address.street, valid: true})
      setStreetNumber({value: companyForUser.address.number, valid: true})
      setAdditional({value: companyForUser.address.additional, valid: true})
      setAdministrativeArea({value: companyForUser.address.administrative_area, valid: true})
      setCity({value: companyForUser.address.city, valid: true})
      setZipCode({value: companyForUser.address.zip_code, valid: true})
      setCountry({value: companyForUser.address.country, valid: true})
    }
  }

  function resetAccountHolder() {
    if (treasuryAccount && component === "modal") {
      setNameHolder({value: treasuryAccount.account_holder.full_name, valid: true})
      setStreet({value: treasuryAccount.account_holder.address.street, valid: true})
      setStreetNumber({value: treasuryAccount.account_holder.address.number, valid: true})
      setAdditional({value: treasuryAccount.account_holder.address.additional, valid: true})
      setAdministrativeArea({value: treasuryAccount.account_holder.address.administrative_area, valid: true})
      setCity({value: treasuryAccount.account_holder.address.city, valid: true})
      setZipCode({value: treasuryAccount.account_holder.address.zip_code, valid: true})
      setCountry({value: treasuryAccount.account_holder.address.country, valid: true})
    } else {
      setNameHolder({value: '', valid: false})
      setStreet({value: '', valid: false})
      setStreetNumber({value: '', valid: false})
      setAdditional({value: '', valid: false})
      setAdministrativeArea({value: '', valid: false})
      setCity({value: '', valid: false})
      setZipCode({value: '', valid: false})
      loadLocationBrowser()
    }
  }

  function restartForm() {
    legendValidInvalidIconRestart('input-name-holder', 'container-validate-name-holder-valid')
    legendValidInvalidIconRestart('input-name-holder', 'container-validate-name-holder-required')
    legendValidInvalidIconRestart('input-street', 'container-validate-street-valid')
    legendValidInvalidIconRestart('input-street', 'container-validate-street-required')
    legendValidInvalidIconRestart('input-street-number', 'container-validate-street-number-valid')
    legendValidInvalidIconRestart('input-street-number', 'container-validate-street-number-required')
    legendValidInvalidIconRestart('input-administrative-area', 'container-validate-administrative-area-valid')
    legendValidInvalidIconRestart('input-administrative-area', 'container-validate-administrative-area-required')
    legendValidInvalidIconRestart('input-city', 'container-validate-city-valid')
    legendValidInvalidIconRestart('input-city', 'container-validate-city-required')
    legendValidInvalidIconRestart('input-zip-code', 'container-validate-zip-code-valid')
    legendValidInvalidIconRestart('input-zip-code', 'container-validate-zip-code-required')
    legendValidInvalidRestart('container-validate-country-required')
  }

  async function loadLocationBrowser() {
    ApiGeolocation.getLocateIPAddress().then( (response) => {
      if (response) {
        setCountry({value: response.country_code, valid: true})
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    if (component === 'page') {
      setOptions({is_default: (!!treasuryAccountsForCompany && treasuryAccountsForCompany.length === 0), is_reference: (reference.valid && reference.value.length > 0), is_holder: !!companyForUser})
      startAccountHolder()
    } else {
      resetAccountHolder()
    }

    uploadTooltip()
    restartForm()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, treasuryAccountsForCompany])

  return (
    <>
      { companyForUser && treasuryAccountsForCompany && accountingCashAccountsForCompany && accountingPostalAccountsForCompany && accountingBankAccountsForCompany &&
        <div className="form d-flex flex-column flex-lg-row">
          <div className="d-flex flex-equal flex-column flex-row-fluid order-1 me-lg-3">
            { typeAccount && accountingAccount &&
              <>
                <div className="row mb-3">
                  <label className="form-label required">{lang.labels.typeOfTreasuryAccount}</label>
                  <div className="fv-row">
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {typeAccount.valid ? treasuryAccountTypeValue(lang, typeAccount.value) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              { treasuryTypeList(lang).map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === typeAccount.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === typeAccount.value}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeType(item.code)}>{item.description}</Dropdown.Item>
                                </li>
                              )}))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                    <AppLegend component={null} attribute={{validity: typeAccount.valid, name: "type-account", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
                <div className="row mb-5">
                  <label className="form-label required">{lang.labels.accountingAccount}</label>
                  <div className="fv-row">
                    <Dropdown>
                      <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                {accountingAccount.valid ? accountingAccountSelected(lang, accountingAccounts(typeAccount.value), accountingAccount.value, companyForUser.language.toLowerCase()) : lang.labels.selectOption}
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              <li className={`select2-results__option select2-results__option--selectable ${!typeAccount.valid && "d-none"}`}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={executeShowModalAccountingAccountCreate}>
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-3 me-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                                      </svg>
                                    </span>
                                    {lang.labels.addAccountingAccount}
                                  </div>
                                </Dropdown.Item>
                              </li>
                              <li className={`select2-results__option select2-results__option--selectable ${accountingAccounts(typeAccount.value).length > 0 && "d-none"}`}>
                                <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.noRecordsFound}</Dropdown.Item>
                              </li>
                              { (typeAccount!.value === 'cash' && treasuryAccountsForCompany.filter((item) => (item.type === 'cash')).length === 0)
                                ?
                                <li className="select2-results__option select2-results__option--selectable select2-results__option--selected" role="option" aria-selected={true}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text">{accountingAccountSelected(lang, accountingAccounts(typeAccount.value), accountingAccount.value, companyForUser.language.toLowerCase())}</Dropdown.Item>
                                </li>
                                :
                                <>
                                  { accountingAccounts(typeAccount.value).map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === accountingAccount.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === accountingAccount.value}>
                                      <Dropdown.Item bsPrefix={`select2-results__option__text text-${item.available ? "success text-hover-primary" : "danger fs-8"}`} onClick={() => handleChangeAccoutingAccount(item)}>{item.code}, {item.name[companyForUser.language.toLowerCase() as keyof typeof item.name]}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </>
                              }
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                    <AppLegend component={null} attribute={{validity: typeAccount.valid, name: "accounting-account", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
              </>
            }
            <div className="row mb-3">
              <label className="form-label required">{lang.labels.nameOfAccount}</label>
              <div className="fv-row">
                <div className="position-relative">
                  <input className="form-control form-control-solid pe-13" type="text" name="name-account" value={nameAccount.value} onChange={handleChangeNameAccount} onKeyUp={validateNameAccount} onBlur={validateNameAccount} />
                  <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                    <img src={iconBank} alt={lang.labels.bank} className="h-20px" />
                  </div>  
                </div>
                <AppLegend component={null} attribute={{validity: nameAccount.valid, name: "name-account", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label">
                <div className="d-flex align-items-center">
                  {lang.labels.iban}
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.internationalBankAccountNumber}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="fv-row">
                <div className="position-relative">
                  <input id="input-iban" className="form-control form-control-solid pe-13" type="text" name="iban" value={iban.value} onChange={handleChangeIban} onKeyUp={validateIban} onBlur={validateIban} />
                  <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                    <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                  </div>  
                </div>
                <AppLegend component={null} attribute={{validity: iban.valid, name: "iban", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label">
                <div className="d-flex align-items-center">
                  {lang.labels.bban}
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.basicBankAccountNumber}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="fv-row">
              <div className="position-relative">
                <input id="input-bban" className="form-control form-control-solid pe-13" type="text" name="bban" value={bban.value} onChange={handleChangeBban} onKeyUp={validateBban} onBlur={validateBban} />
                <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                  <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                </div>
              </div>
              <AppLegend component={null} attribute={{validity: bban.valid, name: "bban", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label">
                <div className="d-flex align-items-center">
                  {lang.labels.bicSwift}
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.bankIdentifierCode}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="fv-row">
                <div className="position-relative">
                  <input id="input-bic" className="form-control form-control-solid pe-13" type="text" name="bic" value={bic.value} onChange={handleChangeBic} onKeyUp={validateBic} onBlur={validateBic} />
                  <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                    <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                  </div>
                </div>
                <AppLegend component={null} attribute={{validity: bic.valid, name: "bic", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label">
                <div className="d-flex align-items-center">
                  {lang.labels.membership}
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.codeOfBelongingToABankingOrganization}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="fv-row">
                <div className="position-relative">
                  <input id="input-membership" className="form-control form-control-solid pe-13" type="text" name="iban" value={membership.value} onChange={handleChangeMembership} onKeyUp={validateMembership} onBlur={validateMembership} />
                  <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                    <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                  </div>  
                </div>
                <AppLegend component={null} attribute={{validity: membership.valid, name: "membership", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row mb-3">
              <label className="form-label">
                <div className="d-flex align-items-center">
                  {lang.labels.qrIban}
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.internationalBankAccountNumberIban}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="fv-row">
                <div className="position-relative">
                  <input id="input-qr-iban" className="form-control form-control-solid pe-13" type="text" name="iban" value={qrIban.value} onChange={handleChangeQrIban} onKeyUp={validateQrIban} onBlur={validateQrIban} />
                  <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                    <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                  </div>
                </div>
                <AppLegend component={null} attribute={{validity: qrIban.valid, name: "qr-iban", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
            <div className="row">
              <label className="form-label">
                <div className="d-flex align-items-center">
                  {lang.labels.reference}
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.informationAdditional}><FaExclamationCircle /></i>
                </div>
              </label>
              <div className="fv-row">
                <div className="position-relative">
                  <input id="input-reference" className="form-control form-control-solid pe-13" type="text" name="reference" value={reference.value} onChange={handleChangeReference} onKeyUp={validateReference} onBlur={validateReference} />
                  <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                    <img src={iconToken} alt={lang.labels.card} className="h-20px" />
                  </div>
                </div>
                <AppLegend component={null} attribute={{validity: reference.valid, name: "reference", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
          <div className="d-flex flex-equal flex-column flex-row-fluid order-2 ms-lg-3">
            <div className="mb-5">
              { component === "page" &&
                <>
                  <div className="separator separator-dashed my-2"></div>
                  <div className="d-flex flex-stack">
                    <div className="d-flex flex-column">
                      <label className="d-flex align-items-center">
                        <span className="fw-bolder">{lang.labels.default}</span>
                        <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.isNecessaryToHaveAtLeastTwoTreasuryAccountsToActivateOption}><FaExclamationCircle /></i>
                      </label>
                      <div className="fs-7 text-gray-700">{lang.labels.selectThisTreasuryAccountAsTheMainOne}</div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="form-check form-check-solid form-check-custom form-switch">
                        <input className="form-check-input w-50px h-30px" type="checkbox" disabled={(treasuryAccountsForCompany && treasuryAccountsForCompany.length > 0) ? false : true} checked={options.is_default} onChange={handleChangeOptionDefault} />
                      </div>
                    </div>
                  </div>
                </>
              }
              <div className="separator separator-dashed my-2"></div>
              <div className="d-flex flex-stack">
                <div className="d-flex flex-column">
                  <label className="d-flex align-items-center">
                    <span className="fw-bolder">{lang.labels.reference}</span>
                    <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.isNecessaryToHaveReferenceToActivateOption}><FaExclamationCircle /></i>
                  </label>
                  <div className="fs-7 text-gray-700">{lang.labels.useReferenceWithinQR}</div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="form-check form-check-solid form-check-custom form-switch">
                    <input className="form-check-input w-50px h-30px" type="checkbox" disabled={(reference.valid && reference.value.length > 0) ? false : true} checked={options.is_reference} onChange={handleChangeOptionReference} />
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-2"></div>
              <div className="d-flex flex-stack">
                <div className="d-flex flex-column">
                  <label className="d-flex align-items-center">
                    <span className="fw-bolder">{lang.labels.bankAccountHolder}</span>
                    <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={`${lang.labels.company}: ${companyForUser.name}`}><FaExclamationCircle /></i>
                  </label>
                  <div className="fs-7 text-gray-700">{lang.labels.IsCompanyOwnerOfBankAccount}</div>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="form-check form-check-solid form-check-custom form-switch">
                    <input className="form-check-input w-50px h-30px" type="checkbox" disabled={companyForUser ? false : true} checked={options.is_holder} onChange={handleChangeOptionHolder} />
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-2"></div>
            </div>
            <div className="border border-dashed border-gray-300 rounded-1 bg-lighten p-3">
              <div className="fv-row mb-3">
                <label className="form-label fs-7 mb-1 d-flex align-items-center">
                  <span className="required">{lang.labels.fullName}</span>
                  <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.fullNameBankAccountHolder}><FaExclamationCircle /></i>
                </label>
                <input id="input-name-holder" className="form-control form-control-sm" type="text" name="name-holder" value={nameHolder.value} disabled={options.is_holder} onChange={handleChangeNameHolder} onKeyUp={validateNameHolder} onBlur={validateNameHolder} />
                <AppLegend component={null} attribute={{validity: nameHolder.valid, name: "name-holder", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
              <div className="d-flex align-self-center mb-3">
                <div className="flex-grow-1 w-75 me-2">
                  <label className="form-label fs-7 mb-1 required">{lang.labels.street}</label>
                  <input id="input-street" className="form-control form-control-sm" type="text" name="street" value={street.value} disabled={options.is_holder} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                  <AppLegend component={null} attribute={{validity: street.valid, name: "street", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="flex-shrink-0 w-25">
                  <label className="form-label fs-7 mb-1 d-flex align-items-center">
                    <span className="required">{lang.labels.number}</span>
                    <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifAddressDoesNotHaveANumber}><FaExclamationCircle /></i>
                  </label>
                  <input id="input-street-number" className="form-control form-control-sm" type="text" name="street-number" value={streetNumber.value} disabled={options.is_holder} onChange={handleChangeNumber} onKeyUp={validateStreetNumber} onBlur={validateStreetNumber} />
                  <AppLegend component={null} attribute={{validity: streetNumber.valid, name: "street-number", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
              <div className="fv-row mb-3">
                <label className="form-label fs-7 mb-1 d-flex align-items-center">
                  {lang.labels.additional}
                  <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifNecessaryEnterSomethingReferentialRegardingAddress}><FaExclamationCircle /></i>
                </label>
                <input id="input-additional" className="form-control form-control-sm" type="text" name="additional" value={additional.value} disabled={options.is_holder} onChange={handleChangeAdditional} onKeyUp={validateAdditional} onBlur={validateAdditional} />
                <AppLegend component={null} attribute={{validity: additional.valid, name: "additional", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
              <div className="fv-row mb-3">
                <label className="form-label fs-7 mb-1 required">{lang.labels.administrativeArea}</label>
                <input id="input-administrative-area" className="form-control form-control-sm" type="text" name="administrative-area" value={administrativeArea.value} disabled={options.is_holder} onChange={handleChangeAdministrativeArea} onKeyUp={validateAdministrativeArea} onBlur={validateAdministrativeArea} />
                <AppLegend component={null} attribute={{validity: administrativeArea.valid, name: "administrative-area", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
              <div className="d-flex align-self-center mb-3">
                <div className="flex-shrink-0 w-25 me-2">
                  <label className="form-label mb-1 d-flex align-items-center">
                    <span className="required">{lang.labels.zipCode}</span>
                    <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.enterThePostalCodeOfTheCity}><FaExclamationCircle /></i>
                  </label>
                  <input id="input-zip-code" className="form-control form-control-sm" type="text" name="zip-code" value={zipCode.value} disabled={options.is_holder} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                  <AppLegend component={null} attribute={{validity: zipCode.valid, name: "zip-code", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="flex-grow-1 w-75">
                  <label className="form-label fs-7 mb-1 required">{lang.labels.city}</label>
                  <input id="input-city" className="form-control form-control-sm" name="city" value={city.value} disabled={options.is_holder} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                  <AppLegend component={null} attribute={{validity: city.valid, name: "city", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
              <div className="fv-row">
                <label className="form-label fs-7 mb-1 required">{lang.labels.country}</label>
                <Dropdown onToggle={uploadDropdown}>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" disabled={options.is_holder}>
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-sm" aria-disabled="false">
                        {country.valid ? countryName(country.value, lang.code) : lang.labels.selectOption}
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-search select2-search--dropdown">
                        <input className="select2-search__field p-2" type="text" name="country" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                      </span>
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { searchCountry.list.length > 0
                            ?
                            <>
                              { searchCountry.list.map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${countryCode(item, lang.code) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={countryCode(item, lang.code) === country.value}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(countryCode(item, lang.code))}>{item}</Dropdown.Item>
                                </li>
                              )}))}
                            </>
                            :
                            <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                          }
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={null} attribute={{validity: country.valid, name: "country", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
        </div>
      }
      { typeAccount && accountingAccount && setAccountingAccount &&
        <AppModalAccountingAccountCreate companyForUser={companyForUser} accountingAccounts={(typeAccount.value === "cash") ? accountingCashAccountsForCompany : (typeAccount.value === "postal") ? accountingPostalAccountsForCompany : (typeAccount.value === "bank") ? accountingBankAccountsForCompany : accountingAccountsForCompany} type={typeAccount.value} accountingAccount={accountingAccount} setAccountingAccounts={(typeAccount.value === "cash") ? setAccountingCashAccountsForCompany : (typeAccount.value === "postal") ? setAccountingPostalAccountsForCompany : (typeAccount.value === "bank") ? setAccountingBankAccountsForCompany : setAccountingAccountsForCompany} setAccountingAccount={setAccountingAccount}></AppModalAccountingAccountCreate>
      }
    </>
  )
};

export default AppTreasuryCreateForm;
