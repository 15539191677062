import axios from 'axios';
import format from 'date-fns/format';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Invoice } from '../models/invoice.model';
import { ProviderInvoice } from '../models/provider.invoice.model';
import { Payment } from '../models/payment.model';
import { appToken } from '../scripts/app.token.script';

export class PaymentDA {
  static apiResponse: ApiResponse

  public static async postPayment(id_company: string, id_invoice: string, amount: string, source: string, method: string, date: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let invoice: Invoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/invoice.data.json').then(response => { invoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (invoice!.id === id_invoice && invoice!.amount.debt >= Number(amount)) {
        invoice!.amount.debt -= Number(amount)
        invoice!.payment_status = (invoice!.amount.debt === 0) ? 'fully paid' : 'partially paid'
        invoice!.payments.push({
          id: `company${(Math.floor(Math.random() * 1000)).toString()}`,
          amount: Number(amount),
          source: source,
          method: method,
          created_date: date,
          created_at: `${new Date(format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'))}`,
          is_delete: false
        })

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: invoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/invoices/${id_invoice}/payments`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        amount: amount,
        method: method,
        source: source,
        created_date: date
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postPaymentProvider(id_company: string, id_provider_invoice: string, amount: string, source: string, method: string, date: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoice: ProviderInvoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/invoice.data.json').then(response => { providerInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoice!.id === id_provider_invoice && providerInvoice!.amount.debt >= Number(amount)) {
        providerInvoice!.amount.debt -= Number(amount)
        providerInvoice!.payment_status = (providerInvoice!.amount.debt === 0) ? 'fully paid' : 'partially paid'
        providerInvoice!.payments.push({
          id: `company${(Math.floor(Math.random() * 1000)).toString()}`,
          amount: Number(amount),
          source: source,
          method: method,
          created_date: date,
          created_at: `${new Date(format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'))}`,
          is_delete: false
        })

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: providerInvoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices/${id_provider_invoice}/payments`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        amount: amount,
        method: method,
        source: source,
        created_date: date
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deletePayment(id_company: string, id_invoice: string, id_payment: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let invoice: Invoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/invoice.data.json').then(response => { invoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (invoice!.payments.some((item: Payment) => item.id === id_payment)) {
        invoice!.amount.debt += Number(invoice!.payments.find((item: Payment) => item.id === id_payment)!.amount)
        invoice!.payment_status = (invoice!.amount.paid === 0) ? 'not payed' : 'partially paid'
        invoice!.payments = invoice!.payments.filter((item: Payment) => item.id !== id_payment)

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: invoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/invoices/${id_invoice}/payments/${id_payment}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deletePaymentProvider(id_company: string, id_provider_invoice: string, id_payment: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoice: ProviderInvoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/invoice.data.json').then(response => { providerInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoice!.payments.some((item: Payment) => item.id === id_payment)) {
        providerInvoice!.amount.debt -= Number(providerInvoice!.payments.find((item: Payment) => item.id === id_payment)!.amount)
        providerInvoice!.payment_status = (providerInvoice!.amount.paid === 0) ? 'not payed' : 'partially paid'
        providerInvoice!.payments = providerInvoice!.payments.filter((item: Payment) => item.id !== id_payment)

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: providerInvoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices/${id_provider_invoice}/payments/${id_payment}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
