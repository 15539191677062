import React, { Dispatch, SetStateAction, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { AccountingAccountDA } from '../../services/accounting.account.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { AccountingAccount } from '../../models/accounting.account.model';
import { getError } from '../../languages/translations/response';
import { legendInvalidEmptyIcon, legendInvalidIcon, legendValidInvalidIconRestart, legendValidRequiredIcon } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { evaluateLegendValidateRequiredIcon } from '../../scripts/validate.legend.script';
import { validateAttributes } from '../../scripts/validate.attributes.script';
import { expressions } from '../../libraries/regular.expressions.library';
import { languageDownloadValue } from '../../libraries/language.download.library';
import { treasuryAccountTypeValue } from '../../libraries/treasury.account.type.library';
import { uploadTooltip } from '../../tools/tooltip.tool';

import AppLegend from '../element/Legend';

export interface AppModalAccountingAccountCreateProps {
  companyForUser: Company | undefined | null,
  accountingAccounts: AccountingAccount[] | undefined | null,
  type: string,
  accountingAccount: {value: string, valid: boolean},
  setAccountingAccounts: Dispatch<SetStateAction<AccountingAccount[] | undefined | null>>
  setAccountingAccount: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
};

let errorResponse: Error, accountingAccountResponse: AccountingAccount;

const AppModalAccountingAccountCreate: React.FunctionComponent<AppModalAccountingAccountCreateProps> = ({companyForUser, accountingAccounts, type, accountingAccount, setAccountingAccounts, setAccountingAccount}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [code, setCode] = useState({value: '', valid: false})
  const [name, setName] = useState({value: {en: '', fr: '', it: '', de: ''}, valid: {en: false, fr: false, it: false, de: false}})

  const submitAccountingAccountCreate = async () => {
    setLoadIndicator('on')

    let nameIsValid: boolean = validateAttributes(name.valid)

    if (companyForUser && accountingAccounts && code.valid && nameIsValid) {
      await AccountingAccountDA.postAccountingTreasuryAccount(companyForUser.id, type, code.value, name.value).then( (response) => {
        if (response.status === 201) {
          accountingAccountResponse = response.data

          setAccountingAccounts([accountingAccountResponse, ...accountingAccounts])
          setAccountingAccount({value: accountingAccountResponse.id, valid: true})

          Swal.fire({
            title: lang.labels.successfullyCreatedAccountingAccount,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalAccountingAccountCreate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (code.value.length === 0) {
          legendInvalidIcon('modal-accounting-account-create-input-code', 'modal-accounting-account-create-container-validate-code-required')
        }
        for (let index = 0; index < Object.keys(name.value).length; index++) {
          if (name.value[Object.keys(name.value)[index] as keyof typeof name.valid].length === 0) {
            legendInvalidIcon('modal-accounting-account-create-input-name-' + index, 'modal-accounting-account-create-container-validate-name-required-' + index)
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCode({...code, value: event.target.value})
  }

  const handleChangeName = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName(name => ({...name, value: {...name.value, [item]: event.target.value}}))
  }

  const validateCode = () => {
    let expression: RegExp = (type === 'cash') ? expressions.cashaccount : (type === 'postal') ? expressions.postalaccount : (type === 'bank') ? expressions.bankaccount : expressions.account
    evaluateLegendValidateRequiredIcon(expression, code, setCode, 'modal-accounting-account-create-input-code', 'modal-accounting-account-create-container-validate-code-valid', 'modal-accounting-account-create-container-validate-code-required')
  }

  const validateName = (index: number, item: string) => {
    if (expressions && expressions.name.test(name.value[item as keyof typeof name.value])) {
      setName(name => ({...name, valid: {...name.valid, [item]: true}}))
      legendValidRequiredIcon('modal-accounting-account-create-input-name-' + index, 'modal-accounting-account-create-container-validate-name-valid-' + index, 'modal-accounting-account-create-container-validate-name-required-' + index)
    } else {
      setName(name => ({...name, valid: {...name.valid, [item]: false}}))
      legendInvalidEmptyIcon(name.value[item as keyof typeof name.value], 'modal-accounting-account-create-input-name-' + index, 'modal-accounting-account-create-container-validate-name-valid-' + index, 'modal-accounting-account-create-container-validate-name-required-' + index)
    }
  }

  const executeHideModalAccountingAccountCreate = () => {
    modalHide('modal-accounting-account-create')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function rangeCodes(): string {
    switch (type) {
      case 'cash':
        return '1000 → 1009.99'
      case 'postal':
        return '1010 → 1019.99'
      case 'bank':
        return '1020 → 1049.99'
      default:
        return lang.labels.none
    }
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-accounting-account-create-input-code', 'modal-accounting-account-create-container-validate-code-valid')
    legendValidInvalidIconRestart('modal-accounting-account-create-input-code', 'modal-accounting-account-create-container-validate-code-required')

    for (var index in Object.keys(name.valid)) {
      legendValidInvalidIconRestart('modal-accounting-account-create-input-name-' + index, 'modal-accounting-account-create-container-validate-name-valid-' + index)
      legendValidInvalidIconRestart('modal-accounting-account-create-input-name-' + index, 'modal-accounting-account-create-container-validate-name-required-' + index)
    }

    setCode({value: '', valid: false})
    setName({value: {en: '', fr: '', it: '', de: ''}, valid: {en: false, fr: false, it: false, de: false}})
  }

  return (
    <div id="modal-accounting-account-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.addAccountingAccount}</h2>
          </div>
          <div className="modal-body">
            <div className="form" onLoad={uploadTooltip}>
              <table className="table align-middle gy-0 mb-5">
                <tbody className="fw-bold">
                  <tr>
                    <td className="w-200px text-gray-500">{lang.labels.typeOfTreasuryAccount}:</td>
                    <td className="min-w-200px text-gray-800">{treasuryAccountTypeValue(lang, type)}</td>
                  </tr>
                  <tr>
                    <td className="w-200px text-gray-500">{lang.labels.rangeAllowedCodes}:</td>
                    <td className="min-w-200px text-gray-800">{rangeCodes()}</td>
                  </tr>
                </tbody>
              </table>
              <div className="row mb-2">
                <label className="col-lg-2 col-form-label required">{lang.labels.code}</label>
                <div className="col-lg-10">
                  <input id="modal-accounting-account-create-input-code" className="form-control form-control-solid" type="text" name="code" value={code.value} onChange={handleChangeCode} onKeyUp={validateCode} onBlur={validateCode} />
                  <AppLegend component={"modal-accounting-account-create"} attribute={{validity: code.valid, name: "code", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
              <div className="row">
                <label className="col-form-label d-flex align-items-center">
                  <span className="required">{lang.labels.name}</span>
                  <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.enterNameOfRequestedLanguages}><FaExclamationCircle /></i>
                </label>
                { Object.entries(name.value).map (( (item, index) => { return (
                  <div key={index} className="my-1">
                    <input id={"modal-accounting-account-create-input-name-" + index} className="form-control form-control-solid" type="text" name="name" autoComplete="off" placeholder={languageDownloadValue(lang, item[0].toUpperCase())} value={item[1]} onChange={(event) => handleChangeName(item[0], event)} onKeyUp={() => validateName(index, item[0])} onBlur={() => validateName(index, item[0])} />
                    <AppLegend component={"modal-accounting-account-create"} attribute={{validity: name.valid[item[0] as keyof typeof name.valid], name: "name", index: index, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                )}))}
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalAccountingAccountCreate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitAccountingAccountCreate}>
              <span className="indicator-label">{lang.labels.create}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalAccountingAccountCreate;
