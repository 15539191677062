import React, { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

import { UseOutletContextCompany } from './Company';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { Deadline } from '../../../models/deadline.model';
import { Unit } from '../../../models/unit.model';
import { uploadTooltip } from '../../../tools/tooltip.tool';
import { numberAddress } from '../../../scripts/address.number.script';
import { colorTemplateDescription } from '../../../libraries/template.color.library';
import { countryName } from '../../../libraries/countries.library';
import { languageDownloadValue } from '../../../libraries/language.download.library';
import { qrTemplateValue } from '../../../libraries/qr.template.library';

export interface CompanyViewPageProps {};

const CompanyViewPage: React.FunctionComponent<CompanyViewPageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany} = UseOutletContextCompany()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)

  function listTaxes(tax_fees: string[]): string[] {
    let listTaxFees: string[] = tax_fees.map((item) => { return (Number(item) || Number(item) === 0) ? `${parseFloat(Number(item).toFixed(2))}%` : `${item.charAt(0).toUpperCase() + item.slice(1)}` })
    return listTaxFees
  }

  function listDeadlines(payment_deadlines: Deadline[]): string[] {
    let listPaymentDeadlines: string[] = payment_deadlines.map((item) => { return `${item.quantity} ${item.type}${(item.quantity > 1) ? 's' : ''}` })
    return listPaymentDeadlines
  }

  function listUnitsForService(measure_units: Unit[]): string[] {
    let listMeasureUnits: string[] = []

    for (let unit of measure_units) {
      if (unit.type === 'service') {
        listMeasureUnits.push(`${unit.name} (${unit.symbol})`)
      }
    }

    return listMeasureUnits
  }

  function listUnitsForProduct(measure_units: Unit[]): string[] {
    let listMeasureUnits: string[] = []

    for (let unit of measure_units) {
      if (unit.type === 'product') {
        listMeasureUnits.push(`${unit.name} (${unit.symbol})`)
      }
    }

    return listMeasureUnits
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.companies, branch: lang.labels.viewCompany}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="content d-flex flex-column flex-column-fluid" >
      { companyForUser && settingsForCompany
        ?
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid me-lg-5 order-1">
            <div className="card card-flush mb-5">
              <div className="card-body">
                <div className="mb-10">
                  <h4 className="mb-5">{lang.labels.companyIdentity}:</h4>
                  <table className="table gs-0 gy-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.name}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.name}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.uuidCode}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.uuid}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mb-10">
                  <h4 className="mb-5">{lang.labels.companyAddress}:</h4>
                  <table className="table gs-0 gy-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.street}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.address.street}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.number}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{numberAddress(lang, companyForUser.address.number)}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.additional}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.address.additional.length > 0 ? `${companyForUser.address.additional}` : `${"No additional description"}`}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.administrativeArea}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.address.administrative_area}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.zipCode}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.address.zip_code}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.city}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.address.city}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.country}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{countryName(companyForUser.address.country, lang.code)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mb-10">
                  <h4 className="mb-5">{lang.labels.contactInformation}:</h4>
                  <table className="table gs-0 gy-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.email}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.email}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.phoneNumber}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.phone}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.webSite}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.website.length > 0 ? `${companyForUser.website}` : lang.labels.none}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mb-10">
                  <h4 className="mb-5">{lang.labels.legalInformation}:</h4>
                  <table className="table gs-0 gy-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.vatNumber}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.vat_number.length > 0 ? `${companyForUser.vat_number}` : lang.labels.none}</td>
                      </tr>
                      <tr className="d-none">
                        <td className="w-200px fw-bold text-muted">{lang.labels.registerNumber}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.register_number.length > 0 ? `${companyForUser.register_number}` : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted">{lang.labels.currency}:</td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{companyForUser.currency.code}: {companyForUser.currency.name} ({companyForUser.currency.symbol})</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mb-0">
                  <h4 className="mb-5">{lang.labels.downloadSettings}:</h4>
                  <table className="table gs-0 gy-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-200px fw-bold text-muted d-flex align-items-center">
                          {lang.labels.language}:
                          <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.languageToDownload}><FaExclamationCircle /></i>
                        </td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800 text-capitalize">{languageDownloadValue(lang, companyForUser.language)}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted d-flex align-items-center">
                          {lang.labels.qrType}:
                          <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.shapesOrVersionsOfQR}><FaExclamationCircle /></i>
                        </td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800">{qrTemplateValue(lang, companyForUser.qr_type)}</td>
                      </tr>
                      <tr>
                        <td className="w-200px fw-bold text-muted"></td>
                        <td className="min-w-200px fw-bold fs-6 text-gray-800"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-325px order-2">
            <div className="card card-flush mb-5">
              <div className="card-header">
                <div className="card-title">
                  <h4>{lang.labels.templateModel}</h4>
                </div>
              </div>
              <div className="card-body text-center pt-0">
                <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3">
                  <div className="image-input-wrapper w-150px h-175px">
                    <img src={settingsForCompany.template_current.thumbnail} alt={lang.labels.template} className="w-100 h-100" />
                  </div>
                </div>
                <div className="text-muted fs-8">{lang.labels.designOfDocumentToDownloadQuotesAndInvoices}</div>
              </div>
            </div>
            { settingsForCompany.colors.length > 0 &&
              <div className="card card-flush mb-5">
                <div className="card-header">
                  <div className="card-title">
                    <h4>{lang.labels.templateColors}</h4>
                  </div>
                </div>
                <div className="card-body text-center pt-0">
                  <div className={`row row-cols-${settingsForCompany.colors.length} mb-3`}>
                    { settingsForCompany.colors.map (( (item, index) => { return (
                      <div key={index} className="col" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={colorTemplateDescription(lang, index)}>
                        <label className="border border-dashed border-dark rounded d-flex p-5" style={{backgroundColor: `${item}`}}></label>
                      </div>
                    )}))}
                  </div>
                  <div className="text-muted fs-8">{lang.labels.colorCombinationForDesignOfDocumentToDownload}</div>
                </div>
              </div>
            }
            { settingsForCompany.tax_fees.length > 0 &&
              <div className="card card-flush mb-5">
                <div className="card-header">
                  <div className="card-title">
                    <h4>{lang.labels.predefinedTaxesFees}</h4>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="d-flex flex-wrap text-gray-600">
                    { listTaxes(settingsForCompany.tax_fees).map (( (item, index) => { return (
                      <div key={index} className="d-flex align-items-center w-125px text-nowrap">
                        <span className="bullet bg-primary me-3"></span>
                        {item}
                      </div>
                    )}))}
                  </div>
                </div>
              </div>
            }
            { settingsForCompany.payment_deadlines.length > 0 &&
              <div className="card card-flush mb-5">
                <div className="card-header">
                  <div className="card-title">
                    <h4>{lang.labels.dueDates}</h4>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="d-flex flex-wrap text-gray-600">
                    { listDeadlines(settingsForCompany.payment_deadlines).map (( (item, index) => { return (
                      <div key={index} className="d-flex align-items-center w-125px text-nowrap">
                        <span className="bullet bg-primary me-3"></span>
                        {item}
                      </div>
                    )}))}
                  </div>
                </div>
              </div>
            }
            { settingsForCompany.measure_units.length > 0 &&
              <div className="card card-flush">
                <div className="card-header">
                  <div className="card-title">
                    <h4>{lang.labels.unitsOfMeasure}</h4>
                  </div>
                </div>
                <div className="card-body pt-0">
                  { listUnitsForService(settingsForCompany.measure_units).length > 0 &&
                    <div className="flex-equal">
                      <div className="fw-bolder text-gray-700 ms-2 mb-2">{lang.labels.services}</div>
                      <div className="d-flex flex-column text-gray-600">
                        { listUnitsForService(settingsForCompany.measure_units).map (( (item, index) => { return (
                          <div key={index} className="d-flex align-items-center text-nowrap">
                            <span className="bullet bg-primary me-3"></span>
                            {item}
                          </div>
                        )}))}
                      </div>
                    </div>
                  }
                  { listUnitsForProduct(settingsForCompany.measure_units).length > 0 &&
                    <div className={`flex-equal ${listUnitsForService(settingsForCompany.measure_units).length > 0 && "mt-5"}`}>
                      <div className="fw-bolder text-gray-700 ms-2 mb-2">{lang.labels.products}</div>
                      <div className="d-flex flex-column text-gray-600">
                        { listUnitsForProduct(settingsForCompany.measure_units).map (( (item, index) => { return (
                          <div key={index} className="d-flex align-items-center text-nowrap">
                            <span className="bullet bg-primary me-3"></span>
                            {item}
                          </div>
                        )}))}
                      </div>
                  </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default CompanyViewPage;
