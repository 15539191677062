import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { UseOutletContextCategory } from '../category/Category';
import { AccountingAccount } from '../../../../models/accounting.account.model';
import { legendValid } from '../../../../tools/legend.data.entry.tool';
import { uploadTooltip } from '../../../../tools/tooltip.tool';
import { accountingAccountSelected } from '../../../../scripts/selected.item.list.script';

import AppLegend from '../../../../components/element/Legend';

export interface AppAccountingAccountFormProps {
  component: string | null,
  accounts: {value: {expense: string, vat: string, product: string}, valid: {expense: boolean, vat: boolean, product: boolean}},
  setAccounts: Dispatch<SetStateAction<{value: {expense: string, vat: string, product: string}, valid: {expense: boolean, vat: boolean, product: boolean}}>>
};

const AppAccountingAccountForm: React.FunctionComponent<AppAccountingAccountFormProps> = ({component, accounts, setAccounts}) => {
  const {companyForUser, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany} = UseOutletContextCategory()
  const {lang} = StateLanguage()

  const [searchAccountingAccount, setSearchAccountingAccount] = useState<{text: string, list: AccountingAccount[]}>({text: '', list: []})

  const handleChangeAccounts = (index: string, item: string) => {
    setAccounts(accounts => ({value: {...accounts.value, [index]: item}, valid: {...accounts.valid, [index]: true}}))
    legendValid(`${component ? `${component}-` : ''}container-validate-account-${index}-required`)
  }

  const handleChangeSearchAccount = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (companyForUser && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany) {
      let current: AccountingAccount[] = (item === 'cash') ? accountingExpenseAccountsForCompany : (item === 'vat') ? accountingVatAccountsForCompany : (item === 'product') ? accountingProductAccountsForCompany : []
      let list: AccountingAccount[] = []
      let text: string = event.target.value

      if (companyForUser && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany && text.length > 0) {
        for (let account of current) {
          if (account.code.indexOf(text) !== -1 || account.name[companyForUser.language.toLowerCase() as keyof typeof account.name].toLowerCase().indexOf(text.toLowerCase()) !== -1) {
            list.push(account)
          }
        }
      } else {
        list = current
      }

      setSearchAccountingAccount({text: text, list: list})
    }
  }

  function uploadDropdown(type: string) {
    if (companyForUser && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany) {
      setSearchAccountingAccount({text: '', list: (type === 'cash') ? accountingExpenseAccountsForCompany : (type === 'vat') ? accountingVatAccountsForCompany : (type === 'product') ? accountingProductAccountsForCompany : []})
    }
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany])

  return (
    <>
      { companyForUser && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany &&
        <>
          <div className="fv-row mb-3">
            <label className="form-label">
              <div className="d-flex align-items-center">
                <span className="required">{lang.labels.expenseAccount}</span>
                <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.accountingAccountPurchases}><FaExclamationCircle /></i>
              </div>
            </label>
            <Dropdown onToggle={() => uploadDropdown("cash")}>
              <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                <span className="selection">
                  <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                    <span className="select2-selection__rendered" role="textbox">
                      <span className="select2-selection__placeholder">
                        {accounts.valid.expense ? accountingAccountSelected(lang, accountingExpenseAccountsForCompany, accounts.value.expense, companyForUser.language.toLowerCase()) : lang.labels.selectOption}
                      </span>
                    </span>
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                <span className="select2-dropdown select2-dropdown--below">
                  <span className="select2-results">
                    <ul className="select2-results__options" role="listbox">
                      <span className="select2-search select2-search--dropdown">
                        <input className="select2-search__field" type="text" name="account-expense" value={searchAccountingAccount.text} onChange={(event) => handleChangeSearchAccount("cash", event)} />
                      </span>
                      { searchAccountingAccount.list.map (( (item, index) => { return (
                        <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === accounts.value.expense && "select2-results__option--selected"}`} role="option" aria-selected={item.id === accounts.value.expense}>
                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeAccounts("expense", item.id)}>{item.code}, {item.name[companyForUser.language.toLowerCase() as keyof typeof item.name]}</Dropdown.Item>
                        </li>
                      )}))}
                    </ul>
                  </span>
                </span>
              </Dropdown.Menu>
            </Dropdown>
            <AppLegend component={component} attribute={{validity: accounts.valid.expense, name: "account-expense", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
          </div>
          <div className="fv-row mb-3">
            <label className="form-label">
              <div className="d-flex align-items-center">
                <span className="required">{lang.labels.vatAccount}</span>
                <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.accountingAccountPurchases}><FaExclamationCircle /></i>
              </div>
            </label>
            <Dropdown onToggle={() => uploadDropdown("vat")}>
              <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                <span className="selection">
                  <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                    <span className="select2-selection__rendered" role="textbox">
                      <span className="select2-selection__placeholder">
                        {accounts.valid.vat ? accountingAccountSelected(lang, accountingVatAccountsForCompany, accounts.value.vat, companyForUser.language.toLowerCase()) : lang.labels.selectOption}
                      </span>
                    </span>
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                <span className="select2-dropdown select2-dropdown--below">
                  <span className="select2-results">
                    <ul className="select2-results__options" role="listbox">
                      <span className="select2-search select2-search--dropdown">
                        <input className="select2-search__field" type="text" name="account-vat" value={searchAccountingAccount.text} onChange={(event) => handleChangeSearchAccount("vat", event)} />
                      </span>
                      { searchAccountingAccount.list.map (( (item, index) => { return (
                        <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === accounts.value.vat && "select2-results__option--selected"}`} role="option" aria-selected={item.id === accounts.value.vat}>
                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeAccounts("vat", item.id)}>{item.code}, {item.name[companyForUser.language.toLowerCase() as keyof typeof item.name]}</Dropdown.Item>
                        </li>
                      )}))}
                    </ul>
                  </span>
                </span>
              </Dropdown.Menu>
            </Dropdown>
            <AppLegend component={component} attribute={{validity: accounts.valid.vat, name: "account-vat", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
          </div>
          <div className="fv-row">
            <label className="form-label">
              <div className="d-flex align-items-center">
                <span className="required">{lang.labels.accountProduct}</span>
                <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.accountingAccountSales}><FaExclamationCircle /></i>
              </div>
            </label>
            <Dropdown onToggle={() => uploadDropdown("product")}>
              <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                <span className="selection">
                  <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                    <span className="select2-selection__rendered" role="textbox">
                      <span className="select2-selection__placeholder">
                        {accounts.valid.product ? accountingAccountSelected(lang, accountingProductAccountsForCompany, accounts.value.product, companyForUser.language.toLowerCase()) : lang.labels.selectOption}
                      </span>
                    </span>
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                <span className="select2-dropdown select2-dropdown--below">
                  <span className="select2-results">
                    <ul className="select2-results__options" role="listbox">
                      <span className="select2-search select2-search--dropdown">
                        <input className="select2-search__field" type="text" name="account-product" value={searchAccountingAccount.text} onChange={(event) => handleChangeSearchAccount("product", event)} />
                      </span>
                      { searchAccountingAccount.list.map (( (item, index) => { return (
                        <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === accounts.value.product && "select2-results__option--selected"}`} role="option" aria-selected={item.id === accounts.value.product}>
                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeAccounts("product", item.id)}>{item.code}, {item.name[companyForUser.language.toLowerCase() as keyof typeof item.name]}</Dropdown.Item>
                        </li>
                      )}))}
                    </ul>
                  </span>
                </span>
              </Dropdown.Menu>
            </Dropdown>
            <AppLegend component={component} attribute={{validity: accounts.valid.product, name: "account-product", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
          </div>
        </>
      }
    </>
  )
};

export default AppAccountingAccountForm;
