import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { TagsInput } from "react-tag-input-component";

import { UseOutletContextProduct } from '../Product';
import { StateLanguage } from '../../../../../languages/config/StateLanguage';
import { Category } from '../../../../../models/category.model';
import { uploadTooltip } from '../../../../../tools/tooltip.tool';
import { legendInvalid, legendInvalidIcon, legendValid, legendValidIcon, legendValidInvalidIconRestart, legendValidInvalidRestart } from '../../../../../tools/legend.data.entry.tool';
import { modalShow } from '../../../../../tools/modal.tool';
import { evaluateLegendValidateRequiredIcon } from '../../../../../scripts/validate.legend.script';
import { categorySelected } from '../../../../../scripts/selected.item.list.script';
import { validateAttributes } from '../../../../../scripts/validate.attributes.script';
import { languageDownloadValue } from '../../../../../libraries/language.download.library';
import { expressions } from '../../../../../libraries/regular.expressions.library';

import AppLegend from '../../../../../components/element/Legend';
import AppModalCategoryCreate from '../../../../../components/modal/ModalCategoryCreate';
import AppModalProductVariantAdd from '../../../../../components/modal/ModalProductVariantAdd';
import AppModalSettingCreateTax from '../../../../../components/modal/ModalSettingsCreateTax';
import AppModalSettingCreateUnit from '../../../../../components/modal/ModalSettingsCreateUnit';
import AppProductVariantForm from './ProductVariantForm';
import AppAppProductVariantView from './ProductVariantView';
import AppAccountingAccountForm from '../../card/AccountingAccountForm';

export interface AppProductFormProps {
  action: string,
  loadIndicator: string,
  name: {value: string, valid: boolean},
  category: {value: string, valid: boolean},
  tags: {value: string[], valid: boolean},
  names: {value: {en: string, fr: string, it: string, de: string}, valid: {en: boolean, fr: boolean, it: boolean, de: boolean}},
  accounts: {value: {expense: string, vat: string, product: string}, valid: {expense: boolean, vat: boolean, product: boolean}},
  variantDefault: {value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}, valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}},
  variants: {value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[], valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}[]},
  setName: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setCategory: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setTags: Dispatch<SetStateAction<{value: string[], valid: boolean}>>,
  setNames: Dispatch<SetStateAction<{value: {en: string, fr: string, it: string, de: string}, valid: {en: boolean, fr: boolean, it: boolean, de: boolean}}>>
  setAccounts: Dispatch<SetStateAction<{value: {expense: string, vat: string, product: string}, valid: {expense: boolean, vat: boolean, product: boolean}}>>,
  setVariantDefault: Dispatch<SetStateAction<{value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}, valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}}>>,
  setVariants: Dispatch<SetStateAction<{value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[], valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}[]}>>,
  submitProduct: Function
};

const AppProductForm: React.FunctionComponent<AppProductFormProps> = ({action, loadIndicator, name, category, tags, names, accounts, variantDefault, variants, setName, setCategory, setTags, setNames, setAccounts, setVariantDefault, setVariants, submitProduct}) => {
  const {companyForUser, settingsForCompany, categoriesForCompany, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, setSettingsForCompany, setCategoriesForCompany} = UseOutletContextProduct()
  const {lang} = StateLanguage()

  const [variantEmpty, setVariantEmpty] = useState({value: {description_short: '', description_long: '', code: '', sku: '', codebar: '', size: '', color: '', price: '', tax_rate: '', unit: '', discount: {type: 'none', value: '', quantity: ''}, photos: [] as {view: string, file: any}[]}, valid: {description_short: false, description_long: true, code: true, sku: true, codebar: true, size: true, color: true, price: false, tax_rate: false, unit: false, discount: {type: true, value: true, quantity: true}, photos: [] as {file: boolean, message: string}[], gallery: true}})
  const [newTax, setNewTax] = useState<{main_modal: string | null, index: number | null, sub_index: number | null}>({main_modal: null, index: null, sub_index: null})
  const [newUnit, setNewUnit] = useState<{type: string, main_modal: string | null, index: number | null, sub_index: number | null}>({type: '', main_modal: null, index: null, sub_index: null})

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeNames = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNames(names => ({...names, value: {...names.value, [item]: event.target.value}}))
  }

  const handleChangeCategory = (item: Category) => {
    setCategory({value: item.id, valid: true})
    setAccounts({value: {expense: item.accounts.expense, vat: item.accounts.vat, product: item.accounts.product}, valid: {expense: true, vat: true, product: true}})

    legendValid('container-validate-category-required')
    legendValid('container-validate-account-expense-required')
    legendValid('container-validate-account-vat-required')
    legendValid('container-validate-account-product-required')
  }

  const handleChangeTags = (items: string[]) => {
    setTags({...tags, value: items})
  }

  const validateName = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, name, setName, 'input-name', 'container-validate-name-valid', 'container-validate-name-required')
    evaluateLegendValidateRequiredIcon(expressions.name, name, setName, 'input-name-other', 'container-validate-name-other-valid', 'container-validate-name-other-required')
  }

  const validateNames = (index: number, item: string) => {
    if (expressions && names.value[item as keyof typeof names.value].length > 0) {
      if (expressions.name.test(names.value[item as keyof typeof names.value])) {
        setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
        legendValidIcon('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
      } else {
        setNames(names => ({...names, valid: {...names.valid, [item]: false}}))
        legendInvalidIcon('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
      }
    } else {
      setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
      legendValidInvalidIconRestart('input-language-name-' + index, 'container-validate-language-name-valid-' + index)
    }
  }

  const validateTagKeyUp = (event: React.KeyboardEvent <HTMLFormElement | HTMLInputElement>) => {
    if (expressions && expressions.tag.test(event.currentTarget.value)) {
      if (!tags.value.includes(event.currentTarget.value)) {
        setTags({...tags, valid: true})
        legendValid('container-validate-tags-valid')
        legendValid('container-validate-tags-exists')
        legendValid('container-validate-tags-other-valid')
        legendValid('container-validate-tags-other-exists')
      } else {
        setTags({...tags, valid: false})
        legendInvalid('container-validate-tags-exists')
        legendInvalid('container-validate-tags-other-exists')
      }
    } else {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-valid')
      legendInvalid('container-validate-tags-other-valid')
    }
  }

  const validateTagBlur = (event: React.FocusEvent <HTMLFormElement | HTMLInputElement>) => {
    /* if (expressions && event.currentTarget.value.length > 0 && expressions.tag.test(event.currentTarget.value) && !tags.value.includes(event.currentTarget.value)) {
      setTags({...tags, value: [...tags.value, event.currentTarget.value], valid: true})
      legendValid('container-validate-tags-required')
      legendValid('container-validate-tags-other-required')
    } else if (tags.value.length === 0) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-required')
      legendInvalid('container-validate-tags-other-required')
    } */

    if (tags.value.includes(event.currentTarget.value)) {
      setTags({...tags, valid: true})
      legendValidInvalidRestart('container-validate-tags-exists')
      legendValidInvalidRestart('container-validate-tags-other-exists')
    }

    event.currentTarget.value = ''
    legendValidInvalidRestart('container-validate-tags-valid')
    legendValidInvalidRestart('container-validate-tags-other-valid')
  }

  const validateTagAdd = (item: string, items: string[]) => {
    if (expressions && items) {
      if (expressions.tag.test(item)) {
        setTags({...tags, valid: true})
        legendValid('container-validate-tags-valid')
        //legendValid('container-validate-tags-required')
        legendValid('container-validate-tags-other-valid')
        //legendValid('container-validate-tags-other-required')
        return true;
      } else {
        setTags({...tags, valid: false})
        legendInvalid('container-validate-tags-valid')
        legendInvalid('container-validate-tags-other-valid')
        return false;
      }
    } else {
      setTags({...tags, valid: false})
      legendValidInvalidRestart('container-validate-tags-valid')
      //legendValidInvalidRestart('container-validate-tags-required')
      legendValidInvalidRestart('container-validate-tags-other-valid')
      //legendValidInvalidRestart('container-validate-tags-other-required')
      return false;
    }
  }

  const validateTagExisting = (item: string) => {
    if (item.length > 0) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-exists')
      legendInvalid('container-validate-tags-other-exists')
    } else {
      setTags({...tags, valid: true})
      legendValid('container-validate-tags-exists')
      legendValid('container-validate-tags-other-exists')
    }
  }

  const validateTagRemoved = (item: string) => {
    /* if (item.length > 0 && tags.value.length === 1) {
      setTags({...tags, valid: false})
      legendInvalid('container-validate-tags-required')
      legendInvalid('container-validate-tags-other-required')
    } else {
      setTags({...tags, valid: true})
      legendValid('container-validate-tags-required')
      legendValid('container-validate-tags-other-required')
    } */

    setTags({...tags, valid: true})
  }

  const executeShowModalCategoryCreate = () => {
    modalShow('modal-category-create')
  }

  const executeShowModalProductVariantAdd = () => {
    setNewTax({...newTax, main_modal: 'modal-product-variant-add'})
    setNewUnit({...newUnit, main_modal: 'modal-product-variant-add'})
    modalShow('modal-product-variant-add')
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, settingsForCompany, categoriesForCompany])

  return (
    <>
      { companyForUser && settingsForCompany && categoriesForCompany &&
        <div className="form d-flex flex-column flex-xl-row">
          <div className="d-flex flex-column flex-xl-row-auto gap-5 w-100 w-xl-350px mb-5 mb-xl-0">
            <div className="card card-flush d-block d-xl-none">
              <div className="card-header">
                <div className="card-title">
                  <h3>{lang.labels.general}</h3>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="mb-3">
                  <label className="form-label required">{lang.labels.productName}</label>
                  <input id="input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                  <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.productNameRequiredAndRecommendedUnique}</div>
                  <AppLegend component={null} attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="mb-0">
                  <label className="form-label">{lang.labels.tags}</label>
                  <TagsInput classNames={{tag: "tag-cls bg-dark bg-opacity-10 fs-7", input: "input-cls form-control form-control-solid w-100"}} name="tags" value={tags.value} onChange={handleChangeTags} onKeyUp={validateTagKeyUp} onBlur={validateTagBlur} beforeAddValidate={validateTagAdd} onExisting={validateTagExisting} onRemoved={validateTagRemoved} />
                  <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.addTagsToService}</div>
                  <AppLegend component={null} attribute={{validity: tags.valid, name: "tags", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: true, max: false}}></AppLegend>
                </div>
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h3>{lang.labels.NameInOtherLanguages}</h3>
                </div>
              </div>
              <div className="card-body pt-0">
                <label className="form-label">{lang.labels.enterInLanguageWant}</label>
                { Object.entries(names.value).map (( (item, index) => { return (
                  <div key={index} className="my-2">
                    <input id={"input-language-name-" + index} className="form-control" type="text" name="language-name" autoComplete="off" placeholder={languageDownloadValue(lang, item[0].toUpperCase())} value={item[1]} onChange={(event) => handleChangeNames(item[0], event)} onKeyUp={() => validateNames(index, item[0])} onBlur={() => validateNames(index, item[0])} />
                    <AppLegend component={null} attribute={{validity: names.valid[item[0] as keyof typeof names.valid], name: "language-name", index: index, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                )}))}
                <div className={`form-text text-justify mt-5 ${action === "update" && "d-none"}`}>{lang.labels.ifYouWishYouCanAssignNameOfCategoryInLanguagesNecessary}</div>
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h3>{lang.labels.productDetails}</h3>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="d-flex flex-wrap justify-content-between align-items-end mb-2">
                  <label className="form-label required">{lang.labels.category}</label>
                  <button className="btn btn-light-primary btn-sm" type="button" onClick={executeShowModalCategoryCreate}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-3 ms-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                      {lang.labels.addCategory}
                    </div>
                  </button>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {category.valid ? categorySelected(lang, categoriesForCompany, category.value) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${categoriesForCompany.length > 0 && "d-none"}`}>
                            <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.selectOption}</Dropdown.Item>
                          </li>
                          { categoriesForCompany.map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === category.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === category.value}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCategory(item)}>{item.display_name}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.addProductToCategory}</div>
                <AppLegend component={null} attribute={{validity: category.valid, name: "category", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                <div className="separator my-5"></div>
                <AppAccountingAccountForm component={null} accounts={accounts} setAccounts={setAccounts}></AppAccountingAccountForm>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid gap-5 ms-xl-5">
            <div className="d-flex flex-column gap-5">
              <div className="card card-flush d-none d-xl-block">
                <div className="card-header">
                  <div className="card-title">
                    <h3>{lang.labels.general}</h3>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="mb-3">
                    <label className="form-label required">{lang.labels.productName}</label>
                    <input id="input-name-other" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                    <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.productNameRequiredAndRecommendedUnique}</div>
                    <AppLegend component={null} attribute={{validity: name.valid, name: "name-other", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="mb-0">
                    <label className="form-label">{lang.labels.tags}</label>
                    <TagsInput classNames={{tag: "tag-cls bg-dark bg-opacity-10 fs-7", input: "input-cls form-control form-control-solid w-100"}} name="tags" value={tags.value} onChange={handleChangeTags} onKeyUp={validateTagKeyUp} onBlur={validateTagBlur} beforeAddValidate={validateTagAdd} onExisting={validateTagExisting} onRemoved={validateTagRemoved} />
                    <div className={`form-text text-justify ${action === "update" && "d-none"}`}>{lang.labels.addTagsToService}</div>
                    <AppLegend component={null} attribute={{validity: tags.valid, name: "tags-other", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: true, max: false}}></AppLegend>
                  </div>
                </div>
              </div>
              <div className="card card-flush">
                <div className="card-header">
                  <div className="card-title">
                    <h3>{(variants.value.length > 1 && variants.valid.length > 1) ? lang.labels.variants : lang.labels.advanced}</h3>
                  </div>
                  <div className="card-toolbar">
                    <button className="btn btn-sm btn-light-primary" type="button" disabled={(variants.value.length > 1 && variants.valid.length > 1) ? !variants.valid.every(item => validateAttributes(item)) : !validateAttributes(variantDefault.valid)} onClick={executeShowModalProductVariantAdd}>
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-3 ms-0">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                          </svg>
                        </span>
                        {lang.labels.addVariant}
                      </div>
                    </button>
                  </div>
                </div>
                <div className="card-body pt-0">
                  { variants.value.length > 1 && variants.valid.length > 1
                    ?
                    <AppAppProductVariantView newTax={newTax} newUnit={newUnit} variant={variantEmpty} variants={variants} setNewTax={setNewTax} setNewUnit={setNewUnit} setVariantDefault={setVariantDefault} setVariant={setVariantEmpty} setVariants={setVariants}></AppAppProductVariantView>
                    :
                    <AppProductVariantForm component={"page"} newUnit={newUnit} variant={variantDefault} setNewUnit={setNewUnit} setVariant={setVariantDefault}></AppProductVariantForm>
                  }
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Link to="/app/inventory/product/list" className="btn btn-lg btn-light fw-bolder text-uppercase me-2">{lang.labels.cancel}</Link>
              <button className="btn btn-lg btn-primary fw-bolder w-100 w-md-300px" type="button" data-kt-indicator={loadIndicator} onClick={() => submitProduct()}>
                <span className="indicator-label text-uppercase text-nowrap">{action === "create" ? lang.labels.createProduct : lang.labels.updateProduct}</span>
                <span className="indicator-progress">
                  {lang.labels.pleaseWait}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      }
      <AppModalCategoryCreate companyForUser={companyForUser} categoriesForCompany={categoriesForCompany} accountingExpenseAccountsForCompany={accountingExpenseAccountsForCompany} accountingVatAccountsForCompany={accountingVatAccountsForCompany} accountingProductAccountsForCompany={accountingProductAccountsForCompany} category={category} categories={null} setCategoriesForCompany={setCategoriesForCompany} setCategory={setCategory} setCategories={null} setObjectAccounts={setAccounts}></AppModalCategoryCreate>
      <AppModalProductVariantAdd settingsForCompany={settingsForCompany} newTax={newTax} newUnit={newUnit} variantDefault={variantDefault} variant={variantEmpty} variants={variants} setNewTax={setNewTax} setNewUnit={setNewUnit} setVariant={setVariantEmpty} setVariants={setVariants}></AppModalProductVariantAdd>
      <AppModalSettingCreateTax companyForUser={companyForUser} data={newTax} variant={newTax.main_modal ? variantEmpty : variantDefault} requirements={null} setSettingsForCompany={setSettingsForCompany} setData={setNewTax} setTaxRate={null} setVariant={newTax.main_modal ? setVariantEmpty : setVariantDefault} setRequirements={null}></AppModalSettingCreateTax>
      <AppModalSettingCreateUnit companyForUser={companyForUser} data={newUnit} variant={newUnit.main_modal ? variantEmpty : variantDefault} requirements={null} setSettingsForCompany={setSettingsForCompany} setData={setNewUnit} setUnitMeasure={null} setVariant={newUnit.main_modal ? setVariantEmpty : setVariantDefault} setRequirements={null}></AppModalSettingCreateUnit>
    </>
  )
};

export default AppProductForm;
