import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Cookies from 'universal-cookie';

import { AuthenticationDL } from '../../services/authentication.service';
import { UserDL } from '../../services/user.service';
import { CompanyDL } from '../../services/company.service';
import { NotificationDL } from '../../services/notification.service';
import { SettingsDA } from '../../services/settings.services';
import { AccountingAccountDA } from '../../services/accounting.account.service';
import { ContactDA } from '../../services/contact.service';
import { TreasuryAccountDA } from '../../services/treasury.account.service';
import { ServiceDA } from '../../services/service.service';
import { ProductDA } from '../../services/product.service';

import { CookieAppService } from '../../models/cookie.app.service.model';
import { CookieCompany } from '../../models/cookie.company.model';
import { Error } from '../../models/error.model';
import { Message } from '../../models/message.model';
import { User } from '../../models/user.model';
import { UserNotifications } from '../../models/user.notification.model';
import { AppService } from '../../models/app.service.model';
import { Company } from '../../models/company.model';
import { CompanyNonUsers } from '../../models/company.non.users.model';
import { Notification } from '../../models/notification.model';
import { CompanySettings } from '../../models/company.settings.model';
import { Settings } from '../../models/settings.model';
import { CompanyAccountingAccounts } from '../../models/company.accounting.accounts.model';
import { AccountingAccount } from '../../models/accounting.account.model';
import { CompanyContacts } from '../../models/company.contacts.model';
import { Contact } from '../../models/contact.model';
import { CompanyTreasuryAccounts } from '../../models/company.treasury.accounts.model';
import { TreasuryAccount } from '../../models/treasury.account.model';
import { CompanyServices } from '../../models/company.services.model';
import { Service } from '../../models/service.model';
import { CompanyProducts } from '../../models/company.products.model';
import { Product } from '../../models/product.model';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError, getMessage } from '../../languages/translations/response';
import { authUserLoggedIn } from '../../scripts/auth.user.script';

import AppNavbar from '../../components/Navbar';
import AppHeader from '../../components/Header';
import AppTitle from '../../components/Title';
import AppFooter from '../../components/Footer';
import AppScroll from '../../components/Scroll';
import AppOverlay from '../../components/Overlay';
import AppDropdownUserProfile from '../../components/dropdown/DropdownUserProfile';
import AppDropdownNotifications from '../../components/dropdown/DropdownNotifications';
import AppDropdownLanguages from '../../components/dropdown/DropdownLanguages';
import AppDrawerDigitalAdminPro from '../../components/drawer/DrawerDigitalAdminPro';
import AppModalCompanyInvitation from '../../components/modal/ModalCompanyInvitation';

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  userLoggedIn: User | undefined | null,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  notificationsForUser: Notification[] | undefined | null,
  settingsForCompany: Settings | null | undefined,
  accountingAccountsForCompany: AccountingAccount[] | undefined | null,
  contactsForCompany: Contact[] | undefined | null,
  treasuryAccountsForCompany: TreasuryAccount[] | null | undefined,
  servicesForCompany: Service[] | null | undefined,
  productsForCompany: Product[] | null | undefined,
  setSettingsForCompany: Dispatch<SetStateAction<Settings | null | undefined>>,
  setAccountingAccountsForCompany: Dispatch<SetStateAction<AccountingAccount[] | undefined | null>>,
  setContactsForCompany: Dispatch<SetStateAction<Contact[] | undefined | null>>,
  setTreasuryAccountsForCompany: Dispatch<SetStateAction<TreasuryAccount[] | null | undefined>>,
  setServicesForCompany: Dispatch<SetStateAction<Service[] | null | undefined>>,
  setProductsForCompany: Dispatch<SetStateAction<Product[] | null | undefined>>,
  reloadUserLoggedIn: Function,
  loadAppServiceForUser: Function,
  loadCompanyForUser: Function,
  loadNotificationsForUser: Function,
  loadSettingsForCompany: Function,
  loadAccountingAccountsForCompany: Function,
  loadContactsForCompany: Function,
  loadTreasuryAccountsForCompany: Function,
  loadServicesForCompany: Function
  loadProductsForCompany: Function,
};

export interface MainPageProps {
  userLoggedIn: User | undefined | null,
  setUserLoggedIn: Dispatch<SetStateAction<User | undefined | null>>
};

let errorResponse: Error, messageResponse: Message, userResponse: User, companyResponse: Company, notificationsResponse: UserNotifications, nonUsersResponse: CompanyNonUsers, settingsResponse: CompanySettings, accounitngAccuntsResponse: CompanyAccountingAccounts, contactsResponse: CompanyContacts, treasuryAccountsResponse: CompanyTreasuryAccounts, productsResponse: CompanyProducts, servicesResponse: CompanyServices;

const MainPage: React.FunctionComponent<MainPageProps> = ({userLoggedIn, setUserLoggedIn}) => {
  const {lang} = StateLanguage()
  const cookies = new Cookies()

  const [mounted, setMounted] = useState(false)
  const [asideMinimize, setAsideMinimize] = useState('off')
  const [scrollVisibility, setScrollVisibility] = useState({header: 'off', top: 'off'})
  const [activeDrawer, setActiveDrawer] = useState({value: 'off', aside: 'off', button: 'off'})
  const [route, setRoute] = useState<{path: {root: string, branch: string} | null, company: boolean} | null>(null)
  const [appServiceForUser, setAppServiceForUser] = useState<AppService | undefined | null>(null)
  const [companyForUser, setCompanyForUser] = useState<Company | undefined | null>(null)
  const [notificationsForUser, setNotificationsForUser] = useState<Notification[] | undefined | null>(null)
  const [nonUsersForCompany, setNonUsersForCompany] = useState<User[] | undefined | null>(null)
  const [settingsForCompany, setSettingsForCompany] = useState<Settings | null | undefined>(null)
  const [accountingAccountsForCompany, setAccountingAccountsForCompany] = useState<AccountingAccount[] | undefined | null>(null)
  const [contactsForCompany, setContactsForCompany] = useState<Contact[] | undefined | null>(null)
  const [treasuryAccountsForCompany, setTreasuryAccountsForCompany] = useState<TreasuryAccount[] | null | undefined>(null)
  const [servicesForCompany, setServicesForCompany] = useState<Service[] | null | undefined>(null)
  const [productsForCompany, setProductsForCompany] = useState<Product[] | null | undefined>(null)

  const loadUserLoggedIn = async () => {
    let apiKey: string = (cookies.get('app_service')) ? cookies.get('app_service').api_key : process.env.REACT_APP_SERVICE_API_KEY

    await UserDL.getUser(apiKey).then( (response) => {
      if (response.status === 200) {
        userResponse = response.data
        setUserLoggedIn(userResponse)

        let apiKeyAppService: string = (userResponse.app_services.length > 0) ? (cookies.get('app_service')) ? cookies.get('app_service').api_key : userResponse.app_services[0] : ''
        let idCompany: string = (userResponse.companies.length > 0) ? (cookies.get('company')) ? cookies.get('company').id : userResponse.companies[0].id : ''

        if (userResponse.app_services.length > 0) {
          loadAppServiceForUser(userResponse, apiKeyAppService, () => {})

          if (userResponse.companies.length > 0) {
            loadCompanyForUser(apiKeyAppService, idCompany, () => {})
          }
        }

        if (!userResponse.verified) {
          Swal.fire({
            title: lang.labels.accountNotVerifiedYet,
            text: lang.labels.toValidateYourAccount,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: lang.labels.yesSend,
            cancelButtonText: lang.labels.noCancel,
            customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
          }).then(async (result) => {
            if (result.isConfirmed) {
              await AuthenticationDL.verify(apiKeyAppService).then( (response) => {
                if (response.status === 200) {
                  messageResponse = response.data

                  Swal.fire({
                    title: getMessage(messageResponse.message, lang.code),
                    text: lang.labels.pleaseCheckYourEmailInbox,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1800
                  } as SweetAlertOptions)
                } else {
                  errorResponse = response.data

                  Swal.fire({
                    title: getError(errorResponse.code, lang.code),
                    text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                    icon: 'error',
                    buttonsStyling: !1,
                    confirmButtonText: lang.labels.okGotIt,
                    customClass: {confirmButton: 'btn btn-primary'}
                  } as SweetAlertOptions)
                }
              }).catch( (error) => {
                console.error(error)
                window.location.href = '/error'
              })
            }
          })
        }
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const reloadUserLoggedIn = async (authorization: string | null, app_service_api_key: string | null, company_id: string | null, callback: any) => {
    if (authorization && authorization.length > 0) {
      cookies.set('token', authorization, {path: '/', sameSite: 'lax'})
    }

    let apiKey: string = (app_service_api_key && app_service_api_key.length > 0) ? app_service_api_key : (appServiceForUser) ? appServiceForUser.api_key : cookies.get('app_service').api_key
    let idCompany: string = (company_id && company_id.length > 0) ? company_id : (companyForUser) ? companyForUser.id : cookies.get('company').id

    await UserDL.getUser(apiKey).then( (response) => {
      if (response.status === 200) {
        userResponse = response.data
        setUserLoggedIn(userResponse)

        loadAppServiceForUser(userResponse, apiKey, () => {})
        loadCompanyForUser(apiKey, idCompany, () => {})
        callback()
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadAppServiceForUser = async (user: User, api_key: string, callback: any) => {
    if (appServiceForUser && (appServiceForUser.api_key !== api_key)) {
      setNotificationsForUser(null)
    }

    let appServicesResponse: AppService[] = user.app_services
    let appServiceResponse: AppService = ((appServicesResponse.filter((app: AppService) => (app.api_key === api_key))).length === 1) ? appServicesResponse.find((app: AppService) => (app.api_key === api_key)) : cookies.get('app_service')
    let appService: CookieAppService = {id: appServiceResponse.id, api_key: appServiceResponse.api_key, name: appServiceResponse.name, url: appServiceResponse.url}

    cookies.set('app_service', appService, {path: '/', sameSite: 'lax'})
    setAppServiceForUser(appServiceResponse)

    loadNotificationsForUser(api_key)
    callback()
  }

  const loadCompanyForUser = async (api_key: string, id_company: string, callback: any) => {
    if (companyForUser && (companyForUser.id !== id_company)) {
      setSettingsForCompany(null)
      setAccountingAccountsForCompany(null)
      setContactsForCompany(null)
      setTreasuryAccountsForCompany(null)
      setServicesForCompany(null)
      setProductsForCompany(null)
    }

    await CompanyDL.getCompany(api_key, id_company).then( (response) => {
      if (response.status === 200) {
        companyResponse = response.data
        setCompanyForUser(companyResponse)

        let company: CookieCompany = {id: companyResponse.id, uuid: companyResponse.uuid, name: companyResponse.name}
        cookies.set('company', company, {path: '/', sameSite: 'lax'})

        loadSettingsForCompany(id_company)
        loadAccountingAccountsForCompany(id_company)
        loadContactsForCompany(id_company)
        loadTreasuryAccountsForCompany(id_company)
        loadServicesForCompany(id_company)
        loadProductsForCompany(id_company)
        callback()
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadNotificationsForUser = async (api_key: string) => {
    setNotificationsForUser(null)

    await NotificationDL.getNotifications(api_key).then( (response) => {
      if (response.status === 200) {
        notificationsResponse = response.data
        setNotificationsForUser(notificationsResponse.notifications)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadNonUsersForCompany = async (api_key: string, id_company: string) => {
    setNonUsersForCompany(null)

    await CompanyDL.getCompanyNonUsers(api_key, id_company).then( (response) => {
      if (response.status === 200) {
        nonUsersResponse = response.data
        setNonUsersForCompany(nonUsersResponse.users)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadSettingsForCompany = async (id_company: string) => {
    await SettingsDA.getSettings(id_company).then( (response) => {
      if (response.status === 200) {
        settingsResponse = response.data
        setSettingsForCompany(settingsResponse.data)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadAccountingAccountsForCompany = async (id_company: string) => {
    await AccountingAccountDA.getAccountingAccounts(id_company, [], [], []).then( (response) => {
      if (response.status === 200) {
        accounitngAccuntsResponse = response.data
        setAccountingAccountsForCompany(accounitngAccuntsResponse.accounting_accounts)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadContactsForCompany = async (id_company: string) => {
    await ContactDA.getContacts(id_company, '', '', '', '').then( (response) => {
      if (response.status === 200) {
        contactsResponse = response.data
        setContactsForCompany(contactsResponse.contacts)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadTreasuryAccountsForCompany = async (id_company: string) => {
    await TreasuryAccountDA.getTreasuryAccounts(id_company).then( (response) => {
      if (response.status === 200) {
        treasuryAccountsResponse = response.data
        setTreasuryAccountsForCompany(treasuryAccountsResponse.treasury_accounts)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadServicesForCompany = async (id_company: string) => {
    await ServiceDA.getServices(id_company, '', '', '', '').then( (response) => {
      if (response.status === 200) {
        servicesResponse = response.data
        setServicesForCompany(servicesResponse.services)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadProductsForCompany = async (id_company: string) => {
    await ProductDA.getProducts(id_company, '', '', '', '').then( (response) => {
      if (response.status === 200) {
        productsResponse = response.data
        setProductsForCompany(productsResponse.products)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    setMounted(true)
    loadUserLoggedIn()

    if (authUserLoggedIn()) {
      toast.success(lang.labels.yourSessionIsActiveCongratulations)
    } 

    if (Array.from(document.querySelectorAll('div[class="modal-backdrop fade show"]')).length > 0) {
      Array.from(document.querySelectorAll('body[class="modal-open"]')).forEach( (node) => {
        node.removeAttribute('class')
        node.removeAttribute('style')
      })
      Array.from(document.querySelectorAll('div[class="modal-backdrop fade show"]')).forEach( (node) => {
        node.remove()
      })
      Array.from(document.querySelectorAll('div[class="modal fade show"]')).forEach( (node) => {
        node.classList.remove('show')
      })
    }

    window.onpopstate = () => {
      Array.from(document.querySelectorAll('body[class="modal-open"]')).forEach( (node) => {
        node.removeAttribute('class')
        node.removeAttribute('style')
      })
      Array.from(document.querySelectorAll('div[class="modal-backdrop fade show"]')).forEach( (node)  => {
        node.remove()
      })
    }

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-enabled h-100" data-kt-sticky-header={scrollVisibility.header} data-kt-scrolltop={scrollVisibility.top} data-kt-aside-minimize={asideMinimize} data-kt-drawer={activeDrawer.value}>
      <div className="d-flex flex-column flex-root h-100">
        <div className="page d-flex flex-row flex-column-fluid">
          <AppNavbar asideMinimize={asideMinimize} scrollVisibility={scrollVisibility} activeDrawer={activeDrawer} setAsideMinimize={setAsideMinimize} setScrollVisibility={setScrollVisibility} setActiveDrawer={setActiveDrawer} route={route} userLoggedIn={userLoggedIn} appServiceForUser={appServiceForUser} companyForUser={companyForUser} notificationsForUser={notificationsForUser} loadAppServiceForUser={loadAppServiceForUser} loadCompanyForUser={loadCompanyForUser} loadNotificationsForUser={loadNotificationsForUser}></AppNavbar>
          <div className="wrapper d-flex flex-column flex-row-fluid">
            <AppHeader activeDrawer={activeDrawer} setActiveDrawer={setActiveDrawer} route={route} appServiceForUser={appServiceForUser} companyForUser={companyForUser} treasuryAccountsForCompany={treasuryAccountsForCompany} loadNonUsersForCompany={loadNonUsersForCompany}></AppHeader>
            <div className="content d-flex flex-column flex-column-fluid">
              <div className="container-xxl pb-5">
                <AppTitle route={route} companyForUser={companyForUser}></AppTitle>
                <Outlet context={{setRoute, userLoggedIn, appServiceForUser, companyForUser, notificationsForUser, settingsForCompany, accountingAccountsForCompany, contactsForCompany, treasuryAccountsForCompany, servicesForCompany, productsForCompany, setSettingsForCompany, setAccountingAccountsForCompany, setContactsForCompany, setTreasuryAccountsForCompany, setServicesForCompany, setProductsForCompany, reloadUserLoggedIn, loadAppServiceForUser, loadCompanyForUser, loadNotificationsForUser, loadSettingsForCompany, loadAccountingAccountsForCompany, loadContactsForCompany, loadTreasuryAccountsForCompany, loadServicesForCompany, loadProductsForCompany}}></Outlet>
              </div>
            </div>
            <AppFooter></AppFooter>
          </div>
        </div>
      </div>
      <AppScroll></AppScroll>
      <AppOverlay activeDrawer={activeDrawer} setActiveDrawer={setActiveDrawer}></AppOverlay>
      <AppDrawerDigitalAdminPro activeDrawer={activeDrawer} setActiveDrawer={setActiveDrawer} route={route}></AppDrawerDigitalAdminPro>
      <AppDropdownUserProfile activeDrawer={activeDrawer} setActiveDrawer={setActiveDrawer} userLoggedIn={userLoggedIn}></AppDropdownUserProfile>
      <AppDropdownLanguages></AppDropdownLanguages>
      <AppDropdownNotifications appServiceForUser={appServiceForUser} notificationsForUser={notificationsForUser} reloadUserLoggedIn={reloadUserLoggedIn} loadNotificationsForUser={loadNotificationsForUser}></AppDropdownNotifications>
      <AppModalCompanyInvitation appServiceForUser={appServiceForUser} companyForUser={companyForUser} nonUsersForCompany={nonUsersForCompany}></AppModalCompanyInvitation>
    </div>
  )
};

export function UseOutletContext() {
  return useOutletContext<ContexType>()
};

export default MainPage;
