import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextCategory } from './Category';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { CategoryDA } from '../../../../services/category.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { CompanyCategories } from '../../../../models/company.categories.model';
import { Category } from '../../../../models/category.model';
import { Container } from '../../../../styles/container.style';
import { modalShow } from '../../../../tools/modal.tool';

import AppSort from '../../../../components/element/Sort';
import AppModalCategoryCreate from '../../../../components/modal/ModalCategoryCreate';
import AppModalCategoryUpdate from '../../../../components/modal/ModalCategoryUpdate';

export interface CategoryListPageProps {};

let errorResponse: Error, messageResponse: Message, categoryResponse: Category, categoriesResponse: CompanyCategories;

const CategoryListPage: React.FunctionComponent<CategoryListPageProps> = props => {
  const {setRoute, companyForUser, categoriesForCompany, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, setCategoriesForCompany} = UseOutletContextCategory()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [filter, setFilter] = useState({search: '', options: {sort_field: '', sort_mode: ''}})
  const [checked, setChecked] = useState({count: 0, list: [] as string[]})
  const [categories, setCategories] = useState<Category[] | undefined | null>(null)
  const [category, setCategory] = useState<Category | undefined | null>(null)

  const loadCategories = async (id_company: string, callback: any) => {
    await CategoryDA.getCategories(id_company, filter.search, filter.options.sort_field, filter.options.sort_mode).then( (response) => {
      if (response.status === 200) {
        categoriesResponse = response.data

        setCategories(categoriesResponse.categories)
        setFilter({search: categoriesResponse.filter.category_name, options: {sort_field: categoriesResponse.filter.sort_field, sort_mode: categoriesResponse.filter.sort_mode}})
        setChecked({count: 0, list: []})
        callback()
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitCategoryUpdate = async (item: string) => {
    modalShow('modal-category-update')

    if (companyForUser) {
      await CategoryDA.getCategory(companyForUser.id, item).then( (response) => {
        if (response.status === 200) {
          categoryResponse = response.data
          setCategory(categoryResponse)
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      }) 
    }
  }

  const submitCategoryDelete = async (item: string) => {
    if (companyForUser && categoriesForCompany) {
      Swal.fire({
        title: lang.labels.youWantToDeleteThisCategory,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await CategoryDA.deleteCategories(companyForUser.id, [item]).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              loadCategories(companyForUser.id, () => {
                setCategoriesForCompany(categoriesForCompany.filter((temp) => (temp.id !== item)))

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.code),
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitCategoryDeleteList = () => {
    if (companyForUser && categoriesForCompany) {
      Swal.fire({
        title: lang.labels.youWantToDeleteSelectedCategories,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await CategoryDA.deleteCategories(companyForUser.id, checked.list).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              loadCategories(companyForUser.id, () => {
                setCategoriesForCompany(categoriesForCompany.filter((temp) => !checked.list.includes(temp.id)));

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.code),
                  text: lang.labels.actionCompletedReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const handleChangeSelectAllCategories = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let list: string[] = (event.target.checked) ? event.target.value.split(',') : []
    setChecked({...checked, count: list.length, list: list})
  }

  const handleChangeSelectCategory = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let list: string[] = (event.target.checked) ? (!checked.list.includes(event.target.value)) ? checked.list.concat(event.target.value) : checked.list : checked.list.filter((item) => (item !== event.target.value))
    setChecked({...checked, count: list.length, list: list})
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    filter.search = event.target.value
    filter.options = {sort_field: '', sort_mode: ''}

    if (companyForUser) {
      loadCategories(companyForUser.id, () => {})
    }
  }

  const executeSortList = (item: string) => {
    if (companyForUser) {
      if (item === filter.options.sort_field) {
        switch (filter.options.sort_mode) {
          case 'asc':
            filter.options.sort_mode = 'desc'
            break;
          case 'desc':
            filter.options.sort_mode = 'asc'
            break;
        }
      } else {
        filter.options.sort_field = item
        filter.options.sort_mode = 'asc'
      }
      loadCategories(companyForUser.id, () => {})
    }
  }

  const executeShowModalCategoryCreate = () => {
    modalShow('modal-category-create')
  }

  function quantityAssets(quantity: number): string {
    return (quantity > 0) ? 'success' : 'danger'
  }

  useEffect( () => {
    if (companyForUser) {
      filter.search = ''
      filter.options.sort_field = ''
      filter.options.sort_mode = ''
      checked.count = 0
      checked.list = []

      setCategories(null)
      loadCategories(companyForUser.id, () => {})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.categories, branch: lang.labels.listCategories}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { categories
        ?
        <div className="card card-flush">
          <div className="card-header">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                  </svg>
                </span>
                <input className="form-control form-control-solid w-350px ps-14" type="text" placeholder={lang.labels.searchCategory} value={filter.search} onChange={handleChangeSearch}/>
              </div>
            </div>
            <div className="card-toolbar">
              <Container property={(checked.count === 0).toString()}>
                <div className="d-flex justify-content-end align-items-center">
                  <button className="btn btn-primary" type="button" onClick={executeShowModalCategoryCreate}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-2 ms-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                      {lang.labels.addCategory}
                    </div>
                  </button>
                </div>
              </Container>
              <Container property={(checked.count > 0).toString()}>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="fw-bolder me-5">
                    <span className="me-2">{checked.count}</span>
                    {lang.labels.selected}
                  </div>
                  <button className="btn btn-danger" type="button" onClick={submitCategoryDeleteList}>{lang.labels.deleteSelected}</button>
                </div>
              </Container>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed mb-1">
                  <thead className="border-bottom">
                    <tr className="text-start text-muted text-uppercase fw-bolder fs-7">
                      <th className="w-25px">
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input className="form-check-input" type="checkbox" value={categories.map(item => item.id)} checked={categories.length === checked.count && checked.count > 0} onChange={handleChangeSelectAllCategories} />
                        </div>
                      </th>
                      <th className="min-w-350px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("category")}>
                          <span className="me-1">{lang.labels.category}</span>
                          <AppSort attribute={"category"} field={filter.options.sort_field} mode={filter.options.sort_mode}></AppSort>
                        </div>
                      </th>
                      <th className="w-125px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("services")}>
                          <span className="me-1">{lang.labels.qtyServices}</span>
                          <AppSort attribute={"services"} field={filter.options.sort_field} mode={filter.options.sort_mode}></AppSort>
                        </div>
                      </th>
                      <th className="w-125px">
                        <div className="d-flex align-items-center text-nowrap cursor-pointer" onClick={() => executeSortList("products")}>
                          <span className="me-1">{lang.labels.qtyProducts}</span>
                          <AppSort attribute={"products"} field={filter.options.sort_field} mode={filter.options.sort_mode}></AppSort>
                        </div>
                      </th>
                      <th className="w-100px text-end">{lang.labels.actions}</th>
                    </tr>
                  </thead>
                  { categories.length > 0
                    ?
                    <tbody className="text-gray-700 fw-bold">
                      { categories.map (( (item, index) => { return (
                        <tr key={index} className="row-dashed">
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <input className="form-check-input" type="checkbox" value={item.id} checked={checked.list.includes(item.id)} onChange={handleChangeSelectCategory} />
                            </div>
                          </td>
                          <td className="d-flex flex-column">
                            <Link to={"/app/inventory/category/view/" + item.id} className="fw-bolder text-dark text-capitalize text-hover-primary mb-1">{item.display_name}</Link>
                            <small>{item.description}</small>
                          </td >
                          <td className={`text-${quantityAssets(item.services.length)} text-center fw-bolder`}>{item.services.length}</td>
                          <td className={`text-${quantityAssets(item.products.length)} text-center fw-bolder`}>{item.products.length}</td>
                          <td className="text-end">
                            <Dropdown>
                              <Dropdown.Toggle variant="btn btn-sm btn-light btn-active-light-primary">
                                <div className="d-flex align-items-center">
                                  {lang.labels.actions}
                                  <span className="svg-icon svg-icon-5 me-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"></path>
                                    </svg>
                                  </span>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary w-auto p-3 fs-7" align="end">
                                <Dropdown.Item bsPrefix="menu-item" onClick={() => submitCategoryUpdate(item.id)}>
                                  <p className="menu-link fw-bold px-3 py-1 m-0">{lang.labels.edit}</p>
                                </Dropdown.Item>
                                <Dropdown.Item bsPrefix="menu-item" onClick={() => submitCategoryDelete(item.id)}>
                                  <p className="menu-link fw-bold px-3 py-1 m-0">{lang.labels.delete}</p>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody>
                      <tr className="text-gray-700 fw-bold">
                        <td valign="top" colSpan={5} className="text-center">{lang.labels.noRecordsFound}</td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalCategoryCreate companyForUser={companyForUser} categoriesForCompany={categoriesForCompany} accountingExpenseAccountsForCompany={accountingExpenseAccountsForCompany} accountingVatAccountsForCompany={accountingVatAccountsForCompany} accountingProductAccountsForCompany={accountingProductAccountsForCompany} category={null} categories={categories} setCategoriesForCompany={setCategoriesForCompany} setCategory={null} setCategories={setCategories} setObjectAccounts={null}></AppModalCategoryCreate>
      <AppModalCategoryUpdate companyForUser={companyForUser} categoriesForCompany={categoriesForCompany} accountingExpenseAccountsForCompany={accountingExpenseAccountsForCompany} accountingVatAccountsForCompany={accountingVatAccountsForCompany} accountingProductAccountsForCompany={accountingProductAccountsForCompany} category={category} categories={categories} setCategoriesForCompany={setCategoriesForCompany} setCategory={setCategory} setCategories={setCategories}></AppModalCategoryUpdate>
    </div>
  )
};

export default CategoryListPage;
