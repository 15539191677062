import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextCompany } from './Company';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/translations/response';
import { ApiGeolocation } from '../../../services/api.geolocation';
import { CompanyDL } from '../../../services/company.service';
import { SettingsDA } from '../../../services/settings.services';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { TokenData } from '../../../models/token.data.model';
import { Currency } from '../../../models/currency.model';
import { Container } from '../../../styles/container.style';
import { legendInvalidIcon, legendValid, legendValidRequiredIcon, legendValidInvalidIconRestart, legendInvalid, legendValidInvalidRequiredRestart } from '../../../tools/legend.data.entry.tool';
import { nextForm, previousForm, firstForm, lastForm, removeFirstForm, removeLastForm } from '../../../tools/stepper.tool';
import { toastError, toastSuccess } from '../../../tools/toast.tool';
import { uploadTooltip } from '../../../tools/tooltip.tool';
import { uploadImage } from '../../../scripts/upload.image.script';
import { evaluateLegendValidateEmptyIcon, evaluateLegendValidateRequiredIcon } from '../../../scripts/validate.legend.script';
import { expressions } from '../../../libraries/regular.expressions.library';
import { countryList, countryCode, countryName } from '../../../libraries/countries.library'
import { languageDownloadList, languageDownloadValue } from '../../../libraries/language.download.library';
import { qrTemplateList, qrTemplateValue } from '../../../libraries/qr.template.library';
import { currencyList, currencyValue } from '../../../libraries/currency.library';

import AppLegend from '../../../components/element/Legend';

export interface CompanyCreatePageProps {};

let errorResponse: Error, messageResponse: Message, tokenDataResponse: TokenData;

const CompanyCreatePage: React.FunctionComponent<CompanyCreatePageProps> = props => {
  const {setRoute, appServiceForUser, reloadUserLoggedIn} = UseOutletContextCompany()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [logo, setLogo] = useState({value: '', file: null as any, valid: true})
  const [street, setStreet] = useState({value: '', valid: false})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: false})
  const [additional, setAdditional] = useState({value: '', valid: true})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: true})
  const [vatNumber, setVatNumber] = useState({value: '', valid: true})
  const [registerNumber, setRegisterNumber] = useState({value: '', valid: true})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [language, setLanguage] = useState({value: '', valid: false})
  const [qrType, setQrTtype] = useState({value: '', valid: false})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const submitCompanyCreate = async () => {
    if (appServiceForUser) {
      setLoadIndicator('on')

      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value

      await CompanyDL.postCompany(appServiceForUser.api_key, name.value, logo.file, street.value, addressStreetNumber, additional.value, administrativeArea.value, city.value, zipCode.value, country.value, email.value, phone.value, website.value, vatNumber.value, registerNumber.value, currency.object, language.value, qrType.value).then( (response) => {
        if (response.status === 201) {
          tokenDataResponse = response.data

          reloadUserLoggedIn(tokenDataResponse.authorization, appServiceForUser.api_key, tokenDataResponse.data.id, async () => {
            await SettingsDA.postSettings(tokenDataResponse.data.id).then( (response) => {
              if (response.status === 201) {
                messageResponse = response.data
                toastSuccess(getMessage(messageResponse.message, lang.code))
              } else {
                errorResponse = response.data
                toastError(getError(errorResponse.code, lang.code))
              }

              Swal.fire({
                title: lang.labels.successfullyCreatedCompany,
                text: lang.labels.newCompanyIsSelectedByDefault,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                setLoadIndicator('off')
                navigate('/app/dashboard', {replace: true})
              })
            }).catch( (error) => {
              console.error(error)
              window.location.href = '/error'
            })
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeLogo = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    uploadImage(event.target.files[0], logo, setLogo, 'container-validate-logo-type', 'container-validate-logo-size')
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreetNumber({...streetNumber, value: event.target.value})
  }

  const handleChangeAdditional = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdditional({...additional, value: event.target.value})
  }

  const handleChangeAdministrativeArea = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdministrativeArea({...administrativeArea, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeCountry = (item: string) => {
    setCountry({...country, value: item, valid: true})
    legendValid('container-validate-country-required')
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeVatNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVatNumber({...vatNumber, value: event.target.value})
  }

  const handleChangeRegisterNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setRegisterNumber({...registerNumber, value: event.target.value})
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
    legendValid('container-validate-currency-required')
  }

  const handleChangeLanguage = (item: string) => {
    setLanguage({...language, value: item, valid: true})
    legendValid('container-validate-language-required')
  }

  const handleChangeQrType = (item: string) => {
    setQrTtype({...qrType, value: item, valid: true})
    legendValid('container-validate-qr-type-required')
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of countryList(lang.code)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = countryList(lang.code)
    }

    setSearchCountry({text: text, list: list})
  }

  const handleRemoveLogo = () => {
    setLogo({value: '', file: null, valid: true})
  }

  const validateName = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, name, setName, 'input-name', 'container-validate-name-valid', 'container-validate-name-required')
  }

  const validateStreet = () => {
    evaluateLegendValidateRequiredIcon(expressions.street, street, setStreet, 'input-street', 'container-validate-street-valid', 'container-validate-street-required')
  }

  const validateNumber = () => {
    if (streetNumber.value === 'NN' || streetNumber.value === 'nn') {
      setStreetNumber({...streetNumber, valid: true})
      legendValidRequiredIcon('input-street-number', 'container-validate-street-number-valid', 'container-validate-street-number-required')
    } else {
      evaluateLegendValidateRequiredIcon(expressions.streetnumber, streetNumber, setStreetNumber, 'input-street-number', 'container-validate-street-number-valid', 'container-validate-street-number-required')
    }
  }

  const validateAdditional = () => {
    evaluateLegendValidateEmptyIcon(expressions.text, additional, setAdditional, 'input-additional', 'container-validate-additional-valid')
  }

  const validateAdministrativeArea = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, administrativeArea, setAdministrativeArea, 'input-administrative-area', 'container-validate-administrative-area-valid', 'container-validate-administrative-area-required')
  }

  const validateCity = () => {
    evaluateLegendValidateRequiredIcon(expressions.location, city, setCity, 'input-city', 'container-validate-city-valid', 'container-validate-city-required')
  }

  const validateZipCode = () => {
    evaluateLegendValidateRequiredIcon(expressions.zipcode, zipCode, setZipCode, 'input-zip-code', 'container-validate-zip-code-valid', 'container-validate-zip-code-required')
  }

  const validateEmail = () => {
    evaluateLegendValidateRequiredIcon(expressions.email, email, setEmail, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
  }

  const validatePhone = () => {
    evaluateLegendValidateRequiredIcon(expressions.phone, phone, setPhone, 'input-phone', 'container-validate-phone-valid', 'container-validate-phone-required')
  }

  const validateWebsite = () => {
    evaluateLegendValidateEmptyIcon(expressions.website, website, setWebsite, 'input-website', 'container-validate-website-valid')
  }

  const validateVatNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.vat, vatNumber, setVatNumber, 'input-vat-number', 'container-validate-vat-number-valid')
  }

  const validateRegisterNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.registernumber, registerNumber, setRegisterNumber, 'input-register-number', 'container-validate-register-number-valid')
  }

  const executeFormPrevious = () => {
    switch (true) {
      case document.getElementById('form-title-6')?.classList.contains('current'):
        removeLastForm('container-stepper-form')
        previousForm('form-title-5', 'form-title-6', 'form-container-5', 'form-container-6')
        break;
      case document.getElementById('form-title-5')?.classList.contains('current'):
        previousForm('form-title-4', 'form-title-5', 'form-container-4', 'form-container-5')
        break;
      case document.getElementById('form-title-4')?.classList.contains('current'):
        previousForm('form-title-3', 'form-title-4', 'form-container-3', 'form-container-4')
        break;
      case document.getElementById('form-title-3')?.classList.contains('current'):
        previousForm('form-title-2', 'form-title-3', 'form-container-2', 'form-container-3')
        break;
      case document.getElementById('form-title-2')?.classList.contains('current'):
        firstForm('container-stepper-form')
        previousForm('form-title-1', 'form-title-2', 'form-container-1', 'form-container-2')
        break;
    }

    uploadTooltip()

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const executeFormNext = () => {
    switch (true) {
      case document.getElementById('form-title-1')?.classList.contains('current'):
        removeFirstForm('container-stepper-form')
        nextForm('form-title-1', 'form-title-2', 'form-container-1', 'form-container-2')
        break;
      case document.getElementById('form-title-2')?.classList.contains('current'):
        if (name.valid === true) {
          nextForm('form-title-2', 'form-title-3', 'form-container-2', 'form-container-3')
          if (!logo.valid) {
            setLogo({value: '', file: null, valid: true})
            legendValidInvalidRequiredRestart('container-validate-logo-type', 'container-validate-logo-size')
          }
        } else {
          Swal.fire({
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            if (name.value.length === 0) {
              legendInvalidIcon('input-name', 'container-validate-name-required')
            }
          })
        }
        break;
      case document.getElementById('form-title-3')?.classList.contains('current'):
        if (street.valid && streetNumber.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid && email.valid && phone.valid) {
          nextForm('form-title-3', 'form-title-4', 'form-container-3', 'form-container-4')
          if (!additional.valid) {
            setAdditional({value: '', valid: true})
            legendValidInvalidIconRestart('input-additional', 'container-validate-additional-valid')
          }
          if (!website.valid) {
            setWebsite({value: '', valid: true})
            legendValidInvalidIconRestart('input-website', 'container-validate-website-valid')
          }
        } else {
          Swal.fire({
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            if (street.value.length === 0) {
              legendInvalidIcon('input-street', 'container-validate-street-required')
            }
            if (streetNumber.value.length === 0) {
              legendInvalidIcon('input-street-number', 'container-validate-street-number-required')
            }
            if (administrativeArea.value.length === 0) {
              legendInvalidIcon('input-administrative-area', 'container-validate-administrative-area-required')
            }
            if (city.value.length === 0) {
              legendInvalidIcon('input-city', 'container-validate-city-required')
            }
            if (zipCode.value.length === 0) {
              legendInvalidIcon('input-zip-code', 'container-validate-zip-code-required')
            }
            if (country.value.length === 0) {
              legendInvalid('container-validate-country-required')
            }
            if (email.value.length === 0) {
              legendInvalidIcon('input-email', 'container-validate-email-required')
            }
            if (phone.value.length === 0) {
              legendInvalidIcon('input-phone', 'container-validate-phone-required')
            }
          })
        }
        break;
      case document.getElementById('form-title-4')?.classList.contains('current'):
        if (currency.valid) {
          nextForm('form-title-4', 'form-title-5', 'form-container-4', 'form-container-5')
          if (!vatNumber.valid) {
            setVatNumber({value: '', valid: true})
            legendValidInvalidIconRestart('input-vat-number', 'container-validate-vat-number-valid')
          }
          if (!registerNumber.valid) {
            setRegisterNumber({value: '', valid: true})
            legendValidInvalidIconRestart('input-register-number', 'container-validate-register-number-valid')
          }
        } else {
          Swal.fire({
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            if (currency.value.length === 0) {
              legendInvalid('container-validate-currency-required')
            }
          })
        }
        break;
      case document.getElementById('form-title-5')?.classList.contains('current'):
        if (language.valid && qrType.valid) {
          lastForm('container-stepper-form')
          nextForm('form-title-5', 'form-title-6', 'form-container-5', 'form-container-6')
        } else {
          Swal.fire({
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
            icon: 'error',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            if (language.value.length === 0) {
              legendInvalid('container-validate-language-required')
            }
            if (qrType.value.length === 0) {
              legendInvalid('container-validate-qr-type-required')
            }
          })
        }
        break;
    }

    uploadTooltip()

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  async function loadLocationBrowser() {
    ApiGeolocation.getLocateIPAddress().then( (response) => {
      if (response) {
        setCountry({value: response.country_code, valid: true})
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  function uploadDropdown() {
    setSearchCountry({text: '', list: countryList(lang.code)})
  }

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.companies, branch: lang.labels.createCompany}, company: false})
    loadLocationBrowser()
    uploadTooltip()

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="card">
      <div className="card-body">
        <div id="container-stepper-form" className="stepper stepper-links d-flex flex-column">
          <div className="stepper-nav d-flex justify-content-center align-content-center py-5">
            <div className="stepper-item">
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="black"/>
                  <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="black"/>
                </svg>
              </span>
            </div>
            <div id="form-title-1" className="stepper-item current" data-kt-stepper-element="nav">
              <h3 className="stepper-title">{lang.labels.new}</h3>
            </div>
            <div id="form-title-2" className="stepper-item pending" data-kt-stepper-element="nav">
              <h3 className="stepper-title">{lang.labels.identity}</h3>
            </div>
            <div id="form-title-3" className="stepper-item pending" data-kt-stepper-element="nav">
              <h3 className="stepper-title">{lang.labels.contact}</h3>
            </div>
            <div id="form-title-4" className="stepper-item pending" data-kt-stepper-element="nav">
              <h3 className="stepper-title">{lang.labels.legal}</h3>
            </div>
            <div id="form-title-5" className="stepper-item pending" data-kt-stepper-element="nav">
              <h3 className="stepper-title">{lang.labels.settings}</h3>
            </div>
            <div id="form-title-6" className="stepper-item pending" data-kt-stepper-element="nav">
              <h3 className="stepper-title">{lang.labels.completed}</h3>
            </div>
            <div className="stepper-item">
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.6 4L6.6 12L14.6 20H10.6L3.3 12.7C2.9 12.3 2.9 11.7 3.3 11.3L10.6 4H14.6Z" fill="black"/>
                  <path opacity="0.3" d="M21.6 4L13.6 12L21.6 20H17.6L10.3 12.7C9.9 12.3 9.9 11.7 10.3 11.3L17.6 4H21.6Z" fill="black"/>
                </svg>
              </span>
            </div>
          </div>
          <div className="form mx-auto mw-700px w-100 py-5">
            <div id="form-container-1" className="current" data-kt-stepper-element="content">
              <div className="w-100">
                <div className="pb-10">
                  <h2 className="text-dark">{lang.labels.createNewCompany}</h2>
                  <div className="text-muted fw-bold">{lang.labels.aNewCompanyWillBeCreated}</div>
                </div>
                <div className="fv-row">
                  <div className="row">
                    <div className="col-12">
                      <input className="btn-check" type="radio" defaultChecked />
                      <label className="btn btn-outline btn-outline-dashed btn-outline-default d-flex align-items-center">
                        <span className="svg-icon svg-icon-3x me-5">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="black"/>
                            <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="black"/>
                            <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="black"/>
                          </svg>
                        </span>
                        <span className="d-block text-justify">
                          <span className="d-block text-dark fw-bolder fs-4 mb-2">{lang.labels.company}</span>
                          <span className="text-muted text-gray-600 fw-bold">
                            {lang.labels.allTheFieldsAreValidated}
                            <br />
                            {lang.labels.pleaseClickContinue}
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="form-container-2" className="pending" data-kt-stepper-element="content">
              <div className="w-100">
                <div className="pb-10">
                  <h2 className="text-dark">{lang.labels.companyIdentity}</h2>
                  <div className="text-muted fw-bold">
                    {lang.labels.ifYouNeedMoreInfo}
                    <a href="https://digitaladmin.ch/" className="link-primary text-lowercase fw-bolder ms-2">{lang.labels.helpPage}</a>
                  </div>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label required">{lang.labels.companyName}</label>
                  <input id="input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                  <AppLegend component={null} attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row">
                  <label className="form-label">
                    <div className="d-flex align-items-center">
                      {lang.labels.companyLogo}
                      <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.dragOrClickOnTheBoxToAddTheCompanyLogo}><FaExclamationCircle /></i>
                    </div>
                  </label>
                  <div className="row mb-2" data-kt-buttons="true">
                    <div className="col">
                      <label className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4">
                        <input className="btn-check" type="file" name="logo" accept=".png, .jpg, .jpeg" onChange={handleChangeLogo} />
                        { logo.value
                          ?
                          <img src={logo.value} alt={lang.labels.logo} className="rounded w-100" />
                          :
                          <span className="fw-bolder fs-3">{lang.labels.addImage}</span>
                        }
                      </label>
                    </div>
                  </div>
                  <Container property={(logo.value.length === 0 && logo.file === null).toString()}>
                    <div className="form-text text-justify">{lang.labels.theFileMustBeFormat}</div>
                  </Container>
                  <Container property={(logo.value.length > 0 && logo.file !== null).toString()}>
                    <div className="d-flex justify-content-end">
                      <button className="btn btn-sm btn-danger text-uppercase" type="button" data-kt-image-input-action="remove" onClick={handleRemoveLogo}>
                        {lang.labels.removeLogo}
                      </button>
                    </div>
                  </Container>
                  <AppLegend component={null} attribute={{validity: logo.valid, name: "logo", index: null, sub_index: null}} container={{valid: false, required: false, size: true, type: true, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            </div>
            <div id="form-container-3" className="pending" data-kt-stepper-element="content">
              <div className="w-100">
                <div className="pb-10">
                  <h2 className="text-dark">{lang.labels.contactInformation}</h2>
                  <div className="text-muted fw-bold">
                    {lang.labels.ifYouNeedMoreInfo}
                    <a href="https://digitaladmin.ch/" className="link-primary text-lowercase fw-bolder ms-2">{lang.labels.helpPage}</a>
                  </div>
                </div>
                <div className="row fv-row mb-5">
                  <div className="col-xl-8 mb-5 mb-xl-0">
                    <label className="form-label required">{lang.labels.street}</label>
                    <input id="input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                    <AppLegend component={null} attribute={{validity: street.valid, name: "street", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="col-xl-4">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        <span className="required">{lang.labels.number}</span>
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifAddressDoesNotHaveANumber}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <input id="input-street-number" className="form-control form-control-solid" type="text" name="street-number" placeholder={lang.labels.noNumber} value={streetNumber.value} onChange={handleChangeNumber} onKeyUp={validateNumber} onBlur={validateNumber} />
                    <AppLegend component={null} attribute={{validity: streetNumber.valid, name: "street-number", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label">
                    <div className="d-flex align-items-center">
                      {lang.labels.additional}
                      <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifNecessaryEnterSomethingReferentialRegardingAddress}><FaExclamationCircle /></i>
                    </div>
                  </label>
                  <input id="input-additional" className="form-control form-control-solid" type="text" name="additional" value={additional.value} onChange={handleChangeAdditional} onKeyUp={validateAdditional} onBlur={validateAdditional} />
                  <AppLegend component={null} attribute={{validity: additional.valid, name: "additional", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label required">{lang.labels.administrativeArea}</label>
                  <input id="input-administrative-area" className="form-control form-control-solid" type="text" name="administrative-area" value={administrativeArea.value} onChange={handleChangeAdministrativeArea} onKeyUp={validateAdministrativeArea} onBlur={validateAdministrativeArea} />
                  <AppLegend component={null} attribute={{validity: administrativeArea.valid, name: "administrative-area", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="row fv-row mb-5">
                  <div className="col-xl-4 mb-5 mb-xl-0">
                    <label className="form-label">
                      <div className="d-flex align-items-center">
                        <span className="required">{lang.labels.zipCode}</span>
                        <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.enterThePostalCodeOfTheCity}><FaExclamationCircle /></i>
                      </div>
                    </label>
                    <input id="input-zip-code" className="form-control form-control-solid" type="text" name="zip-code" value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                    <AppLegend component={null} attribute={{validity: zipCode.valid, name: "zip-code", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                  <div className="col-xl-8">
                    <label className="form-label required">{lang.labels.city}</label>
                    <input id="input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                    <AppLegend component={null} attribute={{validity: city.valid, name: "city", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                  </div>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label required">{lang.labels.country}</label>
                  <Dropdown onToggle={uploadDropdown}>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {country.valid ? countryName(country.value, lang.code) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { searchCountry.list.length > 0
                              ?
                              <>
                                { searchCountry.list.map (( (item, index) => { return (
                                  <li key={index} className={`select2-results__option select2-results__option--selectable ${countryCode(item, lang.code) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={countryCode(item, lang.code) === country.value}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(countryCode(item, lang.code))}>{item}</Dropdown.Item>
                                  </li>
                                )}))}
                              </>
                              :
                              <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultFound}</li>
                            }
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend component={null} attribute={{validity: country.valid, name: "country", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label required">{lang.labels.email}</label>
                  <input id="input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                  <AppLegend component={null} attribute={{validity: email.valid, name: "email", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label required">{lang.labels.phoneNumber}</label>
                  <input id="input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                  <AppLegend component={null} attribute={{validity: phone.valid, name: "phone", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row">
                  <label className="form-label">{lang.labels.webSite}</label>
                  <input id="input-website" className="form-control form-control-solid" type="text" name="website" value={website.value} onChange={handleChangeWebsite} onKeyUp={validateWebsite} onBlur={validateWebsite} />
                  <AppLegend component={null} attribute={{validity: website.valid, name: "website", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            </div>
            <div id="form-container-4" className="pending" data-kt-stepper-element="content">
              <div className="w-100">
                <div className="pb-10">
                  <h2 className="text-dark">{lang.labels.legalInformation}</h2>
                  <div className="text-muted fw-bold">
                    {lang.labels.ifYouNeedMoreInfo}
                    <a href="https://digitaladmin.ch/" className="link-primary text-lowercase fw-bolder ms-2">{lang.labels.helpPage}</a>
                  </div>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label">
                    <div className="d-flex align-items-center">
                      {lang.labels.vatNumber}
                      <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.identificationNumberForACompany}><FaExclamationCircle /></i>
                    </div>
                  </label>
                  <input id="input-vat-number" className="form-control form-control-solid" type="text" name="vat-number" value={vatNumber.value} onChange={handleChangeVatNumber} onKeyUp={validateVatNumber} onBlur={validateVatNumber} />
                  <AppLegend component={null} attribute={{validity: vatNumber.valid, name: "vat-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row mb-5 d-none">
                  <label className="form-label">{lang.labels.registerNumber}</label>
                  <input id="input-register-number" className="form-control form-control-solid" type="text" name="register-number" value={registerNumber.value} onChange={handleChangeRegisterNumber} onKeyUp={validateRegisterNumber} onBlur={validateRegisterNumber} />
                  <AppLegend component={null} attribute={{validity: registerNumber.valid, name: "register-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row">
                  <label className="form-label required">{lang.labels.currency}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {currency.valid ? currencyValue(currency.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { currencyList().map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend component={null} attribute={{validity: currency.valid, name: "currency", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            </div>
            <div id="form-container-5" className="pending" data-kt-stepper-element="content">
              <div className="w-100">
                <div className="pb-10">
                  <h2 className="text-dark">{lang.labels.settingInformation}</h2>
                  <div className="text-muted fw-bold">
                    {lang.labels.ifYouNeedMoreInfo}
                    <a href="https://digitaladmin.ch/" className="link-primary text-lowercase fw-bolder ms-2">{lang.labels.helpPage}</a>
                  </div>
                </div>
                <div className="fv-row mb-5">
                  <label className="form-label">
                    <div className="d-flex align-items-center">
                      <span className="required">{lang.labels.language}</span>
                      <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.languageToDownload}><FaExclamationCircle /></i>
                    </div>
                  </label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {language.valid ? languageDownloadValue(lang, language.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { languageDownloadList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === language.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === language.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeLanguage(item.code)}>{item.description}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend component={null} attribute={{validity: language.valid, name: "language", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
                <div className="fv-row">
                  <label className="form-label">
                    <div className="d-flex align-items-center">
                      <span className="required">{lang.labels.qrType}</span>
                      <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.shapesOrVersionsOfQR}><FaExclamationCircle /></i>
                    </div>
                  </label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {qrType.valid ? qrTemplateValue(lang, qrType.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { qrTemplateList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === qrType.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === qrType.value}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeQrType(item.code)}>{item.description}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  <AppLegend component={null} attribute={{validity: qrType.valid, name: "qr-type", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                </div>
              </div>
            </div>
            <div id="form-container-6" className="pending" data-kt-stepper-element="content">
              <div className="w-100">
                <div className="pb-10">
                  <h2 className="text-dark">{lang.labels.yourAreDone}</h2>
                  <div className="text-muted fw-bold">{lang.labels.theEnteredInformationHasAlreadyValidated}</div>
                </div>
                <div className="mb-0">
                  <input className="btn-check" type="radio" defaultChecked />
                  <label className="btn bg-light-warning border-warning border border-dashed p-6 d-flex align-items-center">
                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
                        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                      </svg>
                    </span>
                    <div className="d-flex flex-stack">
                      <div className="text-justify">
                        <div className="text-dark fw-bolder fs-4 mb-2">{lang.labels.weNeedYourAttention}</div>
                        <div className="text-muted text-gray-600 fw-bold">
                          {lang.labels.theNonRequiredFields}
                          <br />
                          {lang.labels.toFinishCreationNewCompany}
                          <span className="fw-bolder text-primary ms-1 text-lowercase">{lang.labels.create}</span>
                          .
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex flex-stack pt-15">
              <div>
                <button className="btn btn-light-primary me-3" type="button" data-kt-stepper-action="previous" onClick={executeFormPrevious}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-3 ms-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
                      </svg>
                    </span>
                    {lang.labels.back}
                  </div>
                </button>
              </div>
              <div>
                <button className="btn btn-primary" type="button" data-kt-stepper-action="next" onClick={executeFormNext}>
                  <div className="d-flex align-items-center">
                    {lang.labels.continue}
                    <span className="svg-icon svg-icon-3 me-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
                        <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
                      </svg>
                    </span>
                  </div>
                </button>
                <button className="btn btn-primary" data-kt-stepper-action="submit" type="button" data-kt-indicator={loadIndicator} onClick={submitCompanyCreate}>
                  <span className="indicator-label">
                    <div className="d-flex align-items-center">
                      {lang.labels.create}
                      <span className="svg-icon svg-icon-3 me-0">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z" fill="black"/>
                          <path opacity="0.3" d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z" fill="black"/>
                        </svg>
                      </span>
                    </div>
                  </span>
                  <span className="indicator-progress">
                    {lang.labels.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default CompanyCreatePage;
