import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { CompanyAccountingAccounts } from '../models/company.accounting.accounts.model';
import { CompanyAccountingTreasuryAccounts } from '../models/company.accounting.treasury.accounts.model';
import { appToken } from '../scripts/app.token.script';
import { AccountingAccount } from '../models/accounting.account.model';
import { Company } from '../models/company.model';
import { Names } from '../models/names.model';

export class AccountingAccountDA {
  static apiResponse: ApiResponse

  public static async getAccountingAccounts(id_company: string, include: string[], from: string[], between: string[][]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let accountingAccountsByCompany: CompanyAccountingAccounts | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/accounting.accounts.data.json').then(response => { accountingAccountsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (accountingAccountsByCompany!.id_company === id_company) {
        let regex: RegExp = new RegExp('')

        switch (true) {
          case include.length > 0 && from.length === 0 && between.length === 0:
            regex = new RegExp(`^(${include.join('|')})(\\.\\d+)?$`)
            break;
          case include.length === 0 && from.length > 0 && between.length === 0:
            regex = new RegExp(`^(${from.map(code => `${code[0]}\\d{3}`).join('|')})(\\.\\d+)?$`)
            break;
          case include.length === 0 && from.length === 0 && between.length > 0:
            regex = new RegExp(`^(${between.map(([start, end]) => {
              if (start === end) return start;
              return Array.from({length: parseInt(end, 10) - parseInt(start, 10) + 1}, (_, i) => parseInt(start, 10) + i).join('|');
            }).join('|')})(\\.\\d+)?$`);
            break;
        }

        accountingAccountsByCompany!.filter.include = include
        accountingAccountsByCompany!.filter.from = from
        accountingAccountsByCompany!.filter.between = between
        accountingAccountsByCompany!.accounting_accounts = accountingAccountsByCompany!.accounting_accounts.filter((item: AccountingAccount) => regex.test(item.code))

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: accountingAccountsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/accounts?codes=${include}&from=${from}&between=${between}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getAccountingTreasuryAccounts(id_company: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let accountingTreasuryAccountsByCompany: CompanyAccountingTreasuryAccounts | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/accounting.treasury.accounts.data.json').then(response => { accountingTreasuryAccountsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (accountingTreasuryAccountsByCompany!.id_company === id_company) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: accountingTreasuryAccountsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/accounts/available`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postAccountingTreasuryAccount(id_company: string, type: string, code: string, name: Names): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let accountingAccount: AccountingAccount | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        accountingAccount = {
          id: `account${(Math.floor(Math.random() * 1000)).toString()}`,
          code: code,
          type: type,
          level: 3,
          coefficient: 0,
          available: true,
          id_parent: '',
          id_mirror_account: '',
          name: name
        }

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: accountingAccount
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/accounts`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        type: type,
        code: code,
        name: name
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
