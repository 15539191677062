import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextProduct } from './Product';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ProductDA } from '../../../../services/product.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Product } from '../../../../models/product.model';
import { imageProduct } from '../../../../scripts/image.value.script';
import { moneyFormat, taxRateFormat } from '../../../../scripts/format.value.script';
import { datetimeFormat12h } from '../../../../scripts/datetime.script';
import { accountingAccountSelected } from '../../../../scripts/selected.item.list.script';
import { languageDownloadValue } from '../../../../libraries/language.download.library';
import { discountDescription, discountType } from '../../../../libraries/discount.library';
import { uploadTooltip } from '../../../../tools/tooltip.tool';

import imgProductDefault from '../../../../assets/images/product.png';

export interface ProductViewPageProps {};

let errorResponse: Error, messageResponse: Message, productResponse: Product;

const ProductViewPage: React.FunctionComponent<ProductViewPageProps> = props => {
  const {setRoute, companyForUser, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, loadProductsForCompany} = UseOutletContextProduct()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [product, setProduct] = useState<Product | undefined | null>(null)

  const loadProduct = async (id_company: string, id_product: string) => {
    await ProductDA.getProduct(id_company, id_product).then( (response) => {
      if (response.status === 200) {
        productResponse = response.data
        setProduct(productResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitProductDelete = () => {
    if (companyForUser && product) {
      Swal.fire({
        title: lang.labels.youWantDeleteProduct,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProductDA.deleteProducts(companyForUser.id, [product.id]).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data
              loadProductsForCompany(companyForUser.id)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.code),
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/inventory/product/list', {replace: true})
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  useEffect( () => {
    if(companyForUser && param.id) {
      loadProduct(companyForUser.id, param.id)
      uploadTooltip()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.products, branch: lang.labels.viewProduct}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany && product
        ?
        <div className="d-flex flex-column flex-xl-row">
          <div className="d-flex flex-column flex-xl-row-auto gap-5 w-100 w-xl-350px mb-5 mb-xl-0">
            <div className="d-xl-none d-flex flex-wrap justify-content-end gap-2">
              <Link to={"/app/inventory/product/update/" + product.id} className="btn btn-primary btn-sm">{lang.labels.editProduct}</Link>
              <button className="btn btn-danger btn-sm" type="button" onClick={submitProductDelete}>{lang.labels.deleteProduct}</button>
            </div>
            <div className="card card-flush">
              <div className="card-header pt-3">
                <div className="card-title">
                  <h3 className="text-uppercase">{product.display_name}</h3>
                </div>
                <div className="card-toolbar">
                  <div className="badge badge-light-info d-inline fs-7">{product.category.display_name}</div>
                </div>
              </div>
              <div className="card-body text-center pt-0">
                { product.photos.length > 0
                  ?
                  <Carousel fade variant="dark">
                    { product.photos.map (( (item, index) => { return (
                      <Carousel.Item key={index} bsPrefix="carousel-item d-flex justify-content-center align-items-center">
                        <img src={imageProduct(item)} className="d-block w-175px h-175px" alt={lang.labels.photography + index} />
                      </Carousel.Item>
                    )}))}
                  </Carousel>
                  :
                  <div className="symbol symbol-175px">
                    <div className="symbol-label">
                      <img src={imgProductDefault} className="w-100 h-100" alt={lang.labels.photography} />
                    </div>
                  </div>
                }
                <div className="text-muted mt-2">{lang.labels.productReferencePictures}</div>
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h5>{lang.labels.names}</h5>
                </div>
              </div>
              <div className="card-body pt-0">
                { Object.values(product.names).every(value => value.trim() === "")
                  ?
                  <div className="text-gray-700 text-justify">
                    {lang.labels.noRecordsFound}
                  </div>
                  :
                  <>
                    { Object.entries(product.names).map (( (item, index) => { return (
                      <div key={index} className={`${index < 3 && "pb-2"} ${item[1].length === 0 && "d-none"}`}>
                        <div className="fw-bold text-gray-900">{item[1]}</div>
                        <div className="fs-8 text-muted">{languageDownloadValue(lang, item[0].toUpperCase())}</div>
                      </div>
                    )}))}
                  </>
                }
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h5>{lang.labels.tags}</h5>
                </div>
              </div>
              <div className="card-body pt-0">
                { product.tags.length > 0
                  ?
                  <>
                    { product.tags.map (( (item, index) => { return (
                      <div key={index} className={`d-flex align-items-center ${index < product.tags.length - 1 && "pb-1"}`}>
                        <span className="bullet bg-primary me-3"></span>
                        <span className="fw-bold text-gray-800">{item}</span>
                      </div>
                    )}))}
                  </>
                  :
                  <div>
                    {lang.labels.noRecordsFound}
                  </div>
                }
              </div>
            </div>
              <div className="card card-flush">
                <div className="card-header">
                  <div className="card-title">
                    <h5>{lang.labels.accountingAccounts}</h5>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="pb-2">
                    <div className="fs-8 text-muted d-flex align-items-center">
                      {lang.labels.expenseAccount}:
                      <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.purchases}><FaExclamationCircle /></i>
                    </div>
                    <div className="fw-bold text-gray-900">{accountingAccountSelected(lang, accountingExpenseAccountsForCompany, product.accounts.expense, companyForUser.language.toLowerCase())}</div>
                  </div>
                  <div className="pb-2">
                    <div className="fs-8 text-muted d-flex align-items-center">
                      {lang.labels.vatAccount}:
                      <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.purchases}><FaExclamationCircle /></i>
                    </div>
                    <div className="fw-bold text-gray-900">{accountingAccountSelected(lang, accountingVatAccountsForCompany, product.accounts.vat, companyForUser.language.toLowerCase())}</div>
                  </div>
                  <div className="pb-0">
                    <div className="fs-8 text-muted d-flex align-items-center">
                      {lang.labels.accountProduct}:
                      <i className="ms-2 fs-9" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.sales}><FaExclamationCircle /></i>
                    </div>
                    <div className="fw-bold text-gray-900">{accountingAccountSelected(lang, accountingProductAccountsForCompany, product.accounts.product, companyForUser.language.toLowerCase())}</div>
                  </div>
                </div>
              </div>
            <div className={`card card-flush d-none ${product.variants.length > 1 && "d-xl-block"}`}>
              <div className="card-body">
                <div className="pb-2">
                  <div className="text-muted fs-8">{lang.labels.createdAt}:</div>
                  <div className="fw-bold text-gray-900">{datetimeFormat12h(product.created_at)}</div>
                </div>
                <div className="pb-0">
                  <div className="text-muted fs-8">{lang.labels.updatedAt}:</div>
                  <div className="fw-bold text-gray-900">{datetimeFormat12h(product.updated_at)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid gap-5 ms-xl-5">
            <div className="d-none d-xl-flex flex-wrap justify-content-end gap-3">
              <Link to={"/app/inventory/product/update/" + product.id} className="btn btn-primary btn-sm">{lang.labels.editProduct}</Link>
              <button className="btn btn-danger btn-sm" type="button" onClick={submitProductDelete}>{lang.labels.deleteProduct}</button>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h5>{product.variants.length > 1 ? lang.labels.variants : lang.labels.details}</h5>
                </div>
              </div>
              <div className="card-body pt-0">
                { product.variants.length > 1
                  ?
                  <div className="row overflow-scroll">
                    { product.variants.map (( (item, index) => { return (
                      <div key={index} className="col d-flex flex-column border border-dashed border-gray-400 rounded p-5 m-2 bg-opacity-10">
                        <div className="d-flex align-items-center w-100 mb-3">
                          { item.photos.length > 0
                            ?
                            <Carousel slide={false} controls={false} indicators={false} touch={false}>
                              { item.photos.map (( (item, index) => { return (
                                <Carousel.Item key={index}>
                                  <img src={imageProduct(item)} className="d-block w-75px h-75px" alt={lang.labels.photography + index} />
                                </Carousel.Item>
                              )}))}
                            </Carousel>
                            :
                            <div className="symbol symbol-75px">
                              <span className="symbol-label">
                                <img src={imgProductDefault} alt={lang.labels.photography} className="w-100 h-100" />
                              </span>
                            </div>
                          }
                          <div className="w-100 ms-5">
                            <div className="fs-6 fw-bold mb-2">{item.description_short}</div>
                            { item.code.length > 0 &&
                              <div className="d-flex align-items-center fw-bold fs-7 text-gray-800 text-nowrap mb-1">
                                <span className="svg-icon svg-icon-5 svg-icon-dark me-2">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/>
                                    <path d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z" fill="black"/>
                                  </svg>
                                </span>
                                {item.code}
                              </div>
                            }
                            { item.sku.length > 0 &&
                              <div className="d-flex align-items-center fw-bold fs-7 text-gray-800 text-nowrap mb-1">
                                <span className="svg-icon svg-icon-5 svg-icon-dark me-2">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M3 6C2.4 6 2 5.6 2 5V3C2 2.4 2.4 2 3 2H5C5.6 2 6 2.4 6 3C6 3.6 5.6 4 5 4H4V5C4 5.6 3.6 6 3 6ZM22 5V3C22 2.4 21.6 2 21 2H19C18.4 2 18 2.4 18 3C18 3.6 18.4 4 19 4H20V5C20 5.6 20.4 6 21 6C21.6 6 22 5.6 22 5ZM6 21C6 20.4 5.6 20 5 20H4V19C4 18.4 3.6 18 3 18C2.4 18 2 18.4 2 19V21C2 21.6 2.4 22 3 22H5C5.6 22 6 21.6 6 21ZM22 21V19C22 18.4 21.6 18 21 18C20.4 18 20 18.4 20 19V20H19C18.4 20 18 20.4 18 21C18 21.6 18.4 22 19 22H21C21.6 22 22 21.6 22 21Z" fill="black"/>
                                    <path opacity="0.3" d="M21 13H15V11H21C21.6 11 22 10.6 22 10C22 9.4 21.6 9 21 9H15V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V9H11V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V9H3C2.4 9 2 9.4 2 10C2 10.6 2.4 11 3 11H9V13H3C2.4 13 2 13.4 2 14C2 14.6 2.4 15 3 15H9V21C9 21.6 9.4 22 10 22C10.6 22 11 21.6 11 21V15H13V21C13 21.6 13.4 22 14 22C14.6 22 15 21.6 15 21V15H21C21.6 15 22 14.6 22 14C22 13.4 21.6 13 21 13Z" fill="black"/>
                                    <path d="M16 17H8C7.4 17 7 16.6 7 16V8C7 7.4 7.4 7 8 7H16C16.6 7 17 7.4 17 8V16C17 16.6 16.6 17 16 17ZM14 10H10V14H14V10Z" fill="black"/>
                                  </svg>
                                </span>
                                {item.sku}
                              </div>
                            }
                            { item.codebar.length > 0 &&
                              <div className="d-flex align-items-center fw-bold fs-7 text-gray-800 text-nowrap">
                                <span className="svg-icon svg-icon-5 svg-icon-dark me-2">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M3 6C2.4 6 2 5.6 2 5V3C2 2.4 2.4 2 3 2H5C5.6 2 6 2.4 6 3C6 3.6 5.6 4 5 4H4V5C4 5.6 3.6 6 3 6ZM22 5V3C22 2.4 21.6 2 21 2H19C18.4 2 18 2.4 18 3C18 3.6 18.4 4 19 4H20V5C20 5.6 20.4 6 21 6C21.6 6 22 5.6 22 5ZM6 21C6 20.4 5.6 20 5 20H4V19C4 18.4 3.6 18 3 18C2.4 18 2 18.4 2 19V21C2 21.6 2.4 22 3 22H5C5.6 22 6 21.6 6 21ZM22 21V19C22 18.4 21.6 18 21 18C20.4 18 20 18.4 20 19V20H19C18.4 20 18 20.4 18 21C18 21.6 18.4 22 19 22H21C21.6 22 22 21.6 22 21Z" fill="black"/>
                                    <path d="M3 16C2.4 16 2 15.6 2 15V9C2 8.4 2.4 8 3 8C3.6 8 4 8.4 4 9V15C4 15.6 3.6 16 3 16ZM13 15V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V15C11 15.6 11.4 16 12 16C12.6 16 13 15.6 13 15ZM17 15V9C17 8.4 16.6 8 16 8C15.4 8 15 8.4 15 9V15C15 15.6 15.4 16 16 16C16.6 16 17 15.6 17 15ZM9 15V9C9 8.4 8.6 8 8 8H7C6.4 8 6 8.4 6 9V15C6 15.6 6.4 16 7 16H8C8.6 16 9 15.6 9 15ZM22 15V9C22 8.4 21.6 8 21 8H20C19.4 8 19 8.4 19 9V15C19 15.6 19.4 16 20 16H21C21.6 16 22 15.6 22 15Z" fill="black"/>
                                  </svg>
                                </span>
                                {item.codebar}
                              </div>
                            }
                          </div>
                        </div>
                        <table className="table fw-bold gs-0 g-1 m-0">
                          <tbody>
                            <tr>
                              <td className="w-90px fs-7 text-muted">{lang.labels.comment}:</td>
                              <td className="min-w-200px">{(item.description_long.length > 0) ? item.description_long : lang.labels.none}</td>
                            </tr>
                            <tr>
                              <td className="w-90px fs-7 text-muted">{lang.labels.size}:</td>
                              <td className="min-w-200px text-capitalize">{(item.size.length > 0) ? item.size : lang.labels.none}</td>
                            </tr>
                            <tr>
                              <td className="w-90px fs-7 text-muted">{lang.labels.color}:</td>
                              <td className="min-w-200px text-capitalize">{(item.color.length > 0) ? item.color : lang.labels.none}</td>
                            </tr>
                            <tr>
                              <td className="w-90px fs-7 text-muted">{lang.labels.unit}:</td>
                              <td className="min-w-200px text-capitalize">{(item.unit.id.length > 0) ? item.unit.name : lang.labels.none}</td>
                            </tr>
                            <tr>
                              <td className="w-90px fs-7 text-muted">{lang.labels.price}:</td>
                              <td className="min-w-200px text-nowrap">{companyForUser.currency.symbol} {moneyFormat(item.price)}</td>
                            </tr>
                            <tr>
                              <td className="w-90px fs-7 text-muted">{lang.labels.tax}:</td>
                              <td className="min-w-200px text-nowrap">{taxRateFormat(item.tax_rate)}</td>
                            </tr>
                            <tr>
                              <td className="w-90px fs-7 text-muted">{lang.labels.discount}:</td>
                              <td className="min-w-200px text-capitalize text-nowrap">
                                {discountDescription(lang, companyForUser.currency, item.discount)}
                                { item.discount.type !== "none" &&
                                  <span className="badge badge-light fs-9 text-uppercase ms-2">{discountType(lang, item.discount)}</span>
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}))}
                  </div>
                  :
                  <table className="table fw-bold gs-0 gy-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.description}:</td>
                        <td className="min-w-200px">{product.variants[0].description_short}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.comment}:</td>
                        <td className="min-w-200px">{(product.variants[0].description_long.length > 0) ? product.variants[0].description_long : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.code}:</td>
                        <td className="min-w-200px text-capitalize">{(product.variants[0].code.length > 0) ? product.variants[0].code : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.sku}:</td>
                        <td className="min-w-200px text-capitalize">{(product.variants[0].sku.length > 0) ? product.variants[0].sku : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.codebar}:</td>
                        <td className="min-w-200px text-capitalize">{(product.variants[0].codebar.length > 0) ? product.variants[0].codebar : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.size}:</td>
                        <td className="min-w-200px text-capitalize">{(product.variants[0].size.length > 0) ? product.variants[0].size : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.color}:</td>
                        <td className="min-w-200px text-capitalize">{(product.variants[0].color.length > 0) ? product.variants[0].color : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.unit}:</td>
                        <td className="min-w-200px text-capitalize">{product.variants[0].unit.name}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.price}:</td>
                        <td className="min-w-200px text-nowrap">{companyForUser.currency.symbol} {moneyFormat(product.variants[0].price)}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.tax}:</td>
                        <td className="min-w-200px text-nowrap">{taxRateFormat(product.variants[0].tax_rate)}</td>
                      </tr>
                      <tr>
                        <td className="w-150px text-muted fs-7">{lang.labels.discount}:</td>
                        <td className="min-w-200px text-capitalize text-nowrap">
                          {discountDescription(lang, companyForUser.currency, product.variants[0].discount)}
                          { product.variants[0].discount.type !== "none" &&
                            <span className="badge badge-light fs-9 text-uppercase ms-2">{discountType(lang, product.variants[0].discount)}</span>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              </div>
            </div>
            <div className={`card card-flush d-block ${product.variants.length > 1 && "d-xl-none"}`}>
              <div className="card-body">
                <div className="pb-2">
                  <div className="text-muted fs-8">{lang.labels.createdAt}:</div>
                  <div className="fw-bold text-gray-900">{datetimeFormat12h(product.created_at)}</div>
                </div>
                <div className="pb-0">
                  <div className="text-muted fs-8">{lang.labels.updatedAt}:</div>
                  <div className="fw-bold text-gray-900">{datetimeFormat12h(product.updated_at)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default ProductViewPage;
