import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { CategoryDA } from '../../services/category.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Category } from '../../models/category.model';
import { AccountingAccount } from '../../models/accounting.account.model';
import { modalHide } from '../../tools/modal.tool';
import { legendInvalid, legendInvalidIcon, legendValidInvalidRestart, legendValidInvalidIconRestart } from '../../tools/legend.data.entry.tool';
import { validateAttributes } from '../../scripts/validate.attributes.script';

import AppCategoryForm from '../../interfaces/main/inventory/category/card/CategoryForm';

export interface AppModalCategoryUpdateProps {
  companyForUser: Company | undefined | null,
  categoriesForCompany: Category[] | undefined | null,
  accountingExpenseAccountsForCompany: AccountingAccount[] | undefined | null,
  accountingVatAccountsForCompany: AccountingAccount[] | undefined | null,
  accountingProductAccountsForCompany: AccountingAccount[] | undefined | null,
  category: Category | undefined | null,
  categories: Category[] | undefined | null,
  setCategoriesForCompany: Dispatch<SetStateAction<Category[] | undefined | null>>,
  setCategory: Dispatch<SetStateAction<Category | undefined | null>>,
  setCategories: Dispatch<SetStateAction<Category[] | undefined | null>> | null
};

let errorResponse: Error, categoryResponse: Category;

const AppModalCategoryUpdate: React.FunctionComponent<AppModalCategoryUpdateProps> = ({companyForUser, categoriesForCompany, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, category, categories, setCategoriesForCompany, setCategory, setCategories}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [description, setDescription] = useState({value: '', valid: false})
  const [names, setNames] = useState({value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})
  const [accounts, setAccounts] = useState({value: {expense: '', vat: '', product: ''}, valid: {expense: false, vat: false, product: false}})

  const submitCategoryUpdate = async () => {
    setLoadIndicator('on')

    let namesIsValid: boolean = validateAttributes(names.valid)
    let accountsIsValid: boolean = validateAttributes(accounts.valid)

    if (companyForUser && categoriesForCompany && category && name.valid && description.valid && namesIsValid && accountsIsValid) {
      await CategoryDA.putCategory(companyForUser.id, category.id, name.value, description.value, names.value, accounts.value).then( (response) => {
        if (response.status === 200) {
          categoryResponse = response.data

          let tempCategoriesForCompany = categoriesForCompany.map((temp_item) => {
            if (temp_item.id === category.id) {
              temp_item = categoryResponse
            }
            return temp_item
          })

          setCategoriesForCompany(tempCategoriesForCompany)

          if (categories && setCategories) {
            let tempCategories = categories.map((temp_item) => {
              if (temp_item.id === category.id) {
                temp_item = categoryResponse
              }
              return temp_item
            })

            setCategories(tempCategories)
          } else {
            setCategory(categoryResponse)
          }

          Swal.fire({
            title: lang.labels.successfullyUpdatedCategory,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalCategoryUpdate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: `${errorResponse.message}!`,
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('modal-category-update-input-name', 'modal-category-update-container-validate-name-required')
        }
        if (description.value.length === 0) {
          legendInvalid('modal-category-update-container-validate-description-required')
        }
        if (accounts.value.expense.length === 0) {
          legendInvalid('modal-category-update-container-validate-account-expense-required')
        }
        if (accounts.value.vat.length === 0) {
          legendInvalid('modal-category-update-container-validate-account-vat-required')
        }
        if (accounts.value.product.length === 0) {
          legendInvalid('modal-category-update-container-validate-account-product-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const executeHideModalCategoryUpdate = () => {
    modalHide('modal-category-update')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-category-update-input-name', 'modal-category-update-container-validate-name-valid')
    legendValidInvalidIconRestart('modal-category-update-input-name', 'modal-category-update-container-validate-name-required')
    legendValidInvalidRestart('modal-category-update-container-validate-description-valid')
    legendValidInvalidRestart('modal-category-update-container-validate-description-required')
    legendValidInvalidRestart('modal-category-update-container-validate-account-expense-required')
    legendValidInvalidRestart('modal-category-update-container-validate-account-vat-required')
    legendValidInvalidRestart('modal-category-update-container-validate-account-product-required')

    for (var index in Object.keys(names.valid)) {
      legendValidInvalidIconRestart('modal-category-update-input-language-name-' + index, 'modal-category-update-container-validate-language-name-valid-' + index)
    }

    if (categories && setCategories) {
      setCategory(null)
    }
  }

  useEffect( () => {
    if(category) {
      setName({value: category.display_name, valid: true})
      setDescription({value: category.description, valid: true})
      setNames({value: {en: category.names.en, fr: category.names.fr, it: category.names.it, de: category.names.de}, valid: {en: true, fr: true, it: true, de: true}})
      setAccounts({value: {expense: category.accounts.expense, vat: category.accounts.vat, product: category.accounts.product}, valid: {expense: true, vat: true, product: true}})
    } else {
      setName({value: '', valid: false})
      setDescription({value: '', valid: false})
      setNames({value: {en: '', fr: '', it: '', de: ''}, valid: {en: true, fr: true, it: true, de: true}})
      setAccounts({value: {expense: '', vat: '', product: ''}, valid: {expense: false, vat: false, product: false}})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category])

  return (
    <div id="modal-category-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.updateCategory}</h2>
          </div>
          <div className="modal-body py-0">
            { companyForUser && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany && category
              ?
              <AppCategoryForm component={"modal-category-update"} category={category} name={name} description={description} names={names} accounts={accounts} setName={setName} setDescription={setDescription} setNames={setNames} setAccounts={setAccounts}></AppCategoryForm>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalCategoryUpdate}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitCategoryUpdate}>
              <span className="indicator-label">{lang.labels.update}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCategoryUpdate;
