import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Company } from '../models/company.model';
import { CompanyContacts } from '../models/company.contacts.model';
import { Contact } from '../models/contact.model';
import { Currency } from '../models/currency.model';
import { appToken } from '../scripts/app.token.script';
import { extractBase64 } from '../scripts/extract.base.64.script';

export class ContactDA {
  static apiResponse: ApiResponse

  public static async getContacts(id_company: string, contact_name: string, contact_country: string, contact_type: string, sort: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let contactsByCompany: CompanyContacts | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/contacts.data.json').then(response => { contactsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (contactsByCompany!.id_company === id_company) {
        let listContacts: Contact[] = []

        switch (true) {
          case contact_name.length === 0 && contact_country.length === 0 && contact_type.length === 0 && sort.length === 0:
            listContacts = contactsByCompany!.contacts
            break;
          case contact_name.length === 0:
            switch (true) {
              case contact_country.length !== 0 && contact_type.length === 0:
                listContacts = contactsByCompany!.contacts.filter((item: Contact) => item.data.address.country === contact_country)
                break;
              case contact_country.length === 0 && contact_type.length !== 0:
                listContacts = contactsByCompany!.contacts.filter((item: Contact) => item.type === contact_type)
                break;
              case contact_country.length !== 0 && contact_type.length !== 0:
                listContacts = contactsByCompany!.contacts.filter((item: Contact) => item.data.address.country === contact_country && item.type === contact_type)
                break;
              default:
                listContacts = contactsByCompany!.contacts
                break;
            }
            switch (sort) {
              case 'asc':
                listContacts.sort(function (a, b) {
                  let txtA: string = (a.type === 'person') ? `${a.data.first_name} ${a.data.last_name}` : `${a.data.name}`
                  let txtB: string = (b.type === 'person') ? `${b.data.first_name} ${b.data.last_name}` : `${b.data.name}`
                  if (txtA.toLocaleLowerCase() > txtB.toLocaleLowerCase()) { return 1 }
                  if (txtA.toLocaleLowerCase() < txtB.toLocaleLowerCase()) { return -1 }
                  return 0
                })
                break;
              case 'desc':
                listContacts.sort(function (a, b) {
                  let txtA: string = (a.type === 'person') ? `${a.data.first_name} ${a.data.last_name}` : `${a.data.name}`
                  let txtB: string = (b.type === 'person') ? `${b.data.first_name} ${b.data.last_name}` : `${b.data.name}`
                  if (txtA.toLocaleLowerCase() > txtB.toLocaleLowerCase()) { return -1 }
                  if (txtA.toLocaleLowerCase() < txtB.toLocaleLowerCase()) { return 1 }
                  return 0
                })
                break;
            }
            break;
          case contact_name.length !== 0:
            for (let contact of contactsByCompany!.contacts) {
              let name: string = (contact.type === 'company') ? contact.data.name.toLowerCase() : (contact.data.first_name + ' ' + contact.data.last_name).toLowerCase()
              if (name.indexOf(contact_name.toLocaleLowerCase()) !== -1) {
                listContacts.push(contact)
              }
            }
            break;
        }

        contactsByCompany!.filter.contact_name = contact_name
        contactsByCompany!.filter.contact_country = contact_country
        contactsByCompany!.filter.contact_type = contact_type
        contactsByCompany!.filter.sort = sort
        contactsByCompany!.contacts = listContacts

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: contactsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/contacts?name_contact=${contact_name}&country=${contact_country}&type=${contact_type}&order_direction=${sort}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getContact(id_company: string, id_contact: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let contactPerson: Contact | null = null
      let contactCompany: Contact | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/contact.person.data.json').then(response => { contactPerson = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/contact.company.data.json').then(response => { contactCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (contactPerson!.data.id === id_contact || contactCompany!.data.id === id_contact) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (id_contact === contactPerson!.data.id) ? contactPerson : contactCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/contacts/${id_contact}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postContact(id_company: string, type: string, uuid: string, name: string, first_name: string, last_name: string, logo: any, email: string, phone: string, website: string, vat_number: string, register_number: string, street: string, street_number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, currency: Currency, financial_entity: string, iban: string, bban: string, bic: string, language: string, qr_type: string, id_companies: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let contact: Contact | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        contact = {
          companies: [id_company, ...id_companies],
          type: type,
          data: {
            id: `contact${(Math.floor(Math.random() * 1000)).toString()}`,
            uuid: uuid,
            contact_code: (Math.floor(Math.random() * 90000000 + 10000000)).toString(),
            name: name,
            first_name: first_name,
            last_name: last_name,
            logo: (logo && logo.length > 0) ? logo : '',
            avatar: '',
            email: email,
            phone: phone,
            website: website,
            vat_number: vat_number,
            register_number: register_number,
            language: language,
            qr_type: qr_type,
            currency: currency,
            address: {
              street: street,
              number: street_number,
              additional: additional,
              administrative_area: administrative_area,
              city: city,
              zip_code: zip_code,
              country: country
            },
            bank_account: {
              financial_entity: financial_entity,
              iban: iban,
              bban: bban,
              bic: bic
            }
          }
        }

        if (logo && logo.length === undefined) {
          extractBase64(logo).then((image: any) => { contact!.data.logo = image.base })
        }

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: contact
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/contacts`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('uuid', uuid)
      data.append('type', type)
      data.append('phone', phone)
      data.append('email', email)
      data.append('website', website)
      data.append('street', street)
      data.append('number', street_number)
      data.append('additional', additional)
      data.append('administrative_area', administrative_area)
      data.append('city', city)
      data.append('zip_code', zip_code)
      data.append('country', country)
      data.append('language', language)
      data.append('qr_type', qr_type)
      data.append('company', id_company)
      data.append('account_name', financial_entity)
      data.append('bic', bic)
      data.append('bban', bban)
      data.append('iban', iban)

      if (type === 'person') {
        data.append('first_name', first_name)
        data.append('last_name', last_name)
      } else {
        data.append('logo', logo)
        data.append('name', name)
        data.append('vat_number', vat_number)
        data.append('register_number', register_number)
        data.append('currency_name', currency.name)
        data.append('currency_code', currency.code)
        data.append('currency_symbol', currency.symbol)
      }

      id_companies.forEach((item: string) => {
        data.append('other_companies', JSON.stringify(item))
      })

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putContact(id_company: string, id_contact: string, type: string, name: string, first_name: string, last_name: string, logo: any, email: string, phone: string, website: string, vat_number: string, register_number: string, street: string, street_number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, currency: Currency, financial_entity: string, iban: string, bban: string, bic: string, language: string, qr_type: string, id_companies: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let contact: Contact | null = null
      let error: Error | null = null

      await this.getContact(id_company, id_contact).then(response => { contact = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (contact!.data.id === id_contact) {
        if (name.length > 0) { contact!.data.name = name }
        if (first_name.length > 0) { contact!.data.first_name = first_name }
        if (last_name.length > 0) { contact!.data.last_name = last_name }
        if (logo) { if (logo.length > 0) { contact!.data.logo = logo } else { extractBase64(logo).then((image: any) => { contact!.data.logo = image.base }) } } else { contact!.data.logo = '' }
        if (email.length > 0) { contact!.data.email = email }
        if (phone.length > 0) { contact!.data.phone = phone }
        if (website.length > 0) { contact!.data.website = website }
        if (vat_number.length > 0) { contact!.data.vat_number = vat_number }
        if (register_number.length > 0) { contact!.data.register_number = register_number }
        if (currency) { contact!.data.currency = currency }
        if (street.length > 0) { contact!.data.address.street = street }
        if (street_number.length > 0) { contact!.data.address.number = street_number }
        if (additional.length > 0) { contact!.data.address.additional = additional }
        if (administrative_area.length > 0) { contact!.data.address.administrative_area = administrative_area }
        if (city.length > 0) { contact!.data.address.city = city }
        if (zip_code.length > 0) { contact!.data.address.zip_code = zip_code }
        if (country.length > 0) { contact!.data.address.country = country }
        if (financial_entity.length > 0) { contact!.data.bank_account.financial_entity = financial_entity }
        if (iban.length > 0) { contact!.data.bank_account.iban = iban }
        if (bban.length > 0) { contact!.data.bank_account.bban = bban }
        if (bic.length > 0) { contact!.data.bank_account.bic = bic }
        if (language.length > 0) { contact!.data.language = language }
        if (qr_type.length > 0) { contact!.data.qr_type = qr_type }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: contact
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/contacts/${id_contact}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append('type', type)
      data.append('phone', phone)
      data.append('email', email)
      data.append('website', website)
      data.append('street', street)
      data.append('number', street_number)
      data.append('additional', additional)
      data.append('administrative_area', administrative_area)
      data.append('city', city)
      data.append('zip_code', zip_code)
      data.append('country', country)
      data.append('language', language)
      data.append('qr_type', qr_type)
      data.append('account_name', financial_entity)
      data.append('bic', bic)
      data.append('bban', bban)
      data.append('iban', iban)
      id_companies.forEach((item: string) => {
        data.append('companies', JSON.stringify(item))
      })
      if (type === 'person') {
        data.append('first_name', first_name)
        data.append('last_name', last_name)
      } else {
        data.append('logo', logo)
        data.append('name', name)
        data.append('vat_number', vat_number)
        data.append('register_number', register_number)
        data.append('currency_name', currency.name)
        data.append('currency_code', currency.code)
        data.append('currency_symbol', currency.symbol)
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putContactExtend(id_company: string, id_contact: string, id_companies: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let contact: Contact | null = null
      let error: Error | null = null

      await this.getContact(id_company, id_contact).then(response => { contact = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (contact!.data.id === id_contact) {
        contact!.companies = [id_company, ...id_companies]

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: contact
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/contacts/${id_contact}/changes/companies`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data = {
        other_companies: id_companies
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deleteContacts(id_company: string, id_contacts: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let contacts: Contact[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/contacts.data.json').then(response => { contacts = response.data.contacts }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (contacts!.some((item: Contact) => id_contacts.includes(item.data.id))) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/contacts`

      let config: any = {
        headers: { Authorization: appToken() },
        data: { contact: id_contacts }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
