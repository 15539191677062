import { createContext, useReducer, ReactNode } from 'react';
import { Action, InitialState, ReducerLanguage } from './ReducerLanguage';
import { Language } from '../labels/language.labels';
import { Texts } from '../labels/language.texts';

const init = () => {
  const hasData = localStorage.getItem('language')

  if (hasData === null) {
    return InitialState
  }

  if (hasData === Texts.lang.spanish.code) {
    return {
      code: Texts.lang.spanish.code,
      labels: Texts.lang.spanish.labels
    }
  }

  if (hasData === Texts.lang.french.code) {
    return {
      code: Texts.lang.french.code,
      labels: Texts.lang.french.labels
    }
  }

  return {
    code: Texts.lang.english.code,
    labels: Texts.lang.english.labels
  }
}

type ContextDispatch = (action: Action) => void
type Context = { lang: Language }
type Props = { children: ReactNode }

export const CreatedContextDispatch = createContext<ContextDispatch>(() => { })

export const CreatedContextLanguage = createContext<Context>({ lang : InitialState })

export const ContextLanguage = ({ children } : Props) => {
  const [lang, dispatch] = useReducer(ReducerLanguage, InitialState, init)

  return (
    <CreatedContextDispatch.Provider value={dispatch}>
      <CreatedContextLanguage.Provider value={{lang}}>
        {children}
      </CreatedContextLanguage.Provider>
    </CreatedContextDispatch.Provider>
  )
}