import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContextInventory } from '../Inventory';
import { AppService } from '../../../../models/app.service.model';
import { Company } from '../../../../models/company.model';
import { Settings } from '../../../../models/settings.model';
import { Category } from '../../../../models/category.model';
import { AccountingAccount } from '../../../../models/accounting.account.model';

export interface ServicePageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  settingsForCompany: Settings | null | undefined,
  categoriesForCompany: Category[] | undefined | null,
  accountingExpenseAccountsForCompany: AccountingAccount[] | undefined | null,
  accountingVatAccountsForCompany: AccountingAccount[] | undefined | null,
  accountingProductAccountsForCompany: AccountingAccount[] | undefined | null,
  setSettingsForCompany: Dispatch<SetStateAction<Settings | undefined | null>>,
  setCategoriesForCompany: Dispatch<SetStateAction<Category[] | undefined | null>>,
  loadServicesForCompany: Function
};

const ServicePage: React.FunctionComponent<ServicePageProps> = props => {
  const {setRoute, appServiceForUser, companyForUser, settingsForCompany, categoriesForCompany, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, setSettingsForCompany, setCategoriesForCompany, loadServicesForCompany} = UseOutletContextInventory()

  return (
    <Outlet context={{setRoute, appServiceForUser, companyForUser, settingsForCompany, categoriesForCompany, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, setSettingsForCompany, setCategoriesForCompany, loadServicesForCompany}}></Outlet>
  );
}

export function UseOutletContextService() {
  return useOutletContext<ContexType>()
};

export default ServicePage;
