import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextTreasury } from './Treasury';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/translations/response';
import { TreasuryAccountDA } from '../../../services/treasury.account.service';
import { Error } from '../../../models/error.model';
import { TreasuryAccount } from '../../../models/treasury.account.model';
import { TreasuryAccountHolder } from '../../../models/treasury.account.holder.model';
import { legendInvalid, legendInvalidIcon } from '../../../tools/legend.data.entry.tool';

import AppTreasuryCreateForm from './card/TreasuryCreateForm';

export interface TreasuryCreatePageProps {};

let errorResponse: Error, treasuryAccountsResponse: TreasuryAccount[];

const TreasuryCreatePage: React.FunctionComponent<TreasuryCreatePageProps> = props => {
  const {setRoute, companyForUser, treasuryAccountsForCompany, accountingCashAccountsForCompany, accountingPostalAccountsForCompany, accountingBankAccountsForCompany, setTreasuryAccountsForCompany, setAccountingCashAccountsForCompany, setAccountingPostalAccountsForCompany, setAccountingBankAccountsForCompany} = UseOutletContextTreasury()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [typeAccount, setTypeAccount] = useState({value: '', valid: false})
  const [accountingAccount, setAccountingAccount] = useState({value: '', valid: false})
  const [nameAccount, setNameAccount] = useState({value: '', valid: false})
  const [iban, setIban] = useState({value: '', valid: true})
  const [bban, setBban] = useState({value: '', valid: true})
  const [bic, setBic] = useState({value: '', valid: true})
  const [membership, setMembership] = useState({value: '', valid: true})
  const [qrIban, setQrIban] = useState({value: '', valid: true})
  const [reference, setReference] = useState({value: '', valid: true})
  const [nameHolder, setNameHolder] = useState({value: '', valid: true})
  const [street, setStreet] = useState({value: '', valid: false})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: false})
  const [additional, setAdditional] = useState({value: '', valid: true})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [options, setOptions] = useState({is_default: false, is_reference: false, is_holder: false})

  const submitTreasuryAccountCreate = async () => {
    setLoadIndicator('on')

    if (companyForUser && treasuryAccountsForCompany && accountingCashAccountsForCompany && accountingPostalAccountsForCompany && accountingBankAccountsForCompany && typeAccount.valid && accountingAccount.valid && nameAccount.valid && iban.valid && bban.valid && bic.valid && membership.valid && qrIban.valid && reference.valid && reference.valid && nameHolder.valid && street.valid && streetNumber.valid && additional.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid) {
      let valueIban: string = iban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBban: string = bban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBic: string = bic.value.toUpperCase().trim().replaceAll(' ', '')
      let valueQrIban: string = qrIban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueDefault: boolean = (treasuryAccountsForCompany.length === 0) ? true : options.is_default
      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value
      let accountHolder: TreasuryAccountHolder = {full_name: nameHolder.value, address: {street: street.value, number: addressStreetNumber, additional: additional.value, administrative_area: administrativeArea.value, city: city.value, zip_code: zipCode.value, country: country.value}}

      await TreasuryAccountDA.postTreasuryAccount(companyForUser.id, typeAccount.value, accountingAccount.value, nameAccount.value, valueIban, valueBban, valueBic, membership.value, valueQrIban, reference.value, options.is_reference, valueDefault, accountHolder).then( (response) => {
        if (response.status === 201) {
          treasuryAccountsResponse = response.data

          setTreasuryAccountsForCompany(treasuryAccountsResponse)
          setAccountingCashAccountsForCompany(accountingCashAccountsForCompany.filter((item) => (item.id !== accountingAccount.value)))
          setAccountingPostalAccountsForCompany(accountingPostalAccountsForCompany.filter((item) => (item.id !== accountingAccount.value)))
          setAccountingBankAccountsForCompany(accountingBankAccountsForCompany.filter((item) => (item.id !== accountingAccount.value)))

          Swal.fire({
            title: lang.labels.successfullyCreatedTreasuryAccount,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/treasury/list', {replace: true})
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (typeAccount.value.length === 0) {
          legendInvalid('container-validate-type-account-required')
        }
        if (nameAccount.value.length === 0) {
          legendInvalid('container-validate-name-account-required')
        }
        if (nameHolder.value.length === 0) {
          legendInvalidIcon('input-name-holder', 'container-validate-name-holder-required')
        }
        if (street.value.length === 0) {
          legendInvalidIcon('input-street', 'container-validate-street-required')
        }
        if (streetNumber.value.length === 0) {
          legendInvalidIcon('input-street-number', 'container-validate-street-number-required')
        }
        if (administrativeArea.value.length === 0) {
          legendInvalidIcon('input-administrative-area', 'container-validate-administrative-area-required')
        }
        if (city.value.length === 0) {
          legendInvalidIcon('input-city', 'container-validate-city-required')
        }
        if (zipCode.value.length === 0) {
          legendInvalidIcon('input-zip-code', 'container-validate-zip-code-required')
        }
        if (country.value.length === 0) {
          legendInvalid('container-validate-country-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.treasury, branch: lang.labels.createTreasuryAccount}, company: true})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && treasuryAccountsForCompany && accountingCashAccountsForCompany && accountingPostalAccountsForCompany && accountingBankAccountsForCompany
        ?
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h2>{lang.labels.createANewTreasuryAccount}</h2>
            </div>
          </div>
          <div className="card-body">
            <AppTreasuryCreateForm component={"page"} treasuryAccount={null} typeAccount={typeAccount} accountingAccount={accountingAccount} nameAccount={nameAccount} iban={iban} bban={bban} bic={bic} membership={membership} qrIban={qrIban} reference={reference} nameHolder={nameHolder} street={street} streetNumber={streetNumber} additional={additional} administrativeArea={administrativeArea} city={city} zipCode={zipCode} country={country} options={options} setTypeAccount={setTypeAccount} setAccountingAccount={setAccountingAccount} setNameAccount={setNameAccount} setIban={setIban} setBban={setBban} setBic={setBic} setMembership={setMembership} setQrIban={setQrIban} setReference={setReference} setNameHolder={setNameHolder} setStreet={setStreet} setStreetNumber={setStreetNumber} setAdditional={setAdditional} setAdministrativeArea={setAdministrativeArea} setCity={setCity} setZipCode={setZipCode} setCountry={setCountry} setOptions={setOptions}></AppTreasuryCreateForm>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitTreasuryAccountCreate}>
              <span className="indicator-label">{lang.labels.createTreasuryAccount}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default TreasuryCreatePage;
