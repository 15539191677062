import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { ContactDA } from '../../services/contact.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Contact } from '../../models/contact.model';
import { Currency } from '../../models/currency.model';
import { legendValidIcon, legendValidInvalidRestart, legendValidInvalidRequiredRestart, legendValidInvalidIconRestart } from '../../tools/legend.data.entry.tool';
import { collapsible, collapsibleHide, collapsibleShow } from '../../tools/collapsible.tool';
import { modalHide, modalShow } from '../../tools/modal.tool';
import { removeTooltip, uploadTooltip } from '../../tools/tooltip.tool';
import { bicFormat, ibanFormat } from '../../scripts/format.value.script';
import { removeUploadedImage, uploadImage } from '../../scripts/upload.image.script';
import { evaluateLegendValidateEmpty, evaluateLegendValidateEmptyIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';
import { countryList, countryCode, countryName } from '../../libraries/countries.library'
import { currencyList, currencyValue } from '../../libraries/currency.library';
import { languageDownloadList, languageDownloadValue } from '../../libraries/language.download.library';
import { qrTemplateList, qrTemplateValue } from '../../libraries/qr.template.library';

import AppLegend from '../element/Legend';
import AppModalContactUpdateExtend from './ModalContactUpdateExtend';

import logoBlank from '../../assets/images/default.png';
import iconCard from '../../assets/images/icons/card-secret-icon.svg';
import iconBank from '../../assets/images/icons/bank-ico.svg';

export interface AppModalContactUpdateProps {
  companyForUser: Company | undefined | null,
  contact: Contact | undefined | null,
  contacts: Contact[] | undefined | null,
  companiesOfContact: Company[] | undefined | null,
  setContact: Dispatch<SetStateAction<Contact | undefined | null>>,
  setCompaniesOfContact: Dispatch<SetStateAction<Company[] | undefined | null>> | null,
  setContacts: Dispatch<SetStateAction<Contact[] | undefined | null>> | null,
  loadContactsForCompany: Function | null
};

let errorResponse: Error, contactResponse: Contact;

const AppModalContactUpdate: React.FunctionComponent<AppModalContactUpdateProps> = ({companyForUser, contact, contacts, companiesOfContact, setContact, setCompaniesOfContact, setContacts, loadContactsForCompany}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: true})
  const [firstName, setFirstName] = useState({value: '', valid: true})
  const [lastName, setLastName] = useState({value: '', valid: true})
  const [logo, setLogo] = useState({value: '', file: null as any, valid: true})
  const [email, setEmail] = useState({value: '', valid: true})
  const [phone, setPhone] = useState({value: '', valid: true})
  const [website, setWebsite] = useState({value: '', valid: true})
  const [vatNumber, setVatNumber] = useState({value: '', valid: true})
  const [registerNumber, setRegisterNumber] = useState({value: '', valid: true})
  const [street, setStreet] = useState({value: '', valid: true})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: true})
  const [additional, setAdditional] = useState({value: '', valid: true})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: true})
  const [city, setCity] = useState({value: '', valid: true})
  const [zipCode, setZipCode] = useState({value: '', valid: true})
  const [country, setCountry] = useState({value: '', valid: true})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: true})
  const [financialEntity, setFinancialEntity] = useState({value: '', valid: true})
  const [iban, setIban] = useState({value: '', valid: true})
  const [bban, setBban] = useState({value: '', valid: true})
  const [bic, setBic] = useState({value: '', valid: true})
  const [language, setLanguage] = useState({value: '', valid: true})
  const [qrType, setQrTtype] = useState({value: '', valid: true})
  const [values, setValues] = useState({name: '', first_name: '', last_name: '', logo: null, email: '', phone: '', website: '', vat_number: '', register_number: '', street: '', street_number: '', additional: '', administrative_area: '', city: '', zip_code: '', country: '', currency: {code: '', name: '', symbol: ''} , financial_entity: '', iban: '', bban: '', bic: '', language: '', qr_type: ''})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const submitContactUpdate = async () => {
    setLoadIndicator('on')

    if (companyForUser && contact && name.valid && firstName.valid && lastName.valid && logo.valid && email.valid && phone.valid && website.valid && vatNumber.valid && registerNumber.valid && street.valid && streetNumber.valid && additional.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid && currency.valid && financialEntity.valid && iban.valid && bban.valid && bic.valid && language.valid && qrType.valid) {
      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value
      let valueIban: string = iban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBban: string = bban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBic: string = bic.value.toUpperCase().trim().replaceAll(' ', '')

      await ContactDA.putContact(companyForUser.id, contact.data.id, contact.type, name.value, firstName.value, lastName.value, logo.file, email.value, phone.value, website.value, vatNumber.value, registerNumber.value, street.value, addressStreetNumber, additional.value, administrativeArea.value, city.value, zipCode.value, country.value, currency.object, financialEntity.value, valueIban, valueBban, valueBic, language.value, qrType.value, []).then( (response) => {
        if (response.status === 200) {
          contactResponse = response.data

          if (typeof loadContactsForCompany === 'function' && contacts && setContacts) {
            let tempContacts = contacts.map((temp_item) => {
              if (temp_item.data.id === contact.data.id) {
                temp_item = contactResponse
              }
              return temp_item
            })

            setContacts(tempContacts)
            loadContactsForCompany(companyForUser.id)
          } else {
            setContact(contactResponse)
          }

          Swal.fire({
            title: lang.labels.successfullyUpdatedContact,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalContactUpdate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangeLogo = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    uploadImage(event.target.files[0], logo, setLogo, 'modal-contact-update-container-validate-logo-type', 'modal-contact-update-container-validate-logo-size')
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeVatNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVatNumber({...vatNumber, value: event.target.value})
  }

  const handleChangeRegisterNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setRegisterNumber({...registerNumber, value: event.target.value})
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreetNumber({...streetNumber, value: event.target.value})
  }

  const handleChangeAdditional = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdditional({...additional, value: event.target.value})
  }

  const handleChangeAdministrativeArea = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAdministrativeArea({...administrativeArea, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeCountry = (item: string) => {
    setCountry({...country, value: item, valid: true})
  }

  const handleChangeCurrency = (item: Currency) => {
    setCurrency({...currency, value: item.code, object: item, valid: true})
  }

  const handleChangeAccountName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFinancialEntity({...financialEntity, value: event.target.value})
  }

  const handleChangeIban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setIban({...iban, value: event.target.value})
  }

  const handleChangeBban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setBban({...bban, value: event.target.value})
  }

  const handleChangeBic = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setBic({...bic, value: event.target.value})
  }

  const handleChangeLanguage = (item: string) => {
    setLanguage({...language, value: item, valid: true})
  }

  const handleChangeQrType = (item: string) => {
    setQrTtype({...qrType, value: item, valid: true})
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement|HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of countryList(lang.code)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = countryList(lang.code)
    }

    setSearchCountry({...searchCountry, text: text, list: list})
  }

  const handleRemoveLogo = () => {
    removeUploadedImage(logo, setLogo, 'modal-contact-update-container-validate-logo-type', 'modal-contact-update-container-validate-logo-size')
    removeTooltip()
  }

  const validateName = () => {
    evaluateLegendValidateEmptyIcon(expressions.name, name, setName, 'modal-contact-update-input-name', 'modal-contact-update-container-validate-name-valid')
  }

  const validateFirstName = () => {
    evaluateLegendValidateEmptyIcon(expressions.name, firstName, setFirstName, 'modal-contact-update-input-first-name', 'modal-contact-update-container-validate-first-name-valid')
  }

  const validateLastName = () => {
    evaluateLegendValidateEmptyIcon(expressions.name, lastName, setLastName, 'modal-contact-update-input-last-name', 'modal-contact-update-container-validate-last-name-valid')
  }

  const validateEmail = () => {
    evaluateLegendValidateEmptyIcon(expressions.email, email, setEmail, 'modal-contact-update-input-email', 'modal-contact-update-container-validate-email-valid')
  }

  const validatePhone = () => {
    evaluateLegendValidateEmptyIcon(expressions.phone, phone, setPhone, 'modal-contact-update-input-phone', 'modal-contact-update-container-validate-phone-valid')
  }

  const validateWebsite = () => {
    evaluateLegendValidateEmptyIcon(expressions.website, website, setWebsite, 'modal-contact-update-input-website', 'modal-contact-update-container-validate-website-valid')
  }

  const validateVatNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.vat, vatNumber, setVatNumber, 'modal-contact-update-input-vat-number', 'modal-contact-update-container-validate-vat-number-valid')
  }

  const validateRegisterNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.registernumber, registerNumber, setRegisterNumber, 'modal-contact-update-input-register-number', 'modal-contact-update-container-validate-register-number-valid')
  }

  const validateStreet = () => {
    evaluateLegendValidateEmptyIcon(expressions.street, street, setStreet, 'modal-contact-update-input-street', 'modal-contact-update-container-validate-street-valid')
  }

  const validateNumber = () => {
    evaluateLegendValidateEmptyIcon(expressions.streetnumber, streetNumber, setStreetNumber, 'modal-contact-update-input-street-number-', 'modal-contact-update-container-validate-street-number-valid')
  }

  const validateAdditional = () => {
    evaluateLegendValidateEmptyIcon(expressions.text, additional, setAdditional, 'modal-contact-update-input-additional', 'modal-contact-update-container-validate-additional-valid')
  }

  const validateAdministrativeArea = () => {
    evaluateLegendValidateEmptyIcon(expressions.location, administrativeArea, setAdministrativeArea, 'modal-contact-update-input-administrative-area', 'modal-contact-update-container-validate-administrative-area-valid')
  }

  const validateCity = () => {
    evaluateLegendValidateEmptyIcon(expressions.location, city, setCity, 'modal-contact-update-input-city', 'modal-contact-update-container-validate-city-valid')
  }

  const validateZipCode = () => {
    evaluateLegendValidateEmptyIcon(expressions.zipcode, zipCode, setZipCode, 'modal-contact-update-input-zip-code', 'modal-contact-update-container-validate-zip-code-valid')
  }

  const validateFinancialEntity = () => {
    evaluateLegendValidateEmpty(expressions.name, financialEntity, setFinancialEntity, 'modal-contact-update-container-validate-financial-entity-valid')
  }

  const validateIban = () => {
    evaluateLegendValidateEmpty(expressions.iban, iban, setIban, 'modal-contact-update-container-validate-iban-valid')
  }

  const validateBban = () => {
    evaluateLegendValidateEmpty(expressions.bban, bban, setBban, 'modal-contact-update-container-validate-bban-valid')
  }

  const validateBic = () => {
    evaluateLegendValidateEmpty(expressions.bic, bic, setBic, 'modal-contact-update-container-validate-bic-valid')
  }

  function executeCollapsible(item: string) {
    switch (item) {
      case 'identity':
        collapsible('modal-contact-update-button-collapsible-identity', 'modal-contact-update-container-collapsible-identity')
        collapsibleHide('modal-contact-update-button-collapsible-information', 'modal-contact-update-container-collapsible-information')
        collapsibleHide('modal-contact-update-button-collapsible-legal', 'modal-contact-update-container-collapsible-legal')
        collapsibleHide('modal-contact-update-button-collapsible-download', 'modal-contact-update-container-collapsible-download')
        break;
      case 'information':
        collapsible('modal-contact-update-button-collapsible-information', 'modal-contact-update-container-collapsible-information')
        collapsibleHide('modal-contact-update-button-collapsible-identity', 'modal-contact-update-container-collapsible-identity')
        collapsibleHide('modal-contact-update-button-collapsible-legal', 'modal-contact-update-container-collapsible-legal')
        collapsibleHide('modal-contact-update-button-collapsible-download', 'modal-contact-update-container-collapsible-download')
        break;
      case 'legal':
        collapsible('modal-contact-update-button-collapsible-legal', 'modal-contact-update-container-collapsible-legal')
        collapsibleHide('modal-contact-update-button-collapsible-identity', 'modal-contact-update-container-collapsible-identity')
        collapsibleHide('modal-contact-update-button-collapsible-information', 'modal-contact-update-container-collapsible-information')
        collapsibleHide('modal-contact-update-button-collapsible-download', 'modal-contact-update-container-collapsible-download')
        break;
      case 'download':
        collapsible('modal-contact-update-button-collapsible-download', 'modal-contact-update-container-collapsible-download')
        collapsibleHide('modal-contact-update-button-collapsible-legal', 'modal-contact-update-container-collapsible-legal')
        collapsibleHide('modal-contact-update-button-collapsible-identity', 'modal-contact-update-container-collapsible-identity')
        collapsibleHide('modal-contact-update-button-collapsible-information', 'modal-contact-update-container-collapsible-information')
        break;
      default:
        collapsibleShow('modal-contact-update-button-collapsible-identity', 'modal-contact-update-container-collapsible-identity')
        collapsibleHide('modal-contact-update-button-collapsible-information', 'modal-contact-update-container-collapsible-information')
        collapsibleHide('modal-contact-update-button-collapsible-legal', 'modal-contact-update-container-collapsible-legal')
        collapsibleHide('modal-contact-update-button-collapsible-download', 'modal-contact-update-container-collapsible-download')
        break;
    }
  }

  const executeShowModalContactUpdateExtend = () => {
    if (companyForUser && contact && name.valid && firstName.valid && lastName.valid && logo.valid && email.valid && phone.valid && website.valid && vatNumber.valid && registerNumber.valid && street.valid && streetNumber.valid && additional.valid && administrativeArea.valid && city.valid && zipCode.valid && country.valid && currency.valid && financialEntity.valid && iban.valid && bban.valid && bic.valid && language.valid && qrType.valid) {
      setValues({name: name.value, first_name: firstName.value, last_name: lastName.value, logo: logo.file, email: email.value, phone: phone.value, website: website.value, vat_number: vatNumber.value, register_number: registerNumber.value, street: street.value, street_number: streetNumber.value, additional: additional.value, administrative_area: administrativeArea.value, city: city.value, zip_code: zipCode.value, country: country.value, currency: currency.object, financial_entity: financialEntity.value, iban: iban.value, bban: bban.value, bic: bic.value, language: language.value, qr_type: qrType.value})

      modalHide('modal-contact-update')
      modalShow('modal-contact-update-extend')
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        buttonsStyling: !1,
        confirmButtonText: lang.labels.okGotIt,
        customClass: {confirmButton: 'btn btn-primary'}
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const executeHideModalContactUpdate = () => {
    modalHide('modal-contact-update')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function logoCompany(): boolean {
    switch (true) {
      case (contact && contact.type === 'company' && contact.data.logo.length > 0 && logo.valid && logo.value === ''):
        setLogo({value: contact!!.data.logo, file: contact!!.data.logo, valid: true})
        return false
      case (logo.valid && logo.value === '' && logo.file === null):
        return false
      case (logo.valid && logo.value === logoBlank):
        return false
      case (!logo.valid && logo.value === ''):
        return false
      default:
        return true
    }
  }

  function countryContact(): boolean {
    switch (true) {
      case (contact && country.valid && country.value === ''):
        setCountry({...country, value: contact!!.data.address.country, valid: true})
        return true
      case (country.valid && country.value.length > 0):
        return true
      default:
        return false
    }
  }

  function currencyContact(): boolean {
    switch (true) {
      case (contact && contact.type === 'company' && currency.valid && currency.value === ''):
        setCurrency({...currency, value: contact!!.data.currency.code, object: contact!!.data.currency, valid: true})
        return true
      case (currency.valid && currency.value.length > 0):
        return true
      default:
        return false
    }
  }

  function languageContact(): boolean {
    switch (true) {
      case (contact && language.valid && language.value === ''):
        setLanguage({value: contact!!.data.language, valid: true})
        return true
      case (language.valid && language.value.length > 0):
        return true
      default:
        return false
    }
  }

  function qrTypeContact(): boolean {
    switch (true) {
      case (contact && qrType.valid && qrType.value === ''):
        setQrTtype({value: contact!!.data.qr_type, valid: true})
        return true
      case (qrType.valid && qrType.value.length > 0):
        return true
      default:
        return false
    }
  }

  function uploadDropdown() {
    setSearchCountry({text: '', list: countryList(lang.code)})
  }

  function restartModal() {
    executeCollapsible('start')
    legendValidInvalidIconRestart('modal-contact-update-input-name', 'modal-contact-update-container-validate-name-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-first-name', 'modal-contact-update-container-validate-first-name-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-last-name', 'modal-contact-update-container-validate-last-name-valid')
    legendValidInvalidRequiredRestart('modal-contact-update-container-validate-logo-type', 'modal-contact-update-container-validate-logo-size')
    legendValidInvalidIconRestart('modal-contact-update-input-email', 'modal-contact-update-container-validate-email-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-phone', 'modal-contact-update-container-validate-phone-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-website', 'modal-contact-update-container-validate-website-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-vat-number', 'modal-contact-update-container-validate-vat-number-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-register-number', 'modal-contact-update-container-validate-register-number-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-street', 'modal-contact-update-container-validate-street-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-street-number-', 'modal-contact-update-container-validate-street-number-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-additional', 'modal-contact-update-container-validate-additional-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-administrative-area', 'modal-contact-update-container-validate-administrative-area-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-city', 'modal-contact-update-container-validate-city-valid')
    legendValidInvalidIconRestart('modal-contact-update-input-zip-code', 'modal-contact-update-container-validate-zip-code-valid')
    legendValidInvalidRestart('modal-contact-update-container-validate-financial-entity-valid')
    legendValidInvalidRestart('modal-contact-update-container-validate-iban-valid')
    legendValidInvalidRestart('modal-contact-update-container-validate-bban-valid')
    legendValidInvalidRestart('modal-contact-update-container-validate-bic-valid')

    if (contact && contact.data.uuid.length > 0) {
      if (contact.type === 'person') {
        legendValidIcon('modal-contact-update-input-first-name', 'modal-contact-update-container-validate-first-name-valid')
        legendValidIcon('modal-contact-update-input-last-name', 'modal-contact-update-container-validate-last-name-valid')
        legendValidIcon('modal-contact-update-input-email', 'modal-contact-update-container-validate-email-valid')
      }
      if (contact.type === 'company') {
        legendValidIcon('modal-contact-update-input-name', 'modal-contact-update-container-validate-name-valid')
      }
    }

    if (typeof loadContactsForCompany === 'function' && contacts && setContacts && setCompaniesOfContact) {
      setContact(null)
      setCompaniesOfContact(null)
    }

    setName({value: '', valid: true})
    setFirstName({value: '', valid: true})
    setLastName({value: '', valid: true})
    setLogo({value: '', file: null, valid: true})
    setEmail({value: '', valid: true})
    setPhone({value: '', valid: true})
    setWebsite({value: '', valid: true})
    setVatNumber({value: '', valid: true})
    setRegisterNumber({value: '', valid: true})
    setStreet({value: '', valid: true})
    setStreetNumber({value: '', valid: true})
    setAdditional({value: '', valid: true})
    setAdministrativeArea({value: '', valid: true})
    setCity({value: '', valid: true})
    setZipCode({value: '', valid: true})
    setCountry({value: '', valid: true})
    setCurrency({value: '', object: null, valid: true})
    setFinancialEntity({value: '', valid: true})
    setIban({value: '', valid: true})
    setBban({value: '', valid: true})
    setBic({value: '', valid: true})
    setLanguage({value: '', valid: true})
    setQrTtype({value: '', valid: true})
  }

  return (
    <>
      <div id="modal-contact-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog mw-700px">
          <div className="modal-content" onLoad={() => {executeCollapsible("start")}}>
            <div className="modal-header">
              <h2 className="text-capitalize">{lang.labels.updateContactData}</h2>
            </div>
            <div className="modal-body">
              { contact
                ?
                <div className="form" onLoad={uploadTooltip}>
                  <div id="modal-contact-update-container" className="scroll-y d-flex flex-column pe-2">
                    <div id="modal-contact-update-button-collapsible-identity" className="fw-boldest fs-5 rotate collapsible mb-5" data-bs-toggle="collapse" role="button" onClick={() => executeCollapsible("identity")}>
                      {lang.labels.contactIdentity}
                      <span className="ms-2 rotate-180">
                        <span className="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div id="modal-contact-update-container-collapsible-identity" className="collapse mb-3 show">
                      { contact.type === "person" &&
                        <>
                          <div className="fv-row mb-3">
                            <label className="form-label">{lang.labels.firstName}</label>
                            <input id="modal-contact-update-input-first-name" className={`form-control form-control-solid ${contact.type === "person" && contact.data.uuid.length > 0 && "is-valid"}`} type="text" name="first-name" disabled={contact.type === "person" && contact.data.uuid.length > 0} placeholder={contact.data.first_name} value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
                            <AppLegend component={"modal-contact-update"} attribute={{validity: firstName.valid, name: "first-name", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                          <div className="fv-row mb-3">
                            <label className="form-label">{lang.labels.lastName}</label>
                            <input id="modal-contact-update-input-last-name" className={`form-control form-control-solid ${contact.type === "person" && contact.data.uuid.length > 0 && "is-valid"}`} type="text" name="last-name" disabled={contact.type === "person" && contact.data.uuid.length > 0} placeholder={contact.data.last_name} value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
                            <AppLegend component={"modal-contact-update"} attribute={{validity: lastName.valid, name: "last-name", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                        </>
                      }
                      { contact.type === "company" &&
                        <>
                          <div className="fv-row mb-3">
                            <label className="form-label">{lang.labels.companyName}</label>
                            <input id="modal-contact-update-input-name" className={`form-control form-control-solid ${contact.type === "company" && contact.data.uuid.length > 0 && "is-valid"}`} type="text" name="name" disabled={contact.type === "company" && contact.data.uuid.length > 0} placeholder={contact.data.name} value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
                            <AppLegend component={"modal-contact-update"} attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                          <div className="fv-row mb-7">
                            <label className="form-label">
                              <div className="d-flex align-items-center">
                                {lang.labels.companyLogo}
                                <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title= {lang.labels.theFileMustBeFormat}><FaExclamationCircle /></i>
                              </div>
                            </label>
                            <div className="my-5 ">
                              <div className="image-input image-input-outline" data-kt-image-input="true">
                                <img src={logoCompany() ? logo.value : logoBlank} alt={lang.labels.logo} className="image-input-wrapper w-auto h-125px" />
                                <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.changeLogo} onClick={removeTooltip}>
                                  <i className="bi bi-pencil-fill fs-7"></i>
                                  <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={handleChangeLogo} />
                                </label>
                                  <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow ${!logo.valid && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveLogo}>
                                    <i className="bi bi-x fs-2" ></i>
                                  </span>
                              </div>
                            </div>
                            <AppLegend component={"modal-contact-update"} attribute={{validity: logo.valid, name: "logo", index: null, sub_index: null}} container={{valid: false, required: false, size: true, type: true, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                        </>
                      }
                      <div className="fv-row mb-3">
                        <label className="form-label">{lang.labels.email}</label>
                        <input id="modal-contact-update-input-email" className={`form-control form-control-solid ${contact.type === "person" && contact.data.uuid.length > 0  && "is-valid"}`} type="text" name="email" disabled={contact.type === "person" && contact.data.uuid.length > 0} placeholder={contact.data.email} value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                        <AppLegend component={"modal-contact-update"} attribute={{validity: email.valid, name: "email", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">{lang.labels.phoneNumber}</label>
                        <input id="modal-contact-update-input-phone" className="form-control form-control-solid" type="text" name="phone" placeholder={contact.data.phone} value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                        <AppLegend component={"modal-contact-update"} attribute={{validity: phone.valid, name: "phone", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">{lang.labels.webSite}</label>
                        <input id="modal-contact-update-input-website" className="form-control form-control-solid" type="text" name="website" placeholder={contact.data.website} value={website.value} onChange={handleChangeWebsite} onKeyUp={validateWebsite} onBlur={validateWebsite} />
                        <AppLegend component={"modal-contact-update"} attribute={{validity: website.valid, name: "website", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      { contact.type === "company" &&
                        <>
                          <div className="fv-row mb-3">
                            <label className="form-label">
                              <div className="d-flex align-items-center">
                                {lang.labels.vatNumber}
                                <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.identificationNumberForACompany}><FaExclamationCircle /></i>
                              </div>
                            </label>
                            <input id="modal-contact-update-input-vat-number" className="form-control form-control-solid"  type="text" name="vat-number" placeholder={contact.data.vat_number} value={vatNumber.value} onChange={handleChangeVatNumber} onKeyUp={validateVatNumber} onBlur={validateVatNumber} />
                            <AppLegend component={"modal-contact-update"} attribute={{validity: vatNumber.valid, name: "vat-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                          <div className="fv-row mb-3 d-none">
                            <label className="form-label">{lang.labels.registerNumber}</label>
                            <input id="modal-contact-update-input-register-number" className="form-control form-control-solid" type="text" name="register-number" placeholder={contact.data.register_number} value={registerNumber.value} onChange={handleChangeRegisterNumber} onKeyUp={validateRegisterNumber} onBlur={validateRegisterNumber} />
                            <AppLegend component={"modal-contact-update"} attribute={{validity: registerNumber.valid, name: "register-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                          </div>
                        </>
                      }
                    </div>
                    <div id="modal-contact-update-button-collapsible-information" className="fw-boldest fs-5 rotate collapsible mb-5" data-bs-toggle="collapse" role="button" onClick={() => executeCollapsible("information")}>
                      {lang.labels.contactInformation}
                      <span className="ms-2 rotate-180">
                        <span className="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div id="modal-contact-update-container-collapsible-information" className="collapse mb-3">
                      <div className="row mx-0">
                        <div className="col-md-8 fv-row mb-3 px-0 pe-md-2">
                          <label className="form-label">{lang.labels.street}</label>
                          <input id="modal-contact-update-input-street" className="form-control form-control-solid" type="text" name="street" placeholder={contact.data.address.street} value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                          <AppLegend component={"modal-contact-update"} attribute={{validity: street.valid, name: "street", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                        <div className="col-md-4 fv-row mb-3 px-2 ps-md-0">
                          <label className="form-label">
                            <div className="d-flex align-items-center">
                              {lang.labels.number}
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifAddressDoesNotHaveANumber}><FaExclamationCircle /></i>
                            </div>
                          </label>
                          <input id="modal-contact-update-input-street-number-" className="form-control form-control-solid" type="text" name="number" placeholder={contact.data.address.number} value={streetNumber.value} onChange={handleChangeNumber} onKeyUp={validateNumber} onBlur={validateNumber} />
                          <AppLegend component={"modal-contact-update"} attribute={{validity: streetNumber.valid, name: "street-number", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">
                          <div className="d-flex align-items-center">
                            {lang.labels.additional}
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.ifNecessaryEnterSomethingReferentialRegardingAddress}><FaExclamationCircle /></i>
                          </div>
                        </label>
                        <input id="modal-contact-update-input-additional" className="form-control form-control-solid" type="text" name="additional" placeholder={contact.data.address.additional} value={additional.value} onChange={handleChangeAdditional} onKeyUp={validateAdditional} onBlur={validateAdditional} />
                        <AppLegend component={"modal-contact-update"} attribute={{validity: additional.valid, name: "additional", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">{lang.labels.administrativeArea}</label>
                        <input id="modal-contact-update-input-administrative-area" className="form-control form-control-solid" type="text" name="administrative-area" placeholder={contact.data.address.administrative_area} value={administrativeArea.value} onChange={handleChangeAdministrativeArea} onKeyUp={validateAdministrativeArea} onBlur={validateAdministrativeArea} />
                        <AppLegend component={"modal-contact-update"} attribute={{validity: administrativeArea.valid, name: "administrative-area", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="row mx-0">
                        <div className="col-md-4 fv-row mb-3 px-0 pe-md-2">
                          <label className="form-label">
                            <div className="d-flex align-items-center">
                              {lang.labels.zipCode}
                              <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.enterThePostalCodeOfTheCity}><FaExclamationCircle /></i>
                            </div>
                          </label>
                          <input id="modal-contact-update-input-zip-code" className="form-control form-control-solid" type="text" name="zip-code" placeholder={contact.data.address.zip_code} value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                          <AppLegend component={"modal-contact-update"} attribute={{validity: zipCode.valid, name: "zip-code", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                        <div className="col-md-8 fv-row mb-3 px-0 ps-md-2">
                          <label className="form-label">{lang.labels.city}</label>
                          <input id="modal-contact-update-input-city" className="form-control form-control-solid" name="city" placeholder={contact.data.address.city} value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                          <AppLegend component={"modal-contact-update"} attribute={{validity: city.valid, name: "city", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </div>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">{lang.labels.country}</label>
                        <Dropdown onToggle={uploadDropdown}>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {countryContact() ? countryName(country.value, lang.code) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-search select2-search--dropdown">
                                <input className="select2-search__field" type="text" name="country" autoComplete="off" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                              </span>
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { searchCountry.list.length > 0
                                    ?
                                    <>
                                      { searchCountry.list.map (( (item, index) => { return (
                                        <li key={index} className={`select2-results__option select2-results__option--selectable ${countryCode(item, lang.code) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={countryCode(item, lang.code) === country.value}>
                                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(countryCode(item, lang.code))}>{item}</Dropdown.Item>
                                        </li>
                                      )}))}
                                    </>
                                    :
                                    <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultFound}</li>
                                  }
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div id="modal-contact-update-button-collapsible-legal" className="fw-boldest fs-5 rotate collapsible mb-5" data-bs-toggle="collapse" role="button" onClick={() => executeCollapsible("legal")}>
                      {lang.labels.legalInformation}
                      <span className="ms-2 rotate-180">
                        <span className="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div id="modal-contact-update-container-collapsible-legal" className="collapse mb-3">
                      { contact.type === "company" &&
                        <div className="fv-row mb-3">
                          <label className="form-label">{lang.labels.currency}</label>
                          <Dropdown>
                            <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                              <span className="selection">
                                <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                  <span className="select2-selection__rendered" role="textbox">
                                    <span className="select2-selection__placeholder">
                                      {currencyContact() ? currencyValue(currency.value) : lang.labels.selectOption}
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                              <span className="select2-dropdown select2-dropdown--below">
                                <span className="select2-results">
                                  <ul className="select2-results__options" role="listbox">
                                    { currencyList().map (( (item, index) => { return (
                                      <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === currency.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === currency.value}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCurrency(item)}>{item.code}: {item.name} ({item.symbol})</Dropdown.Item>
                                      </li>
                                    )}))}
                                  </ul>
                                </span>
                              </span>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      }
                      <div className="fv-row mb-3">
                        <label className="form-label">{lang.labels.financialEntity}</label>
                        <div className="position-relative">
                          <input className="form-control form-control-solid pe-13" type="text" name="account-name" placeholder={contact.data.bank_account.financial_entity} value={financialEntity.value} onChange={handleChangeAccountName} onKeyUp={validateFinancialEntity} onBlur={validateFinancialEntity} />
                          <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                            <img src={iconBank} alt={lang.labels.bank} className="h-20px" />
                          </div>
                        </div>
                        <AppLegend component={"modal-contact-update"} attribute={{validity: financialEntity.valid, name: "financial-entity", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">
                          <div className="d-flex align-items-center">
                            {lang.labels.iban}
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.internationalBankAccountNumber}><FaExclamationCircle /></i>
                          </div>
                        </label>
                        <div className="position-relative">
                          <input id="modal-contact-update-input-iban" className="form-control form-control-solid pe-13" type="text" name="iban" placeholder={ibanFormat(contact.data.bank_account.iban)} value={iban.value} onChange={handleChangeIban} onKeyUp={validateIban} onBlur={validateIban} />
                          <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                            <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                          </div>  
                        </div>
                        <AppLegend component={"modal-contact-update"} attribute={{validity: iban.valid, name: "iban", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">
                          <div className="d-flex align-items-center">
                            {lang.labels.bban}
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.basicBankAccountNumber}><FaExclamationCircle /></i>
                          </div>
                        </label>
                        <div className="position-relative">
                          <input id="modal-contact-update-input-bban" className="form-control form-control-solid pe-13" type="text" name="bban" placeholder={contact.data.bank_account.bban} value={bban.value} onChange={handleChangeBban} onKeyUp={validateBban} onBlur={validateBban} />
                          <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                            <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                          </div>
                        </div>
                        <AppLegend component={"modal-contact-update"} attribute={{validity: bban.valid, name: "bban", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">
                          <div className="d-flex align-items-center">
                            {lang.labels.bicSwift}
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.bankIdentifierCode}><FaExclamationCircle /></i>
                          </div>
                        </label>
                        <div className="position-relative">
                          <input id="modal-contact-update-input-bic" className="form-control form-control-solid pe-13" type="text" name="bic" placeholder={bicFormat(contact.data.bank_account.bic)} value={bic.value} onChange={handleChangeBic} onKeyUp={validateBic} onBlur={validateBic} />
                          <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                            <img src={iconCard} alt={lang.labels.card} className="h-20px" />
                          </div>
                        </div>
                        <AppLegend component={"modal-contact-update"} attribute={{validity: bic.valid, name: "bic", index: null, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                      </div>
                    </div>
                    <div id="modal-contact-update-button-collapsible-download" className="fw-boldest fs-5 rotate collapsible mb-5" data-bs-toggle="collapse" role="button" onClick={() => executeCollapsible("download")}>
                      {lang.labels.fileDownloadSettings}
                      <span className="ms-2 rotate-180">
                        <span className="svg-icon svg-icon-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div id="modal-contact-update-container-collapsible-download" className="collapse mb-3">
                      <div className="fv-row mb-3">
                        <label className="form-label">
                          <div className="d-flex align-items-center">
                            {lang.labels.language}
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.languageToDownload}><FaExclamationCircle /></i>
                          </div>
                        </label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {languageContact() ? languageDownloadValue(lang, language.value) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { languageDownloadList(lang).map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === language.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === language.value}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeLanguage(item.code)}>{item.description}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="fv-row mb-3">
                        <label className="form-label">
                          <div className="d-flex align-items-center">
                            <span>{lang.labels.qrType}</span>
                            <i className="ms-2 fs-8" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.shapesOrVersionsOfQR}><FaExclamationCircle /></i>
                          </div>
                        </label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {qrTypeContact() ? qrTemplateValue(lang, qrType.value) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { qrTemplateList(lang).map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === qrType.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === qrType.value}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeQrType(item.code)}>{item.description}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              }
            </div>
            <div className="modal-footer flex-center">
              <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalContactUpdate}>{lang.labels.discard}</button>
              { companiesOfContact && companiesOfContact.length === 1
                ?
                <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitContactUpdate}>
                  <span className="indicator-label">{lang.labels.update}</span>
                  <span className="indicator-progress">
                    {lang.labels.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                :
                <button className="btn btn-primary mx-2" type="button" onClick={executeShowModalContactUpdateExtend}>{lang.labels.extendChanges}</button>
              }
            </div>
          </div>
        </div>
      </div>
      <AppModalContactUpdateExtend companyForUser={companyForUser} contact={contact} contacts={contacts} companiesOfContact={companiesOfContact} values={values} setContact={setContact} setContacts={setContacts} loadContactsForCompany={loadContactsForCompany} restartModalMain={restartModal}></AppModalContactUpdateExtend>
    </>
  )
};

export default AppModalContactUpdate;
