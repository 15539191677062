import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';
import { TreasuryAccount } from '../../../models/treasury.account.model';
import { Company } from '../../../models/company.model';
import { AccountingAccount } from '../../../models/accounting.account.model';
import { AccountingAccountDA } from '../../../services/accounting.account.service';
import { Error } from '../../../models/error.model';
import { CompanyAccountingTreasuryAccounts } from '../../../models/company.accounting.treasury.accounts.model';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { getError } from '../../../languages/translations/response';
import { StateLanguage } from '../../../languages/config/StateLanguage';

export interface TreasuryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  companyForUser: Company | undefined | null,
  accountingAccountsForCompany: AccountingAccount[] | undefined | null,
  treasuryAccountsForCompany: TreasuryAccount[] | undefined | null,
  accountingCashAccountsForCompany: AccountingAccount[] | undefined | null,
  accountingPostalAccountsForCompany: AccountingAccount[] | undefined | null,
  accountingBankAccountsForCompany: AccountingAccount[] | undefined | null,
  setAccountingAccountsForCompany: Dispatch<SetStateAction<AccountingAccount[] | undefined | null>>,
  setTreasuryAccountsForCompany: Dispatch<SetStateAction<TreasuryAccount[] | undefined | null>>,
  setAccountingCashAccountsForCompany: Dispatch<SetStateAction<AccountingAccount[] | undefined | null>>,
  setAccountingPostalAccountsForCompany: Dispatch<SetStateAction<AccountingAccount[] | undefined | null>>,
  setAccountingBankAccountsForCompany: Dispatch<SetStateAction<AccountingAccount[] | undefined | null>>,
  loadAccountingTreasuryAccountsForCompany: Function
};

let errorResponse: Error, accounitngTreasuryAccuntsResponse: CompanyAccountingTreasuryAccounts;

const TreasuryPage: React.FunctionComponent<TreasuryPageProps> = props => {
  const {setRoute, companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, setAccountingAccountsForCompany, setTreasuryAccountsForCompany} = UseOutletContext()
  const {lang} = StateLanguage()

  const [accountingCashAccountsForCompany, setAccountingCashAccountsForCompany] = useState<AccountingAccount[] | undefined | null>(null)
  const [accountingPostalAccountsForCompany, setAccountingPostalAccountsForCompany] = useState<AccountingAccount[] | undefined | null>(null)
  const [accountingBankAccountsForCompany, setAccountingBankAccountsForCompany] = useState<AccountingAccount[] | undefined | null>(null)

  const loadAccountingTreasuryAccountsForCompany = async (id_company: string) => {
    await AccountingAccountDA.getAccountingTreasuryAccounts(id_company).then( (response) => {
      if (response.status === 200) {
        accounitngTreasuryAccuntsResponse = response.data
        setAccountingCashAccountsForCompany(accounitngTreasuryAccuntsResponse.accounting_accounts.cash)
        setAccountingPostalAccountsForCompany(accounitngTreasuryAccuntsResponse.accounting_accounts.postal)
        setAccountingBankAccountsForCompany(accounitngTreasuryAccuntsResponse.accounting_accounts.bank)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    if (companyForUser) {
      setAccountingCashAccountsForCompany(null)
      setAccountingPostalAccountsForCompany(null)
      setAccountingBankAccountsForCompany(null)
      loadAccountingTreasuryAccountsForCompany(companyForUser.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser])

  return (
    <Outlet context={{setRoute, companyForUser, accountingAccountsForCompany, treasuryAccountsForCompany, accountingCashAccountsForCompany, accountingPostalAccountsForCompany, accountingBankAccountsForCompany, setAccountingAccountsForCompany, setTreasuryAccountsForCompany, setAccountingCashAccountsForCompany, setAccountingPostalAccountsForCompany, setAccountingBankAccountsForCompany, loadAccountingTreasuryAccountsForCompany}}></Outlet>
  );
}

export function UseOutletContextTreasury() {
  return useOutletContext<ContexType>()
};

export default TreasuryPage;
