export const contentEnglish = {
  code: "EN",
  labels: {
    about: "About",
    accountSettings: "Account settings",
    actions: "Actions",
    additional: "Additional",
    address: "Address",
    administrativeArea: "State/Department",
    amount: "Amount",
    amountTotal: "Amount total",
    apply: "Apply",
    approved: "Approved",
    ascending: "Ascending",
    addContact: "Add contact",
    addContent: "Add content",
    addSignature: "Add signature",
    avatar: "Avatar",
    back: "Back",
    bban: "BBAN",
    bicSwift: "BIC/SWIFT",
    bimester: "Bimester",
    calculatedValuesAreReferential: "Calculated values are referential",
    cancel: "Cancel",
    canceled: "Canceled",
    city: "City",
    clickOn: "Click on",
    comment: "Comment",
    completed: "Completed",
    companyContact: "Company contact",
    content: "Content",
    registerNumber: "Register number",
    concerning: "Concerning",
    contactInformation: "Contact information",
    continue: "Continue",
    country: "Country",
    create: "Create",
    createdAt: "Created at",
    createdBy: "Created by",
    createdDate: "Created Date",
    currency: "Currency",
    customer: "Customer",
    date: "Date",
    day: "Day",
    days: "Days",
    default: "Default",
    delete: "Delete",
    descending: "Descending",
    description: "Description",
    detail: "Detail",
    details: "Details",
    discard: "Discard",
    doNotGroup: "Do not group",
    edit: "Edit",
    email: "Email",
    end: "End",
    endDate: "End date",
    excel: "Excel",
    export: "Export",
    filter: "Filters",
    filterOptions: "Filter options",
    firstName: "First name",
    group: "Group",
    groups: "groups",
    groupBy: " Group by",
    halfYear: "Half year",
    iban: "IBAN",
    identity: "Identity",
    inProgress: "In progress",
    justNow: "Just now",
    lastDay: "Last day",
    lastName: "Last name",
    legalInformation: "Legal information",
    list: "List",
    location: "Location",
    menu: "Menu",
    message: "Message",
    noNumber: "No number",
    noMatchingRecordsFound: "No matching records found",
    month: "Month",
    months: "Months",
    number: "Number",
    or: "Or",
    sortAlphabetically: "Sort alphabetically",
    pages: "Pages",
    pdf: "PDF",
    pending: "Pending",
    person: "Person",
    phone: "Phone",
    phoneNumber: "Phone number",
    priceGross: "Price gross",
    priceNet: "Price net",
    priceTotal: "Price total",
    product: "Product",
    products: "Products",
    qrIban: "QR-IBAN",
    quantity: "Quantity",
    rejected: "Rejected",
    remove: "Remove",
    removeReference: "Remove reference",
    removeContent: "Remove content",
    removeSignature: "Remove signature",
    removeRequirement: "Remove block",
    removeVoucher: "Remove voucher",
    requirement: "Block",
    requirements: "Blocks",
    reset: "Reset",
    saveChanges: "Save changes",
    searchResults: "Search results",
    selectExportFormat: "Select export format",
    send: "Send",
    service: "Service",
    services: "Services",
    appService: "App service",
    appServices: "App services",
    signature: "Signature",
    signatures: "Signatures",
    start: "Start",
    startDate: "Start date",
    status: "Status",
    street: "Street",
    subTotal: "Subtotal",
    support: "Support",
    symbol: "Symbol",
    tax: "Tax",
    taxRate: "Tax rate",
    taxTotal: "Total tax",
    trimester: "Trimester",
    total: "Total",
    totalAmount: "Total amount",
    top: "Top",
    type: "Type",
    timeInterval: "Time interval",
    interval: "Interval",
    unitMeasure: "Unit measure",
    unitPrice: "Price unit",
    update: "Update",
    validity: "Validity",
    validUntil: "Valid until",
    value: "Value",
    vatNumber: "VAT/IDE number",
    webSite: "Web site",
    week: "Week",
    view: "View",
    yes: "Yes",
    zipCode: "ZIP code",
    gross: "Gross",
    net: "Net",
    rebate: "Rebate",
    rebateTotal: "Total rebate",
    selectOptionAddAmountPercentageDesired: "Select an option and add the amount or percentage desired",
    allowedFileTypes: "Allowed file types: png, jpg, jpeg",
    changeImage: "Change image",
    changeLogo: "Change logo",
    exportError: "Export error",
    failedToLoadTheScript: "Failed to load the script",
    identificationNumberForACompany: "Identification number for a company that wishes to carry out operations at a European level",
    internationalBankAccountNumberIban: "International Bank Account Number (IBAN)",
    listOfCompaniesToWhichIHaveAccess: "List of companies to which I have access",
    noRecordsFound: "No records found",
    noResultsFound: "No results found",
    removeImage: "Remove image",
    selectTheTypeOfContact: "Select the type of contact you want to create",
    specifyTheUniqueUUID: "Specify the unique UUID code of a user or company",
    the: "The",
    theDefaultBankAccountIsTheCompanys: "The default treasury account is the company's",
    theDefaultCurrencyIsTheCompanys: "The default currency is the company's",
    theDefaultShapeOrVersionOfAQrInvoiceToDownloadIsTheClients: "The default hape or version of a QR-Invoice to download is the client's",
    theDefaultLanguageToDownloadIsTheClients: "The default language to download is the client's",
    youMustAcceptTheTermsAndConditions: "You must accept the terms and conditions",
    acceptingInvitationToAccessCompany: "Accepting invitation to access company",
    verificationYourAccount: "Verification your account",
    addCompany: "Add company",
    allInLicense: "All-in license",
    buyNow: "Buy now",
    comingSoon: "Coming soon",
    digitalAdmin: "Digital Admin",
    digitalAdminSarl: "Digital Admin SARL",
    licenseTerms: "License Terms",
    pageNotFound: "Page not found",
    planYourBusinessApplications: "Plan your business applications by choosing one of",
    viewInvitations: "View invitations",
    digitalAdminPro: "Digital Admin Pro",
    purchase: "Purchase",
    purchasePro: "Purchase Digital Admin Pro",
    reload: "Reload",
    securedPaymentBy: "Secured payment by",
    systemError: "System error",
    welcomeToDigitalAdminApp: "Welcome to Digital Admin App",
    welcomeToDigitalAdmin: "Welcome to Digital Admin",
    dashboard: "Dashboard",
    abbreviation: "Abbreviation",
    amountPurchases: "Amount purchases",
    amountSales: "Amount sales",
    best: "Best",
    companyInsight: "Company insight",
    comparativeInterval: "Comparative interval",
    comparisonIncomeAndExpenses: "Comparison income & expenses",
    customerPayments: "Customer payments",
    customRange: "Custom range",
    debtPercent: "Debt(%)",
    distributionOfQuotes: "Distribution of quotes according to your status",
    distributionOfOrders: "Distribution of orders according to your status",
    expirationDate: "Expiration date",
    invoicesNotYetPaid: "invoices not yet paid",
    invoicesStillToBePaid: "Invoices still to be paid",
    invoicesStillToBePaidByTheCustomer: "Invoices still to be paid by the customer",
    invoicesWithMoreDebt: "Invoices with more debt",
    ordersByState: "Orders by state",
    ordersToBilled: "Orders to billed",
    paidPercent: "Paid(%)",
    percentage: "Percentage",
    quotesByState: "Quotes by state",
    quotesAccepted: "Quotes accepted",
    quotesApproved: "Quotes approved",
    quotesToExpire: "Quotes to expire",
    quotesToExpireThisMonth: "quotes to expire this month",
    retailAnalysisSample: "Retail analysis sample",
    representationOfSalesAndPurchases: "Representation of sales and purchases of a company by month over a year",
    salesVsPurchases: "Sales vs Purchases",
    thisBimester: "This bimester",
    thisTrimester: "This trimester",
    thisHalfYear: "This half year",
    thisMonth: "This month",
    thisWeek: "This week",
    thisYear: "This year",
    today: "Today",
    turnout: "Turnout",
    viewAll: "View all",
    worst: "Worst",
    worstCustomers: "worst customers",
    yesterday: "Yesterday",
    fixedAmount: "Fixed amount",
    discount: "Discount",
    account: "Account",
    button: "button",
    changeEmail: "Change email",
    changePassword: "Change password",
    confirmNewPassword: "Confirm new password",
    currentPassword: "Current password",
    emailAddress: "Email address",
    enterNewEmailAddress: "Enter new email address",
    fullName: "Full name",
    newPassword: "New password",
    pleaseCheckYourEmailInbox: "Please check your email inbox",
    profileCompleation: "Profile compleation",
    profileDetails: "Profile details",
    signInMethod: "Sign-in method",
    updateEmail: "Update email",
    updatePassword: "Update password",
    updateUser: "Update user",
    validateAccount: "Validate account",
    viewUser: "View user",
    yourAccountIsNotVerified: "Your account is not verified. Please validate your account through your email address, by clicking",
    user: "User",
    users: "Users",
    confirmPassword: "Confirm password",
    createAnAccount: "Create an account",
    enterTheNewPasswordForTheMail: "Enter the new password for the mail",
    enterYourEmailToResetYourPassword: "Enter your email to reset your password",
    goToLoginPage: "Go to login page",
    goToPreviousPage: "Go to previous page",
    iAgree: " I agree &",
    language: "Language",
    languages: "Languages",
    myProfile: "My profile",
    notVerified: "Not verified",
    ourServicesOrRequesting: "our services or requesting a new one",
    password: "Password",
    resetPassword: "Reset password",
    signIn: "Sign in to Digital Admin",
    signInHere: "Sign in here",
    signOut: "Sign out",
    submit: "Submit",
    termsAndConditions: "terms and conditions",
    userProfile: "User profile",
    use8OrMoreCharacters: "Use 8 or more characters with a mix of letters (lowercase and uppercase), numbers & symbols",
    verified: "Verified",
    welcome: "Welcome",
    company: "Company",
    companyParameterSettings: "Company parameter settings",
    companies: "Companies",
    current: "Current",
    addDeadlinePaymentParameter: "Add deadline payment parameter",
    addImage: "Add image",
    addTax: "Add tax",
    addDeadline: "Add deadline",
    addTaxRateParameter: "Add tax rate parameter",
    addUnit: "Add unit",
    addUnitMeasureParameter: "Add unit measure parameter",
    advanceSettings: "Advance settings",
    anInvitationWillBeSent: "An invitation will be sent for a user to have access to the",
    aNewCompanyWillBeCreated: "A new company will be created from the entered data",
    browseUsers: "Browse users",
    companyAddress: "Company address",
    companyAdvanceSettings: "Company advance settings",
    companyDownloadSettings: "Company download settings",
    companyGeneralSettings: "Company general settings",
    companyIdentity: "Company identity",
    companyLogo: "Company logo",
    companyName: "Company name",
    createCompany: "Create company",
    createAndSave: "Create & Save",
    createNewCompany: "Create new company",
    designOfDocumentToDownloadQuotesAndInvoices: "Design of document to download quotes, orders and invoices",
    downloadSettings: "Download settings",
    dueDates: "Due dates for payment of an invoice",
    editCompany: "Edit company",
    free: "Free",
    generalSettings: "General settings",
    helpPage: "Help page",
    ifYouNeedMoreInfo: "If you need more info, please check out",
    image: "Image",
    inviteUser: "Invite a user",
    legal: "Legal",
    listOfUsers: "List of users",
    logo: "Logo",
    name: "Name",
    new: "New",
    noResultFound: "No result found",
    overview: "Overview",
    parameterSettings: "Parameter settings",
    pleaseTryAgainWithADifferentQuery: "Please try again with a different query",
    predefinedTaxesFees: "Predefined tax fees",
    private: "Private",
    removeLogo: "Remove logo",
    selectModel: "Select model",
    selectAndSaveDesiredLayoutToDownload: "Select and save the desired design to download quote, order and invoice",
    selectAndSaveOneToFourDesiredColors: "Select and save one to four desired colors for quote, order and invoice design",
    sendInvitation: "Send invitation",
    settings: "Settings",
    taxValue: "Tax value",
    templateColors: "Template colors",
    templateModel: "Template model",
    theEnteredInformationHasAlreadyValidated: "The entered information has already been validated, you can now confirm & create a new company",
    theNonRequiredFields: "The non-required fields that have been filled with erroneous data will be deleted and will not be saved",
    toFinishCreationNewCompany: "To finish with the creation of the new company, click on",
    unitsOfMeasure: "Units of measure for an invoice",
    uuidCode: "UUID code",
    uuidCompany: "UUID Company",
    uuidName: "UUID/Name",
    usersWhoAreSubscribed: "Users who are subscribed and have access to company",
    viewCompany: "View company",
    viewMoreUsers: "View more users",
    thisContactWillOnlyBeRegisteredInCompany: "This contact will only be registered in the company",
    treasury: "Treasury",
    treasuryAccount: "Treasury account",
    treasuryAccounts: "Treasury accounts",
    codeOfBelongingToABankingOrganization: "Code of belonging to a banking organization",
    createANewTreasuryAccount: "Create a new treasury account",
    createNewTreasuryAccount: "Create new treasury account",
    createTreasuryAccount: "Create treasury account",
    listTreasuryAccounts: "List treasury accounts",
    membership: "Membership",
    nameOfAccount: "Name of the account",
    contactUs: "Contact Us",
    typeOfTreasuryAccount: "Type of treasury account",
    updateTreasuryAccountData: "Update treasury account data",
    viewTreasuryAccountsList: "View treasury accounts list",
    notification: "Notification",
    notifications: "Notifications",
    accept: "Accept",
    accessToCompany: "Access to company",
    activities: "Activities",
    answers: "Answers",
    close: "Close",
    companyInvitations: "Company invitations",
    hasReceivedANotification: "Has received a notification",
    hasReceivedAQuote: "Has received a quote",
    hasReceivedAnInvitation: "Has received an invitation",
    hasReceivedAnOrder: "Has received an order",
    newNotification: "New notification",
    noNotifications: "No notifications",
    other: "Other",
    quoteEvaluation: "Quote evaluation",
    quoteNotification: "Quote notification",
    reject: "Reject",
    theInvitationToAccess: "The invitation to access the company by the ",
    theQuoteThatYouSent: "The quote that you sent by the",
    youHaveAlreadyEvaluatedTheOrderOfThe: "You have already evaluated the order of the",
    youHaveAlreadyEvaluatedQuote: "You have already evaluated the quote of the",
    youHaveAlreadyRespondedTo: "You have already responded to ",
    youHaveReceivedAQuote: "You have received a quote from the",
    contact: "Contact",
    contacts: "Contacts",
    basicBankAccountNumber: "Basic Treasury Account Number",
    bankIdentifierCode: "Bank Identifier Code / Society for Worldwide Interbank Financial Telecommunication",
    contactsList: "List contacts ",
    contactUUID: "Contact UUID",
    contactIdentity: "Contact identity",
    contactIsNotAnAppUser: "Contact is not an app user",
    contactType: "Contact type",
    contactUUIDandType: "Contact UUID and type",
    countryContact: "Country contact",
    createContact: "Create contact",
    createNewContact: "Create new contact",
    createContactCompanyStructure: "Create a company-type contact structure",
    createContactPersonStructure: "Create a person-type contact structure",
    deleteContact: "Delete contact",
    deleteSelected: "Delete selected",
    definingTheIdentityOfContact: "Defining the identity of contact",
    definingTheInformationOfContact: "Defining the information of contact",
    definingTheLegalInformationOfContact: "Defining the legal information of contact",
    definingTheSettingsToDownloadQuotesAndInvoices: "Defining the settings to download quotes and invoices",
    dragOrClickOnTheBoxToAddTheCompanyLogo: "Drag or click on the box to add the company logo",
    editContact: "Edit contact",
    enterThePostalCodeOfTheCity: "Enter the postal code of the city",
    exportContacts: "Export contacts",
    extendChanges: "Extend changes",
    fileDownloadSettings: "File download settings",
    financialEntity: "Financial entity",
    identityOfContact: "Identity of contact",
    ifAddressDoesNotHaveANumber: "If address does not have a number, enter 'nn' or 'NN'",
    ifNecessaryEnterSomethingReferentialRegardingAddress: "If necessary, enter something referential regarding address",
    ifYouDoNotWishToExtendContactModificationsToOtherCompanies: "If you don`t wish to extend contact modifications to other companies, don`t select any and select",
    informationOfContact: "Information of contact",
    internationalBankAccountNumber: "International Bank Account Number",
    languageToDownload: "Language to download quote, order or invoice",
    legalInformationOfContact: "Legal information of contact",
    qrType: "QR type",
    shapesOrVersionsOfQR: "Shapes or versions of a QR-Invoice",
    selectCompanyAndSave: "Select company and save",
    selectCompaniesToCreateContact: "Select the companies to which to add a new contact",
    typeOfContact: "Type of contact",
    updateContactData: "Update contact data",
    uuidAndType: "UUID and type",
    userUuid: "User UUID",
    viewContact: "View contact",
    viewContactsList: "View contacts list",
    quote: "Quote",
    quotes: "Quotes",
    addQuote: "Add quote",
    approveQuote: "Approve quote",
    companyQuoteList: "Company quote list",
    createQuote: "Create quote",
    newQuote: "New quote",
    dateOfQuote: "Date of quote, this can be modified later",
    editQuote: "Edit quote",
    enterADateOrSelectADefaultPaymentDeadline: "Enter a date or select a default payment deadline",
    expired: "Expired",
    exportQuotes: "Export quotes",
    generateQuote: "Generate quote",
    inTime: "In time",
    listQuotes: "List quotes",
    listOfQuotes: "List of quotes",
    quoteDate: "Quote date",
    quoteGroups: "Quote groups",
    quoteNumber: "Quote number",
    quoteReference: "Quote reference",
    quoteStatus: "Quote status",
    quoteValidity: "Quote validity",
    referenceQuote: "Reference quote",
    rejectQuote: "Reject quote",
    sendQuote: "Send quote",
    updateQuote: "Update quote ",
    viewQuote: "View quote",
    youWantToChangeQuoteStatusTo: "You want to change quote status to",
    purchases: "Purchases",
    sales: "Sales",
    viewInvoices: "View invoices",
    viewOrders: "View orders",
    viewQuotes: "View quotes",
    viewProviderOrders: "View provider orders",
    viewProviderInvoices: "View provider invoices",
    viewExpenses: "View expenses",
    expenses: "Expenses",
    addExpense: "Add expense",
    createExpense: "Create expense",
    editExpense: "Edit expense",
    expenseDate: "Expense date",
    expenseGroups: "Expense groups",
    expenseNumber: "Expense number",
    expenseValidity: "Expense validity",
    invalidateExpense: "Invalidate Expense",
    listExpenses: "List expenses",
    listOfExpenses: "List of expenses",
    removeExpense: "Remove Expense",
    updateExpense: "update expense",
    validateExpense: "Validate expense",
    viewExpense: "View expense",
    addProviderInvoice: "Add provider invoice",
    createProviderInvoice: "Create provider invoice",
    editProviderInvoice: "Edit provider invoice",
    invalidateProviderInvoice: "Invalidate provider invoice",
    listProviderInvoices: "List provider invoices",
    listOfProviderInvoices: "List of provider invoices",
    myBankAccount: "My treasury account",
    provider: "Provider",
    providerBankAccount: "Provider treasury account",
    providerInvoiceDate: "Provider invoice date",
    providerInvoiceGroups: "Provider invoice groups",
    providerInvoiceStatus: "Provider invoice status",
    providerInvoiceValidity: "Provider invoice validity",
    providerInvoices: "Provider invoices",
    providerOrderNumber: "Provider order number",
    updateProviderInvoice: "Update provider invoice",
    validateProviderInvoice: "Validate provider invoice",
    viewProviderInvoice: "View provider invoice",
    addProviderOrder: "Add provider order",
    approveProviderOrder: "Approve provider order",
    cancelProviderOrder: "Cancel provider order",
    createProviderOrder: "Create provider order",
    editProviderOrder: "Edit provider order",
    generateProviderInvoice: "Generate provider invoice",
    generateProviderOrder: "Generate provider order",
    listProviderOrders: "List provider orders",
    listOfProviderOrders: "List of provider orders",
    providerOrderDate: "Provider order date",
    providerOrders: "Provider orders",
    providerOrderGroups: "Provider order groups",
    providerOrderReference: "Provider order reference",
    providerInvoiceReference: "Provider invoice reference",
    expenseReference: "Expense reference",
    providerOrderStatus: "Provider order status",
    updateProviderOrder: "Update Provider Order",
    viewProviderOrder: "View provider order",
    youWantToChangeProviderOrderStatusTo: "You want to change provider order status to",
    addVouchers: "Add voucher(s)",
    download: "Download",
    file: "File",
    lastModified: "Last Modified",
    size: "Size",
    vouchers: "Vouchers",
    addFiles: "Add files",
    attachFiles: "Attach files",
    removeAll: "Remove all",
    uploadFiles: "Upload files",
    orders: "Orders",
    order: "Order",
    orderEvaluation: "Order evaluation",
    orderGroups: "Order groups",
    orderNotification: "Order notification",
    orderReference: "Order reference",
    orderStatus: "Order status",
    addOrder: "Add order",
    approveOrder: "Approve Order",
    cancelOrder: "Cancel order",
    companyListOfOrders: "Company list of orders",
    createOrder: "Create order",
    delivery: "Delivery",
    deliveyAddress: "Delivery address",
    deliveryNote: "Delivery note",
    editOrder: "Edit order",
    exportOrder: "Export order",
    exportOrders: "Export orders",
    generateInvoice: "Generate invoice",
    generateOrder: "Generate order",
    isDeliveryAddressTheSameAsCustomersAddress: "Is delivery address the same as customer's address",
    listOrders: "List orders",
    listOfOrders: "List of orders",
    orderDate: "Order date",
    orderFile: "Order file",
    orderNumber: "Order number",
    selectExportType: "Select export type",
    sendOrder: "Send Order",
    theOrderThatYouSentByThe: "The order that you sent by the",
    thisOptionIsUniqueAndNewInvoice: "This option is unique and new invoice cannot be created from this order",
    updateOrder: "Update order",
    viewOrder: "View order",
    youHaveReceivedAnOrderFromThe: "You have received an order from the",
    youWantToChangeOrderStatusTo: "You want to change order status to",
    invoice: "Invoice",
    invoices: "Invoices",
    addInvoice: "Add invoice",
    amountDebt: "Amount debt",
    amountPaid: "Amount paid",
    amountPayed: "Amount payed",
    companyInvoiceList: "Company invoices list",
    createInvoice: "Create invoice",
    editInvoice: "Edit invoice",
    exportInvoices: "Export invoices",
    debt: "Debt",
    fullyPaid: "Fully paid",
    invalidInvoices: "Invalid invoices",
    invalidateInvoice: "Invalidate invoice",
    invoiceGroups: "Invoice groups",
    invoiceDate: "Invoice date",
    invoiceNumber: "Invoice number",
    invoiceStatus: "Invoice status",
    invoiceValidity: "Invoice validity",
    listInvoices: "List invoices",
    listOfInvoices: "List of invoices",
    notPayed: "Not payed",
    partiallyPaid: "Partially paid",
    payed: "Payed",
    thisInvoiceHasNoPayments: "This invoice has no payments",
    updateInvoice: "Update invoice",
    validated: "Validated",
    validateInvoice: "Validate invoice",
    viewInvoice: "View invoice",
    youWillBeAbleToUndoThisAction: "You will be able to undo this action",
    payment: "Payment",
    payments: "Payments",
    amountToPay: "Amount to pay ",
    createNewPayment: "Create new payment",
    deleteSomePayment: "Delete some payment",
    doNotDeletePayments: "Do not delete payments",
    makePayment: "Make a payment",
    method: "Method",
    paymentDate: "Payment date",
    paymentMethod: "Payment method",
    save: "Save",
    sourceOfIncome: "Source of income",
    sourceTheAmount: "Source where the amount of money of payment made is entered",
    cash: "Cash",
    card: "Card",
    payPal: "PayPal",
    transfer: "Transfer",
    mobile: "Mobile",
    thisOptionIncludeTaxPrice: "This option includes IVA in indicated price",
    thisOptionNotIncludeTaxPrice: "This option does not include IVA in indicated price",
    addOrRemoveContactCompanies: "Add or remove contact from companies",
    theCurrentCompanyIs: "The current company is",
    notifyMe: "Notify me",
    background: "Background",
    inventory: "Inventory",
    viewProducts: "View products",
    viewProduct: "View product",
    viewServices: "View services",
    viewCategories: "View categories",
    viewStorageCenter: "View storage center",
    toCreateA: "To create a",
    theCompanyContainTreasutyAccount: "the company needs to contain at least one treasury account",
    appLanguage: "App language",
    theUuidCanBeUsedToCreateNnewContact: "The UUID can be used to create a new contact",
    settingInformation: "Settings information",
    addOrRemoveCompanies: "Add/Remove companies",
    notGrouped: "Not grouped",
    notFiltered: "Not filtered",
    orderValidity: "Order validity",
    expiration: "Expiration",
    quoteExpiration: "Quote expiration",
    orderExpiration: "Order expiration",
    invoiceExpiration: "Invoice expiration",
    discountTotal: "Discount total",
    addService: "Add service",
    addProduct: "Add product",
    noRecords: "No records",
    quantityMinimumForRebate: "Quantity minimum for rebate",
    thisOptionRebateIsFixedAmount: "This option indicates that rebate is a fixed amount",
    thisOptionRebateIsPercentage: "This option indicates that rebate is a percentage",
    thisOptionRebateIsNone: "This option indicates that rebate is null",
    totalAfterRebates: "Total after rebates",
    calculator: "Calculator",
    selectTypeRebateAndEnterValueAndQuantity: "Select the type of rebate and enter value and quantity",
    reference: "Reference",
    newOrder: "New order",
    invoiceReference: "Invoice reference",
    newInvoice: "New invoice",
    providerOrderValidity: "Provider order validity",
    expenseStatus: "Expense status",
    newProviderOrder: "New provider order",
    newProvederInvoice: "New provider invoice",
    newExpense: "New expense",
    viewVoucher: "View voucher",
    informationAdditional: "Information additional",
    no: "No",
    bank: "Bank",
    fullNameBankAccountHolder: "Full name of the bank account holder",
    bankAccountHolder: "Bank account holder",
    isNecessaryToHaveReferenceToActivateOption: "It is necessary to have a reference to activate this option",
    isNecessaryToHaveAtLeastTwoTreasuryAccountsToActivateOption: "It is necessary to have at least two treasury accounts to activate this option",
    none: "None",
    undefined: "Undefined",
    colorCombinationForDesignOfDocumentToDownload: "Color combination for design of document to download",
    all: "All",
    lettersTitlesSubtitles: "Letters titles subtitles",
    firstBar: "First bar",
    secondBar: "Second bar",
    barLetters: "Bar letters",
    qrIbanAndQrReference: "QR-IBAN and QR Reference",
    ibanAndStructuredCreditorReference: "IBAN and Structured Creditor Reference (SCOR)",
    ibanWithoutReference: "IBAN Without Reference",
    cashAccount: "Cash account",
    postalAccount: "Postal account",
    bankAccount: "Bank account",
    productsList: "List products",
    category: "Category",
    categories: "Categories",
    listCategories: "List Categories",
    tags: "Tags",
    variants: "Variants",
    photography: "Photography",
    reminder: "Reminder",
    reminders: "Reminders",
    paymentStatus: "Payment status",
    year: "Year",
    createReminder: "Create reminder",
    updateReminder: "Update reminder",
    makeCopy: "Make a copy",
    reminderTotal: "Total reminder",
    inDispute: "In dispute",
    createReminderFee: "Create reminder fee",
    addCategory: "Add category",
    qtyAssets: "QTY assets",
    qtyServices: "QTY services",
    qtyProducts: "QTY products",
    viewCategory: "View category",
    editCategory: "Edit Category",
    deleteCategory: "Delete Category",
    names: "Names",
    listCategoryItems: "List of category items",
    editListing: "Edit listing",
    updateCategoryItemList: "Update category item list",
    updateCategory: "Update category",
    NameInOtherLanguages: "Name in other languages",
    enterInLanguageWant: "Enter in language you want",
    enterNameOfRequestedLanguages: "Enter the name of the requested languages",
    enterOrModifyInLanguage: "Enter or modify in language you want",
    general: "General",
    categoryName: "Category name",
    categoryType: "Category type",
    createCategory: "Create category",
    editProduct: "Edit product",
    deleteProduct: "Delete product",
    productReferencePictures: "Product reference pictures",
    updatedAt: "Updated at",
    code: "Code",
    sku: "SKU",
    codebar: "Codebar",
    color: "Color",
    unit: "Unit",
    price: "Price",
    fixed: "Fixed",
    productName: "Product name",
    productDetails: "Product details",
    advanced: "Advanced",
    addVariant: "Add variant",
    uniqueProductCodeAssignedByCompany: "Unique product code assigned by the company",
    additionalCommentDescriptionProductVariant: "Additional comment or description to the product variant",
    selectDiscountTypeAppliedToProduct: "Select a discount type that will be applied to this product",
    discountType: "Discount type",
    selectDiscountTypeThatWillAppliedProduct: "Select a discount type that will be applied to this product",
    noDiscount: "No discount",
    setDiscountPercentage: "Set discount percentage",
    fixedDiscountedAmount: "Fixed discount amount",
    quantityMinimumDiscount: "Quantity minimum for discount",
    createProduct: "Create product",
    dropFilesHereOrClickUpload: "Drop files here or click to upload",
    uploadUpToFiles: "Upload up to 10 files",
    success: "Success",
    addNewProductVariant: "Add new product variant",
    editVariant: "Edit variant",
    editProductVariant: "Edit product variant",
    updateProduct: "Update product",
    saveVariant: "Save variant",
    servicesList: "List services",
    viewService: "View service",
    editService: "Edit service",
    deleteService: "Delete service",
    serviceReferencePictures: "Service reference pictures",
    serviceName: "Service name",
    serviceDetails: "Service details",
    createService: "Create service",
    updateService: "Update service",
    uniqueServiceCodeAssignedByCompany: "Unique service code assigned by the company",
    selectDiscountTypeAppliedToService: "Select a discount type that will be applied to this service",
    and: "and",
    expenseAccount: "Expense account",
    vatAccount: "VAT account",
    accountProduct: "Product account",
    accountingAccount: "Accounting account",
    accountingAccounts: "Accounting accounts",
    accountingAccountPurchases: "Accounting account for purchases",
    accountingAccountSales: "Accounting account for sales",
    addAccountingAccount: "Add accounting account",
    rangeAllowedCodes: "Range of allowed codes",


    ifYouChangeYourMind: "If you change your mind, ask for a new application to be sent to you.",
    messageWillBeSentYourEmailToRecoverAccount: "Message will be sent your email to recover account, please check your email. Will be redirected to login page.",
    nowYouCanLogIn: "Now you can log in with the new account create, will be redirected to login page.",
    newCompanyIsSelectedByDefault: "New company is selected by default, redirecting to main page.",
    notificationWillBeDeleted: "Notification of the invitation to access company will be deleted.",
    theAppWillBeTranslated: "The app will be translated into indicated language, except for  information entered in documents.",
    toBeAddedInOrderToDownloadTheInvoice: "to be added in order to download the invoice.",
    userWillRespondInvitation: "User will respond whether or not accept invitation to access company.",
    youWillNotBeAbleToUndo: "You will not be able to undo this action.",
    tokenValidForAUserWhoHasNotLoggedIn: "Token valid for a user who has not logged in, redirecting to login page.",
    toValidateYourAccount: "To validate your account, you will be sent a validation email from where you can verify your account and enter again.",
    validatedAccountForLoggedInUser: "Validated account for logged in user, redirect to main page.",
    validatedAccountForADifferentUser: "Validated account for a different user than the one you are logged in, redirecting to login page.",
    validatedVerifiedForAUserWhoHasNotLoggedIn: "Validated verified for a user who has not logged in, redirecting to login page.",
    newServicesWithinTheSameApp: "New services within the same application.",
    pageInProduction: "Page in production, very soon you will be able to access new functionalities within the application.",
    pleaseCorrectThePathOfThePageInOrderToAccess: " Please correct the path of the page in order to access the application.",
    pleaseTryAgainLater: "Please try again later.",
    thereAreNoCompaniesAddedYet: "There are no companies added yet.",
    tokenValidForAnUserThatIsDifferentFromLoggedIn: "Token valid for an user that is different from logged in, redirecting to login page.",
    validTokenForTheUserThatLoggedIn: "Valid token for the user that logged in, redirecting to dashboard page.",
    redirectingToLogin: "Redirecting to login page.",
    willNotBeAbleToReturnUntilYouLogin: "Will not be able to return until you login again.",
    allTheFieldsAreValidated: "All the fields are validated, in case of entering an incorrect data in a required form you will not be able to advance to the next stage, on the contrary, when entering an incorrect data in a non-required form, it will not be saved.",
    pleaseClickContinue: "Please click continue to enter the details of the new company to be created.",
    searchUserForInvitate: "Search for user by their UUID or by name and send a notification to access the company.",
    theFileMustBeFormat: "The file must be in jpeg, jpg or png format.",
    ifYouHaveAnyQuestionsContactUs: "If you have any questions you can contact us.",
    noNotificationsSeenYet: "No notifications seen yet.",
    thereAreNoNotifications: "There are no notifications received yet.",
    byDefaultValuesOfTheCompanyAreTaken: "By default, values of the company are taken.",
    selectTheOptionsThatBestConsiderWhenDownloading: "Select the options that best consider when downloading a quote or an invoice in PDF format.",
    notificationsAboutInvitationsToSubscribe: "Notifications about invitations to subscribe and have access to a company.",
    whenCreatingAContactByUUID: "When creating a contact by UUID you will not be able to modify certain fields depending on the type: person (name, surname and email) and company (name).",
    youCanDirectlyEnterUUIDOfAUserOrCompanyToAddAsContact: "You can directly enter UUID of a user or a company to add it as a contact, otherwise choose type of contact to create.",
    descriptionCreateQuote: "Please enter the following data, they are required to create a new quote.",
    descriptionCreateOrder: "Please enter the following data, they are required to create a new order.",
    descriptionCreateInvoice: "Please enter the following data, they are required to create a new invoice.",
    pleaseModifyAddOrDeleteFieldsFromQuote: "Please modify, add and/or delete the fields you want from the quote.",
    sorryLooksLikeThereAreSomeErrorstryAgain: "Sorry, looks like there are some errors detected, please try again.",
    sorryLooksLikeThereAreSomeErrorsTrySolve: "Sorry, looks like there are some errors detected, please try solve.",
    sorryThereIsNothingToAdd: "Sorry, there`s nothing to add, please try again.",
    thankYouVeryMuchForSubscribingRedirectingToPreviousPage: "Thank you very much for subscribing, redirecting to previous page.",
    actionCompletedReturningToPage: "Action completed, returning to page.",
    actionCompletedRedirectingToPage: "Action completed, redirecting to page.",
    thisOptionIsUniqueAndNewOrderCannotBeCreatedFromThisQuote: "This option is unique and new order cannot be created from this quote.",
    pleaseEnterTheFollowingDataToCreateANewProviderInvoice: "Please enter the following data, they are required to create a new provider invoice.",
    pleaseModifyAddOrDeleteFieldsFromProviderInvoice: "Please modify, add and/or delete the fields you want from the provider invoice.",
    pleaseModifyAddOrDeleteFieldsFromExpense: "Please modify, add and/or delete the fields you want from the expense.",
    pleaseEnterTheFollowingDataToCreateANewExpense: "Please enter the following data, they are required to create a new expense.",
    pleaseEnterThFollowingDataToCreateANewProviderOrder: "Please enter the following data, they are required to create a new provider order.",
    pleaseModifyAddAndOrDeleteFieldsFromProviderOrder: "Please modify, add and/or delete the fields you want from the provider order.",
    fileInDifferentFormat: 'File in different format. File format: ".pdf".',
    fileIsTooBig: "File is too big. Max file size: 1MB.",
    fileFormatMaximum1MBPerFile: 'File format is ".pdf" and maximum file size is 1 MB per file.',
    someOfTheUploadedFilesDoNotMeetTheRequiredCharacteristics: "Some of the uploaded files do not meet the required characteristics.",
    PleaseModifyAddOrDeleteFieldsFromOrder: "Please modify, add and/or delete the fields you want from the order.",
    pleaseModifyAddOrDeleteFieldsFromInvoice: "Please modify, add and/or delete the fields you want from the invoice.",
    thisAmountCanNotBeGreater: "This amount cannot be greater than total debt of invoice.",
    toCreateAnDocument: "To create a documento, the company needs to contain at least one treasury account.",
    newContactWwillAddedToCompanyAndSelectDefault: "New contact will be added to company and select as default, returning to page.",
    youHaveSuccessfullyResetYourPassword: "You have successfully reset your password, now you can log in again. Will be redirected to login page.",
    kickstartYourBusinessByAddingAYourFirstCompany: "Kickstart your business by adding a your first company.",
    ifNoAmountIsEnteredTheValueWillBeZero: "If no amount is entered, the value will be 0.",
    ifYouWishYouCanAssignNameOfCategoryInLanguagesNecessary: "If you wish, you can assign the name of the category in languages that you think are necessary.",
    productNameRequiredAndRecommendedUnique: "A product name is required and recommended to be unique.",
    addProductToCategory: "Add product to a category.",
    addTagsToProduct: "Add tags to product, they can be optional.",
    setProductMediaGallery: "Set the product media gallery.",
    serviceNameRequiredAndRecommendedUnique: "A service name is required and recommended to be unique.",
    addServiceToCategory: "Add service to a category.",
    addTagsToService: "Add tags to service, they can be optional.",
    setServiceMediaGallery: "Set the service media gallery.",
    aCategoryContainServicesProducts: "A category can contain both services and products.",


    selectOneOption: "Select one of the options,",
    youAreWishingToEnterWithTheFollowingEmailAddress: "You are wishing to enter with the following email address:",
    checkOrUncheck: "Check or uncheck the",


    ifYouWishToSaveTheChangesMade: "if you wish to save the changes made.",
    youWantToRun: "you want to run.",
    toContinue: "to continue.",
    waitAMomentToVerifyYourAccess: "wait a moment to verify your access.",
    companyPleaseEvaluateQuote: "company, please evaluate the quote.",
    hasSentARequestForAccessToTheFollowingCompany: " has sent a request for access to the following company, please respond to it.",
    ifYouWantToSaveTheNewQuote: "if you want to save the new quote.",
    ifYouWantToSaveTheNewQuoteWithTheChangesMade: "if you want to save the new quote with the changes made.",
    ifYouWantToSaveTheNewInvoiceWithTheChangesMade: "if you want to save the new invoice with the changes made.",
    ifYouWantToSaveTheNewExpense: "if you want to save the new expense.",
    ifYouWantToSaveTheNewProviderInvoice: "if you want to save the new provider invoice.",
    ifYouWantToSaveTheNewProviderOrder: "if you want to save the new provider order.",
    ifYouWantToSaveTheNewProviderOrderWithTheChangesMade: "if you want to save the new provider order with the changes made.",
    ifYouWantToSaveTheNewProviderInvoiceWithTheChangesMade: "if you want to save the new provider invoice with the changes made.",
    ifYouWantToSaveTheNewOrder: "if you want to save the new order.",
    ifYouWantToSaveTheNewOrderWithTheChangesMade: "if you want to save the new order with the changes made.",
    companyPleaseEvaluateTheOrder: "company, please evaluate the order.",
    ifYouWantToSaveTheNewInvoice: "if you want to save the new invoice.",
    youWillNotBeAbleToRemoveItFromThisCompany: ", you will not be able to remove it from this company, you can select the other companies.",
    inThisCategory: "in this category",
    toAddOrRemoveCategory: "you want to add or remove to category",


    yearl: "year",
    years: "years",
    hour: "hour",
    hours: "hours",
    minute: "minute",
    minutes: "minutes",
    information: "information",
    items: "items",
    selected: "selected",
    accountNeedsA: "account needs a",
    options: "options",
    with: "with",
    bestCustomers: "best customers",
    byRecentUpdates: "by recent updates",
    byTimeRange: "by time range",
    template: "template",
    evaluated: "evaluated",
    noMailFound: "no mail is found",
    report: "report",
    reports: "reports",
    requestOnAccess: "request on access to the company, your response was",
    userHasBeen: "user has been",
    companyYourAnswerHasBeen: "company, your answer has been",
    companyToUser: "company to user",
    hasBeen: "has been",
    byYear: "by year",


    youWantLogOut: "You want log out?",
    doesItTakeALongTimeToLoadTheApp: "Does it take a long time to load the app?",
    alreadyHaveAnCccount: "Already have an account?",
    forgotPassword: "Forgot password?",
    newHere: "New here?",
    canNotFindTheUserYouWant: "Can't find the user you want?",
    selectThisTreasuryAccountAsTheMainOne: "Select this treasury account as the main one?",
    youWantToDeleteThisTreasuryAccount: "You want to delete this treasury account?",
    youWantToSelectThisTreasuryAccountAsPrimary: "You want to select this treasury account as primary?",
    wantToExtendChangesToOtherCompanies: "Want to extend changes to other companies?",
    youWantToDeleteThisContact: "You want to delete this contact?",
    youWantToDeleteSelectedContacts: "You want to delete selected contacts?",
    youWantSendQuote: "You want to send the quote to customer?",
    youWantToChangeQuoteStatusRejected: "You want to change quote status to rejected?",
    youWantToGenerateAnOrderFromThisQuote: "You want to generate an order from this quote?",
    youWantToChangeStatusTheExpense: "You want to change status the expense?",
    youWantToDeleteThisExpense: "You want to delete this expense?",
    youWantToChangeStatusTheProviderInvoice: "You want to change status the provider invoice?",
    youWantToGenerateAProviderInvoiceFromThisProviderOrder: "You want to generate a provider invoice from this provider order?",
    youWantToChangeOrderStatusToCanceled: "You want to change order status to canceled?",
    youWantToGenerateAnInvoiceFromThisOrder: "You want to generate an invoice from this order?",
    youWantToSendTheOrderToCustomer: "You want to send the order to customer?",
    youWantToDeleteThisPayment: "You want to delete this payment?",
    youWantToChangeStatusTheInvoice: "You want to change status the invoice?",
    IsCompanyOwnerOfBankAccount: "Is the company the owner of the bank account?",
    useReferenceWithinQR: "Use a reference within the QR?",
    youWantDeleteProduct: "You want to delete this product?",
    youWantDeleteSelectedProducts: "You want to delete selected products?",
    youWantToDeleteThisReminder: "You want to delete this reminder?",
    youWantToDeleteThisCategory: "You want to delete this category?",
    youWantToDeleteSelectedCategories: "You want to delete selected categories?",
    youWantDeleteService: "You want to delete this product?",
    youWantDeleteSelectedServices: "You want to delete selected services?",


    yourSessionHasBeenClosed: "Your session has been closed, please register!",
    yourSessionHasExpired: "Your session has expired, please register again!",
    accountNotVerifiedYet: "Account not verified yet!",
    moneyBackGuarantee: "100% money back guarantee!",
    somethingWentWrong: "Something went wrong!",
    thePageYouLookedNotFound: "The page you looked not found!",
    yourSessionIsActiveCongratulations: "Your session is active, congratulations!",
    weNeedYourAttention: "We need your attention!",
    yourAreDone: "Your are done!",
    startCreatingNewTreasuryAccountsForYourCompany: "Start creating new treasury accounts for your company, it is very important for the operation of the application!",
    getNotifiedWhenWeLaunch: "Get notified when we launch!",
    processOfCreatingANewInvoiceCompleted: "Process of creating a new invoice completed!",
    processOfUpdatingAInvoiceCompleted: "Process of updating a invoice completed!",
    thereAreNoTreasuryAccounts: "There are no treasury accounts!",
    processOfCreatingANewOrderCompleted: "¡Process of creating a new order completed!",
    processOfUpdatingAnOrderCompleted: "¡Process of updating an order completed!",
    processOfCreatingQuoteCompleted: "Process of creating a new quote completed!",
    processOfCreatingAQuoteFromAnotherQuoteCompleted: "Process of creating a quote from another quote completed!",
    processOfCreatingAOrderFromAnotherOrderCompleted: "Process of creating a order from another order completed!",
    processOfCreatingAInvoiceFromAnotherInvoiceCompleted: "Process of creating a invoice from another invoice completed!",
    processOfUpdatingAQuoteCompleted: "Process of updating a quote completed!",
    processOfCreatingANewExpenseCompleted: "Process of creating a new expense completed!",
    processOfUpdatingAnExpenseCompleted: "Process of updating an expense completed!",
    processOfCreatingANewProviderInvoiceCompleted: "Process of creating a new provider invoice completed!",
    processOfUpdatingAProviderInvoiceCompleted: "Process of updating a provider invoice completed!",
    processOfCreatedAProviderOrderCompleted: "Process of created a provider order completed!",
    processOfCreatedAProviderInvoiceCompleted: "Process of created a provider invoice completed!",
    processOfCreatingANewProviderOrderCompleted: "Process of creating a new provider order completed!",
    processOfUpdatingAProviderOrderCompleted: "Process of updating a provider order completed!",
    incorrectFiles: "Incorrect files!",
    fileSizeNotAllowed: "File size not allowed! Maximum file size: 1 MB",
    invalidFileType: "Invalid file type! Allowed file types: jpeg, jpg or png",
    startAddingNewElementsToThisCategory: "Start adding new elements to this category!",
    accountingAccountAlreadySelected: "Accounting account already selected, please select another one!",


    english: "English",
    french: "French",
    italian: "Italian",
    german: "German",
    spanish: "Spanish",


    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September ",
    october: "Octuber",
    november: "November",
    december: "December",


    first: "first",
    second: "second",
    third: "third",
    fourth: "fourth",
    fifth: "fifth",
    sixth: "sixth",


    n: "N°",
    hrs: "hrs",
    min: "min",
    sec: "sec",
    mb: "MB",


    pleaseWait: "Please wait...",
    selectOption: "Select option...",
    search: "Search...",
    writeCommentToInvite: "Write a comment to the invite...",
    loading: "Loading...",
    searchContact: "Search contact and select...",
    searchCategory: "Search category and select...",
    searchProduct: "Search product and select...",
    searchService: "Search service and select...",


    okGotIt: "Ok, got it",
    yesLogOut: "Yes, log out",
    noCancel: "No, cancel",
    yesDelete: "Yes, delete",
    yesResolveError: "Yes, resolve error",
    yesSelect: "Yes, select",
    yesCanceled: "Yes, canceled",
    okClose: "Ok, close",
    yesReject: "Yes, reject",
    yesGenerate: "Yes, generate",
    yesSend: "Yes, send",
    yesChangeStatus: "Yes, change status",
    yesCreateTreasuryAccount: "Yes, create treasury account",


    valid: "Valid",
    invalid: "Invalid",
    colorIsInvalid: "Color is invalid",
    theValueIsNotAValid: "The value is not a valid",
    theValueIsNotAValidUUIDOrNameUser: "The value is not a valid UUID or Name User",
    theValueIsAtLeast8Digits: "The value is at least 8 digits",
    theValueDoesNotMatch: "The value does not match",
    theValueAlreadyExists: "The value already exists",
    limitFilesMaximum: "Limit of 10 files maximum",
    required: "Required",
    theValueIsRequired: "The value is required",
    colorIsRequired: "Color is required",
    requirementsIsRequired: "Block is required",
    contentsToRequirementIsRequired: "Contents to block is required",


    successful: "Successfully!",
    successfullyAcceptedCompany: "Successfully accepted company!",
    successfullyCreatedTreasuryAccount: "Successfully created treasury account!",
    successfullyCreatedCompany: "Successfully created company!",
    successfullyCreatedCompanyParameters: "Successfully created company parameters!",
    successfullyCreatedContact: "Successfully created contact!",
    successfullyCreatedExpense: "Successfully created expense!",
    successfullyCreatedOrder: "Successfully created order!",
    successfullyCreatedInvoice: "Successfully created invoice!",
    successfullyCreatedNotification: "Successfully created notification!",
    successfullyCreatedPayment: "Successfully created payment!",
    successfullyCreatedProviderInvoice: "Successfully created provider invoice!",
    successfullyCreatedProviderOrder: "Successfully created provider order!",
    successfullyCreatedQuote: "Successfully created quote!",
    successfullyCreatedUser: "Successfully created user!",
    successfullyDeletedTreasuryAccount: "Successfully deleted treasury account!",
    successfullyDeletedContact: "Successfully deleted contact!",
    successfullyDeletedExpense: "Successfully deleted expense!",
    successfullyDeletedPayment: "Successfully deleted payment!",
    successfullyInvitationCancel: "Successfully invitation cancel!",
    successfullyInvitationSent: "Successfully invitation sent!",
    successfullyLoggedIn: "You have successfully logged in!",
    successfullyOrderSent: "Successfully order sent!",
    successfullyUpdatedCompanySettings: "Successfully updated company settings!",
    successfullyUpdatedTreasuryAccount: "Successfully updated treasury account!",
    successfullyUpdatedDefaultTreasuryAccount: "Successfully updated default treasury account!",
    successfullyUpdatedContact: "Successfully updated contact!",
    successfullyUpdatedExpense: "Successfully updated expense!",
    successfullyUpdatedInvoice: "Successfully updated invoice!",
    successfullyUpdatedNotification: "Successfully updated notification!",
    successfullyUpdatedOrder: "Successfully updated order!",
    successfullyUpdatedPassword: "Successfully updated password!",
    successfullyUpdatedProviderInvoice: "Successfully updated provider invoice!",
    successfullyUpdatedProviderOrder: "Successfully updated provider order!",
    successfullyUpdatedQuote: "Successfully updated quote!",
    successfullyUpdatedStatus: "Successfully updated status!",
    successfullyQuoteSent: "Successfully quote sent!",
    successfullyRejectedCompany: "Successfully rejected company!",
    successfullyRequest: "Successfully request!",
    successfullySubscribed: "Successfully subscribed!",
    successfullyAcceptedInvitation: "Successfully accepted invitation!",
    successfullyUpdatedUser: "Successfully updated user!",
    successfullyUpdatedAccount: "Successfully updated account!",
    successfullyMailSent: "Successfully mail sent!",
    verificationWasSuccessful: "Verification was successful!",
    youHaveSuccessfullySignedOut: "You have successfully signed out!",
    successfullyDeletedReminder: "Successfully deleted reminder!",
    successfullyCreatedReminder: "Successfully created reminder!",
    successfullyUpdatedReminder: "Successfully updated reminder!",
    successfullyUpdatedCategory: "Successfully updated category!",
    successfullyCreatedCategory: "Successfully created category!",
    successfullyDeletedCategories: "Category(ies) successfully deleted!",
    successfullyCreatedService: "Successfully created service!",
    successfullyUpdateService: "Successfully updated service!",
    successfullyUpdateProduct: "Successfully updated product!",
    successfullyCreatedProduct: "Successfully created product!",
    successfullyDeleteProducts: "Successfully deleted products!",
    successfullyCreatedAccountingAccount: "Successfully created accounting account!",

    error: "Error",
    error1000: "Token not found!",
    error1001: "Token invalid!",
    error1002: "Error ocurred in database!",
    error1003: "One or more mandatory fields are empty!",
    error1004: "Uuid not found!",
    error1005: "Password do not match!",
    error1006: "Invalid format for Email!",
    error1007: "Wrong password!",
    error1008: "User not found!",
    error1009: "Invalid Apikey!",
    error1010: "Email already taken!",
    error1011: "Must be at least 8 characters!",
    error1012: "This user is already suscribe to this services!",
    error1014: "We not have registered email ir out database!",
    error1015: "Error sending email!",
    error1016: "This company is already registered!",
    error1017: "The user is already verified!",
    error1018: "Wrong password token!",
    error1019: "Invalid other ApiKey!",
    error1020: "Wrong verification token!",
    error1021: "You do not have access to this company!",
    error1022: "There is already an invoice with this order!",
    error1023: "Company not found!",
    error1024: "Unable to create an invoice from a rejected order!",
    error1025: "Invalid uuid!",
    error1026: "Notification not found!",
    error1027: "The quote is not found in this company!",
    error1028: "The end date must be greater than the start date!",
    error1029: "Wrong email!",
    error1030: "Quote not found!",
    error1031: "You can't invite yourself!",
    error1032: "Invoice not found!",
    error1033: "Contact not found!",
    error1034: "Currency incorrect!",
    error1035: "Payment not found!",
    error1036: "The payment has already been deleted!",
    error1037: "The contact has already been deleted!",
    error1038: "Invoice not validated!",
    error1039: "The amount must not be negative!",
    error1040: "One of the contacts has already been deleted!",
    error1041: "That contact already exists!",
    error1042: "The contact does not belong to the company!",
    error1043: "More than you should pay!",
    error1044: "The contact already belongs to the company!",
    error1047: "The new password you entered is the same as your previous password!",
    error1048: "Incorrect way to order!",
    error1049: "The new email is the same as the email currently registered in your account!",
    error1050: "Wrong payment method!",
    error1051: "The quote does not exist!",
    error1052: "Wrong type!",
    error1055: "The first name, last name and email should not be changed!",
    error1056: "The first name and last name should not be changed!",
    error1057: "The quote wigh this status cannot edited!",
    error1058: "There is already an invoice with this quote!",
    error1059: "Invitation not sent!",
    error1060: "Invitation has already been accepted!",
    error1061: "Invitation has already been rejected!",
    error1062: "Incorrect invitation status!",
    error1064: "Error when sending the email for account verification!",
    error1065: "Invalid format for phone!",
    error1066: "Invalid format for additional address!",
    error1067: "Invalid format for address number!",
    error1068: "Invalid format for address street!",
    error1069: "Invalid zip_code!",
    error1070: "Invitation not found!",
    error1071: "Invalid format for bic!",
    error1072: "Invalid format for bban!",
    error1073: "Invalid format for vat!",
    error1074: "Invalid format for register!",
    error1075: "Invalid format for website!",
    error1076: "Provider Order not approved!",
    error1077: "Invalid format for id of company!",
    error1078: "Notification does not belong to user!",
    error1079: "The provider order is not found in this company!",
    error1080: "The first name and email should not be changed!",
    error1081: "The first name should not be changed!",
    error1082: "The last name and email should not be changed!",
    error1083: "The last name should not be changed!",
    error1084: "The email should not be changed!",
    error1085: "The name should not be changed!",
    error1086: "Quote not approved!",
    error1087: "Templates does not exist!",
    error1088: "The currency has to be EUR or CHF!",
    error1089: "Please enter a valid QR-IBAN!",
    error1090: "Please enter a valid IBAN!",
    error1091: "Invalid format for language!",
    error1092: "Invalid format for country!",
    error1093: "The provider order is not found in this company!",
    error1094: "Provider invoice validated!",
    error1095: "Provider invoice not found!",
    error1096: "Treasury Account deleted!",
    error1097: "Provider invoice not validated!",
    error1098: "Provider order not found!",
    error1099: "Expense not found!",
    error1100: "The expense has already been deleted!",
    error1101: "The order wigh this status cannot edited!",
    error1102: "The order does not exist!",
    error1103: "Order not canceled!",
    error1104: "Order not approved!",
    error1105: "Treasury account not found!",
    error1106: "The order is not found in this company!",
    error1107: "Order not found!",
    error1108: "There is already an provider invoice with this provider order!",
    error1109: "Unable to create an provider invoice from a rejected provider order!",
    error1110: "Has payments made, it cannot be invalidated!",
    error1111: "Category not found!",
    error1112: "Category is already deleted!",
    error1113: "Products not found!",
    error1114: "Products are already deleted!",
    error1115: "One or more categories not found!",
    error1116: "One or more categories already deleted!",
    error1117: "One or more products not found!",
    error1118: "One or more products already deleted!",
    error1119: "One or more variants with the same name!",
    error1120: "Invalid type! Allowed values are: product, service.",
    error1121: "Invalid format for the category ID!",
    error1122: "Service not found!",
    error1123: "Service is already deleted!",
    error1124: "One or more services not found!",
    error1125: "One or more services already deleted!",
    error1126: "Incorrect category for products!",
    error1127: "Incorrect category for services!",
    error1128: "Measure unit not found!",
    error1129: "A product or service cannot remain uncategorized!",
    error1130: "The category has items, it cannot be deleted!",
    error1131: "One o more units not found!",
    error1132: "There is already a treasury account with this accounting account",
    error1133: "The cash account code is not in the range 1000 - 1009.99",
    error1134: "The postal account code is not in the range 1010 - 1019.99",
    error1135: "The bank account code is not in the range 1020 - 1049.99",
    error1136: "One or more of the selected products and/or services were not found",
  }
}