import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextProduct } from './Product';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ProductDA } from '../../../../services/product.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Product } from '../../../../models/product.model';
import { legendInvalid, legendInvalidIcon } from '../../../../tools/legend.data.entry.tool';
import { validateAttributes } from '../../../../scripts/validate.attributes.script';

import AppProductForm from './card/ProductForm';

export interface ProductUpdatePageProps {};

let errorResponse: Error, messageResponse: Message, productResponse: Product;

const ProductUpdatePage: React.FunctionComponent<ProductUpdatePageProps> = props => {
  const {setRoute, companyForUser, settingsForCompany, categoriesForCompany, accountingExpenseAccountsForCompany, accountingVatAccountsForCompany, accountingProductAccountsForCompany, loadProductsForCompany} = UseOutletContextProduct()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [name, setName] = useState({value: '', valid: false})
  const [names, setNames] = useState({value: {en: '', fr: '', it: '', de: ''}, valid: {en: false, fr: false, it: false, de: false}})
  const [category, setCategory] = useState({value: '', valid: false})
  const [tags, setTags] = useState({value: [] as string[], valid: false})
  const [accounts, setAccounts] = useState({value: {expense: '', vat: '', product: ''}, valid: {expense: false, vat: false, product: false}})
  const [variants, setVariants] = useState({value: [] as any[], valid: [] as any[]})
  const [variantDefault, setVariantDefault] = useState({value: {description_short: '', description_long: '', code: '', sku: '', codebar: '', size: '', color: '', price: '', tax_rate: '', unit: '', discount: {type: 'none', value: '', quantity: ''}, photos: [] as {view: string, file: any}[]}, valid: {description_short: false, description_long: true, code: true, sku: true, codebar: true, size: true, color: true, price: false, tax_rate: false, unit: false, discount: {type: true, value: true, quantity: true}, photos: [] as {file: boolean, message: string}[], gallery: true}})
  const [product, setProduct] = useState<Product | undefined | null>(null)

  const loadProduct = async (id_company: string, id_product: string) => {
    await ProductDA.getProduct(id_company, id_product).then( (response) => {
      if (response.status === 200) {
        productResponse = response.data

        let tempVariantsValue: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[] = []
        let tempVariantsValid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}[] = []

        for (let variant of productResponse.variants) {
          let newVariantValue: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: any[]} = {
            description_short: variant.description_short,
            description_long: variant.description_long,
            code: variant.code,
            sku: variant.sku,
            codebar: variant.codebar,
            size: variant.size,
            color: variant.color,
            price: variant.price.toString(),
            tax_rate: variant.tax_rate,
            unit: variant.unit.id,
            discount: {
              type: variant.discount.type,
              value: variant.discount.value.toString(),
              quantity: variant.discount.quantity.toString()
            },
            photos: []
          }
          let newVarianttValid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: any[], gallery: boolean} = {
            description_short: true,
            description_long: true,
            code: true,
            sku: true,
            codebar: true,
            size: true,
            color: true,
            price: true,
            tax_rate: true,
            unit: true,
            discount: {
              type: true,
              value: true,
              quantity: true
            },
            photos: [],
            gallery: true
          }

          for (let photo of variant.photos) {
            let newPhotoValue: {view: string, file: any} = {
              view: photo,
              file: photo
            }
            let newPhotoValid: {file: boolean, message: string} = {
              file: true,
              message: lang.labels.valid
            }

            newVariantValue.photos.push(newPhotoValue)
            newVarianttValid.photos.push(newPhotoValid)
          }

          tempVariantsValue.push(newVariantValue)
          tempVariantsValid.push(newVarianttValid)
        }

        if (tempVariantsValue.length > 1 && tempVariantsValid.length > 1) {
          setVariants({value: tempVariantsValue, valid: tempVariantsValid})
        } else {
          setVariantDefault({value: tempVariantsValue[0], valid: tempVariantsValid[0]})
        }

        setProduct(productResponse)
        setName({value: productResponse.display_name, valid: true})
        setNames({value: productResponse.names, valid: {en: true, fr: true, it: true, de: true}})
        setAccounts({value: productResponse.accounts, valid: {expense: true, vat: true, product: true}})
        setCategory({value: productResponse.category.id, valid: true})
        setTags({value: productResponse.tags, valid: true})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitProductUpdate = async () => {
    setLoadIndicator('on')

    let namesIsValid: boolean = validateAttributes(names.valid)
    let accountsIsValid: boolean = validateAttributes(accounts.valid)
    let variantsIsValid: boolean = (variants.valid.length < 2 && variants.valid.length < 2) ? validateAttributes(variantDefault.valid) : variants.valid.every(item => validateAttributes(item))

    if (companyForUser && product && name.valid && namesIsValid && accountsIsValid && category.valid && tags.valid && variantsIsValid) {
      let variantList = (variants.valid.length < 2 && variants.valid.length < 2)
        ?
        [ {...variantDefault.value, photos: variantDefault.value.photos.map(item => item.file)} ]
        :
        variants.value.map(item => { return {
          description_short: item.description_short,
          description_long: item.description_long,
          code: item.code,
          sku: item.sku,
          codebar: item.codebar,
          size: item.size,
          color: item.color,
          price: item.price,
          tax_rate: item.tax_rate,
          unit: item.unit,
          discount: {
            type: item.discount.type,
            value: item.discount.value,
            quantity: item.discount.quantity
          },
          photos: item.photos.map((sub_item: {view: string, file: any}) => {return sub_item.file})
        }
      })

      await ProductDA.putProduct(companyForUser.id, product.id, name.value, category.value, names.value, accounts.value, tags.value, variantList).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          loadProductsForCompany(companyForUser.id)

          Swal.fire({
            title: getMessage(messageResponse.message, lang.code),
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (name.value.length === 0) {
          legendInvalidIcon('input-name', 'container-validate-name-required')
          legendInvalidIcon('input-name-other', 'container-validate-name-other-required')
        }
        if (category.value.length === 0) {
          legendInvalid('container-validate-category-required')
        }
        if (accounts.value.expense.length === 0) {
          legendInvalid('container-validate-account-expense-required')
        }
        if (accounts.value.vat.length === 0) {
          legendInvalid('container-validate-account-vat-required')
        }
        if (accounts.value.product.length === 0) {
          legendInvalid('container-validate-account-product-required')
        }
        if (variants.valid.length === 0 && variants.valid.length === 0) {
          if (variantDefault.value.description_short.length === 0) {
            legendInvalidIcon('page-input-variant-description-short', 'page-container-validate-variant-description-short-required')
          }
          if (variantDefault.value.unit.length === 0) {
            legendInvalid('page-container-validate-variant-unit-required')
          }
          if (variantDefault.value.tax_rate.length === 0) {
            legendInvalid('page-container-validate-variant-tax-required')
          }
          if (variantDefault.value.price.length === 0) {
            legendInvalidIcon('page-input-variant-price', 'page-container-validate-variant-price-required')
          }
          if (variantDefault.value.discount.type !== 'none') {
            if (variantDefault.value.discount.value.length === 0) {
              legendInvalidIcon('page-input-variant-discount-value', 'page-container-validate-variant-discount-value-required')
            }
            if (variantDefault.value.discount.quantity.length === 0) {
              legendInvalidIcon('page-input-variant-discount-quantity', 'page-container-validate-variant-discount-quantity-required')
            }
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    if(companyForUser && param.id) {
      loadProduct(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.products, branch: lang.labels.updateProduct}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { settingsForCompany && categoriesForCompany && accountingExpenseAccountsForCompany && accountingVatAccountsForCompany && accountingProductAccountsForCompany && product
        ?
        <AppProductForm action={"update"} loadIndicator={loadIndicator} name={name} category={category} tags={tags} names={names} accounts={accounts} variantDefault={variantDefault} variants={variants} setName={setName} setCategory={setCategory} setTags={setTags} setNames={setNames} setAccounts={setAccounts} setVariantDefault={setVariantDefault} setVariants={setVariants} submitProduct={submitProductUpdate}></AppProductForm>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default ProductUpdatePage;
